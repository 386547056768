import D3Can from "@components/shared/D3Can";
import { Button, Form, Input, Space, Spin } from "antd";
import { useEffect } from "react";
import { CurrencyInput } from "react-currency-mask";
import { useDefaultPricesStore } from "./store";
import { DefaultPricesType, UpdateDefaultPricesType } from "./types";
import FormItemInputMoney from "@components/shared/FormItemInputMoney";

function DefaultPrice() {
  const { getDefaultPrices, updateDefaultPrices, loading } = useDefaultPricesStore();
  const [form] = Form.useForm<DefaultPricesType>();
  function convertPricesToFloat(prices: DefaultPricesType) {
    return {
      digitalized_page: parseFloat(prices.digitalized_page),
      document_registration: parseFloat(prices.document_registration),
      gigabyte_storage: parseFloat(prices.gigabyte_storage),
      repository_deployment: parseFloat(prices.repository_deployment),
      repository_registration: parseFloat(prices.repository_registration),
      hanging_folder_deployment: parseFloat(prices.hanging_folder_deployment),
    };
  }

  useEffect(() => {
    (async () => {
      const result = await getDefaultPrices();
      if (result.status === "success") {
        const data = result.body as DefaultPricesType;
        form.setFieldsValue(data);
      }
    })();
  }, [getDefaultPrices, form]);

  return loading ? (
    <div className="flex items-center justify-center h-full">
      <Spin />
    </div>
  ) : (
    <div className="flex flex-col h-full p-2 overflow-hidden w-80">
      <Form
        className="form-item-for-table"
        form={form}
        layout="vertical"
        onFinish={async (payload: DefaultPricesType) => {
          await updateDefaultPrices(convertPricesToFloat(payload) as UpdateDefaultPricesType);
        }}
      >
        <div className="h-full pr-2 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100">
          <FormItemInputMoney
            form={form}
            numberType="positive"
            name="repository_registration"
            label="Cadastro de repositório"
          />
          <FormItemInputMoney
            form={form}
            numberType="positive"
            name="document_registration"
            label="Cadastro de documento"
          />
          <FormItemInputMoney form={form} numberType="positive" name="digitalized_page" label="Digitalização" />
          <FormItemInputMoney
            form={form}
            numberType="positive"
            name="repository_deployment"
            label="Implantação de repositório"
          />
          <FormItemInputMoney
            form={form}
            numberType="positive"
            name="hanging_folder_deployment"
            label="Implantação pasta suspensa"
          />
          <FormItemInputMoney
            form={form}
            numberType="positive"
            name="gigabyte_storage"
            label="Armazenamento de imagem em Gigabyte"
          />
        </div>
      </Form>
      <div className="h-8 pr-4 my-2">
        <Form.Item>
          <div className="text-right">
            <Space size="small">
              <D3Can action="view" resource="company">
                <Button type="primary" htmlType="submit" onClick={() => form.submit()}>
                  Atualizar
                </Button>
              </D3Can>
            </Space>
          </div>
        </Form.Item>
      </div>
    </div>
  );
}

export default DefaultPrice;
