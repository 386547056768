import D3Can from "@components/shared/D3Can";
import D3ProtectPage from "@components/shared/D3ProtectPage";
import FixedColumn from "@components/shared/FixedColumn";
import { Icon } from "@iconify/react";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import D3Copy from "@utils/D3Copy";
import D3DateHourSorter from "@utils/D3DateHourSorter";
import { Fixed, FixedType } from "@utils/Fixed";
import { Button, Col, Flex, Pagination, Popover, Row, Space, Tooltip } from "antd";
import Table from "antd/es/table";
import { useContext, useEffect, useState } from "react";
import { ShowTag, StatusOrders, TypeOfRequest } from "./utils";
import { CreateMaterialOrderForm } from "./components/CreateMaterialOrderForm";
import PrintMaterialOrderForm from "./components/PrintMaterialOrderForm";
import { SearchMaterialOrderForm } from "./components/SearchMaterialOrderForm";
import { SendSignedMaterialOrderForm } from "./components/SendSignedMaterialOrderForm";
import { ViewMaterialOrderForm } from "./components/ViewMaterialOrderForm";
import { useMaterialOrdersStore } from "./store";
import { MaterialOrderListItemType } from "./types";
import ServeForm from "./components/ServeForm";
import ReadyMaterialOrderForm from "./components/ReadyMaterialOrderForm";
import confirmationDialog from "@components/shared/confirmationDialog";
import D3PopConfirmation from "@components/shared/D3PopConfirmation";

const MaterialOrdersView: React.FC = () => {
  const {
    apiResult,
    pagination,
    loading,
    setPagination,
    loadMaterialOrders,
    resetFilter,
    resetState,
    cancelMaterialOrder,
    readyOrder,
  } = useMaterialOrdersStore();
  const { openDrawer } = useContext(D3DrawerContext);
  const [openSearch, setOpenSearch] = useState(false);
  const [fixed, setFixed] = useState<FixedType[]>([
    {
      dataIndex: "",
      direction: "left",
    },
    {
      dataIndex: "",
      direction: "right",
    },
  ]);

  useEffect(() => {
    (async () => {
      await loadMaterialOrders();
    })();
    return () => resetState();
  }, [loadMaterialOrders]);

  return (
    <div className="flex h-full mb-1 mr-1 overflow-auto bg-white rounded shadow-md">
      <Table
        size="small"
        rowKey="cuid"
        dataSource={apiResult.data}
        bordered
        className="responsive-table"
        pagination={false}
        loading={loading}
        title={() => {
          return (
            <>
              <Row className="w-full">
                <Col span={8} className="flex items-center justify-start">
                  <Popover
                    open={openSearch}
                    placement="rightTop"
                    title="Pesquisar Ordem de material"
                    content={
                      <SearchMaterialOrderForm
                        onCallBackFilter={() => setOpenSearch(false)}
                        onCallBackResetFilter={() => {
                          setOpenSearch(false);
                          resetFilter();
                        }}
                      />
                    }
                    trigger="click"
                    onOpenChange={(open) => setOpenSearch(open)}
                  >
                    <Button
                      onClick={() => setOpenSearch(!openSearch)}
                      type="primary"
                      size="middle"
                      className="flex items-center"
                      icon={<Icon icon="fluent:search-12-filled" height={16} width={16} />}
                    >
                      Pesquisar
                    </Button>
                  </Popover>
                </Col>
                <Col span={8} className="text-center">
                  <span className="text-xl font-semibold text-gray-600">Ordens de Material</span>
                </Col>
                <Col span={8} className="flex items-center justify-end">
                  <D3Can action="create" resource="order_material">
                    <Button
                      type="primary"
                      size="middle"
                      className="flex items-center"
                      icon={<Icon icon="ic:round-plus" height={20} width={20} />}
                      onClick={() =>
                        openDrawer(
                          <D3ProtectPage
                            targetPage={<CreateMaterialOrderForm />}
                            isRequiredCustomerGroup={false}
                            isRequiredCustomer={true}
                            resource="order_material"
                            action="view"
                          />,
                          "right",
                          1200,
                          "Cadastrar Ordem de material"
                        )
                      }
                    >
                      Ordem de Material
                    </Button>
                  </D3Can>
                </Col>
              </Row>
            </>
          );
        }}
        footer={() => {
          return (
            <Flex className="w-full" align="center" justify="end">
              <Pagination
                showLessItems
                showTotal={(total, range) =>
                  `Exibindo de ${range[0]} a ${range[1]} do total ${total} ${total > 1 ? "registros" : "registro"}`
                }
                showSizeChanger={true}
                current={pagination.page}
                onChange={setPagination}
                pageSize={pagination.pageSize}
                total={apiResult.total}
              />
            </Flex>
          );
        }}
      >
        <Table.Column<MaterialOrderListItemType>
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          key="id"
          title={
            <FixedColumn
              active={fixed.some((item) => item.dataIndex === "id")}
              dataIndex="id"
              direction="left"
              title="Número"
              setFixed={setFixed}
            />
          }
          fixed={Fixed(fixed, "id")}
          dataIndex="id"
          render={(value) => {
            return (
              <div className="flex items-center justify-between gap-2">
                {value}
                <Tooltip trigger="hover" title="Copiar número da ordem">
                  <Button
                    className="transition-all duration-300 ease-in-out hover:bg-gray-200"
                    type="link"
                    shape="circle"
                    icon={<Icon className="text-blue-500" icon="fluent:copy-32-filled" />}
                    size="small"
                    onClick={() => D3Copy({ text: value, success: "Número copiado! " })}
                  />
                </Tooltip>
              </div>
            );
          }}
        />
        <Table.Column<MaterialOrderListItemType>
          title={
            <FixedColumn
              active={fixed.some((item) => item.dataIndex === "customer_name")}
              dataIndex="customer_name"
              direction="left"
              title="Cliente"
              setFixed={setFixed}
            />
          }
          fixed={Fixed(fixed, "customer_name")}
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          key="customer_name"
          dataIndex="customer_name"
        />
        <Table.Column<MaterialOrderListItemType>
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          key="requester_department"
          title="Departamento"
          dataIndex="requester_department"
        />

        <Table.Column<MaterialOrderListItemType>
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          key="requester_user"
          title="Requisitado por"
          dataIndex="requester_user"
        />
        <Table.Column<MaterialOrderListItemType>
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          key="created_by"
          title="Criado por"
          dataIndex="created_by"
        />
        <Table.Column<MaterialOrderListItemType>
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          key="created_at"
          title="Data criação"
          dataIndex="created_at"
          render={(value, materialOrder) => D3DateHourSorter(materialOrder.created_at)}
        />

        <Table.Column<MaterialOrderListItemType>
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          key="status"
          title="Tipo de requisição"
          dataIndex="status"
          render={(value, materialOrder) => ShowTag(TypeOfRequest, materialOrder.request_type)}
        />
        <Table.Column<MaterialOrderListItemType>
          showSorterTooltip={false}
          key="active"
          title="Status"
          dataIndex="active"
          render={(value, materialOrder) => {
            return (
              <Space>
                {materialOrder.printed && (
                  <Tooltip trigger="hover" title="Ordem impressa" destroyTooltipOnHide mouseLeaveDelay={0} zIndex={11}>
                    <Button
                      className="cursor-help"
                      type="link"
                      icon={
                        <Icon
                          height={20}
                          icon="lets-icons:print-duotone"
                          className="text-blue-400 transition-all duration-100 ease-in hover:scale-110 hover:text-blue-500"
                        />
                      }
                    />
                  </Tooltip>
                )}

                {materialOrder.ready && (
                  <Tooltip
                    trigger="hover"
                    title="Ordem pronta para atendimento"
                    destroyTooltipOnHide
                    mouseLeaveDelay={0}
                    zIndex={11}
                  >
                    <Button
                      className="cursor-help"
                      type="link"
                      icon={
                        <Icon
                          height={20}
                          icon="fluent:task-list-24-regular"
                          className="text-green-400 transition-all duration-100 ease-in hover:scale-110 hover:text-green-500"
                        />
                      }
                    />
                  </Tooltip>
                )}
                {materialOrder.canceled && (
                  <Tooltip trigger="hover" title="Ordem cancelada" destroyTooltipOnHide mouseLeaveDelay={0} zIndex={11}>
                    <Button
                      className="cursor-help"
                      type="link"
                      icon={
                        <Icon
                          height={20}
                          icon="lets-icons:cancel"
                          className="text-red-400 transition-all duration-100 ease-in hover:scale-110 hover:text-red-500"
                        />
                      }
                    />
                  </Tooltip>
                )}

                {materialOrder.completed && (
                  <Tooltip trigger="hover" title="Ordem atendida" destroyTooltipOnHide mouseLeaveDelay={0} zIndex={11}>
                    <Button
                      className="cursor-help"
                      type="link"
                      icon={
                        <Icon
                          height={20}
                          icon="fluent:document-checkmark-16-filled"
                          className="text-green-400 transition-all duration-100 ease-in hover:scale-110 hover:text-green-500"
                        />
                      }
                    />
                  </Tooltip>
                )}
              </Space>
            );
          }}
        />
        <Table.Column<MaterialOrderListItemType>
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          key="status"
          title="Status atual"
          dataIndex="status"
          render={(value, materialOrder) => ShowTag(StatusOrders, materialOrder.status)}
        />

        <Table.Column<MaterialOrderListItemType>
          key="actions"
          width={200}
          title={
            <FixedColumn
              active={fixed.some((item) => item.dataIndex === "actions")}
              dataIndex="actions"
              direction="right"
              title="Ações"
              setFixed={setFixed}
            />
          }
          fixed={Fixed(fixed, "actions")}
          render={(_, materialOrder) => {
            return (
              <>
                <div className="flex items-center justify-center gap-1 px-2 ">
                  <D3Can action="view" resource="order_material" type="hidden">
                    <Tooltip
                      trigger="hover"
                      title="Visualizar ordem"
                      destroyTooltipOnHide
                      mouseLeaveDelay={0}
                      zIndex={11}
                    >
                      <Button
                        className="flex items-center justify-center"
                        type="text"
                        shape="default"
                        icon={
                          <Icon
                            icon="carbon:document-view"
                            height={18}
                            width={18}
                            className="text-blue-400 transition-all duration-100 ease-in hover:text-blue-500"
                          />
                        }
                        size={"middle"}
                        onClick={() =>
                          openDrawer(
                            <ViewMaterialOrderForm cuid={materialOrder.cuid} />,
                            "right",
                            600,
                            "Visualizar Ordem de Material"
                          )
                        }
                      />
                    </Tooltip>
                  </D3Can>

                  <D3Can action="print" resource="order_material" type="hidden">
                    {materialOrder.canceled ? null : (
                      <Tooltip
                        title="Imprimir ordem"
                        destroyTooltipOnHide
                        trigger="hover"
                        mouseLeaveDelay={0}
                        zIndex={11}
                      >
                        <Button
                          className="flex items-center justify-center"
                          type="text"
                          shape="default"
                          icon={
                            <Icon
                              icon="lets-icons:print-duotone"
                              height={24}
                              width={24}
                              className="text-blue-400 transition-all duration-100 ease-in hover:text-blue-500"
                            />
                          }
                          size={"middle"}
                          onClick={() =>
                            openDrawer(
                              <PrintMaterialOrderForm
                                cuid={materialOrder.cuid}
                                fileName={`Ordem de material nº ${materialOrder.id}`}
                                printed={materialOrder.printed}
                              />,
                              "right",
                              1000,
                              "Imprimir Ordem de Material",
                              true
                            )
                          }
                        />
                      </Tooltip>
                    )}
                  </D3Can>

                  <D3Can action="completed" resource="order_service" type="hidden">
                    {materialOrder.completed || materialOrder.canceled || materialOrder.ready ? null : (
                      <Tooltip
                        title="Informar que a separação está concluída."
                        trigger="hover"
                        destroyTooltipOnHide
                        mouseLeaveDelay={0}
                        zIndex={11}
                      >
                        <Button
                          className="flex items-center justify-center"
                          type="text"
                          shape="default"
                          onClick={() => {
                            if (materialOrder.has_pickup_address) {
                              confirmationDialog({
                                title: `Atenção !`,
                                icon: "question",
                                text: "Confirme se a ordem de material está pronta para atendimento.",
                                showConfirmButton: true,
                                confirmButtonText: `Confirmar`,
                                showCancelButton: true,
                                cancelButtonText: "Cancelar",
                                cancelFunction: () => {},
                                confirmFunction: async () => {
                                  await readyOrder(materialOrder.cuid, { pickup_address_cuid: "" });
                                },
                              });
                            } else {
                              openDrawer(
                                <ReadyMaterialOrderForm orderCuid={materialOrder.cuid} />,
                                "right",
                                600,
                                "Informar que a tapa de separação foi concluída"
                              );
                            }
                          }}
                          icon={
                            <Icon
                              icon="entypo:check"
                              height={20}
                              width={20}
                              className="text-green-400 transition-all duration-100 ease-in hover:text-green-500"
                            />
                          }
                          size={"middle"}
                        />
                      </Tooltip>
                    )}
                  </D3Can>

                  <D3Can action="serve" resource="order_material" type="hidden">
                    {materialOrder.canceled || materialOrder.completed || materialOrder.ready === false ? null : (
                      <Tooltip
                        title="Atender a ordem"
                        trigger="hover"
                        destroyTooltipOnHide
                        mouseLeaveDelay={0}
                        zIndex={11}
                      >
                        <Button
                          className="flex items-center justify-center"
                          type="text"
                          shape="default"
                          onClick={() =>
                            openDrawer(
                              <ServeForm materialOrderCuid={materialOrder.cuid} />,
                              "right",
                              600,
                              "Baixar ordem de material"
                            )
                          }
                          icon={
                            <Icon
                              icon="fluent:document-checkmark-16-filled"
                              height={20}
                              width={20}
                              className="text-green-400 transition-all duration-100 ease-in hover:text-green-500"
                            />
                          }
                          size={"middle"}
                        />
                      </Tooltip>
                    )}
                  </D3Can>

                  <D3Can action="serve" resource="order_material" type="hidden">
                    {materialOrder.canceled ? null : (
                      <Tooltip
                        trigger="hover"
                        title="Envio/reenvio da ordem digitalizada"
                        destroyTooltipOnHide
                        mouseLeaveDelay={0}
                        zIndex={11}
                      >
                        <Button
                          className="flex items-center justify-center"
                          type="text"
                          shape="default"
                          icon={
                            <Icon
                              icon="fa6-solid:file-signature"
                              height={16}
                              className="text-blue-400 transition-all duration-100 ease-in hover:text-blue-500"
                            />
                          }
                          size={"middle"}
                          onClick={() =>
                            openDrawer(
                              <SendSignedMaterialOrderForm
                                orderCuid={materialOrder.cuid}
                                fileName={`Ordem de material nº ${materialOrder.id}`}
                                isUploadOrder={materialOrder.has_order_resent}
                              />,
                              "right",
                              600,
                              "Envio / reenvio da ordem digitalizada"
                            )
                          }
                        />
                      </Tooltip>
                    )}
                  </D3Can>

                  <D3Can action="cancel" resource="order_material" type="hidden">
                    {materialOrder.completed || materialOrder.canceled || materialOrder.ready === false ? null : (
                      <Tooltip
                        title="Cancelar ordem"
                        trigger="hover"
                        destroyTooltipOnHide
                        mouseLeaveDelay={0}
                        zIndex={11}
                      >
                        <Button
                          className="flex items-center justify-center"
                          type="text"
                          shape="default"
                          onClick={() =>
                            D3PopConfirmation({
                              confirmText: "Confirmar Cancelamento",
                              input: "textarea",
                              text: "Informe o motivo do cancelamento.",
                              icon: "warning",
                              preConfirm: async (reason: string) => {
                                await cancelMaterialOrder(materialOrder.cuid, reason);
                              },
                            })
                          }
                          icon={
                            <Icon
                              icon="lets-icons:cancel"
                              height={20}
                              width={20}
                              className="text-red-400 transition-all duration-100 ease-in hover:text-red-500"
                            />
                          }
                          size={"middle"}
                        />
                      </Tooltip>
                    )}
                  </D3Can>
                </div>
              </>
            );
          }}
        />
      </Table>
    </div>
  );
};

export default MaterialOrdersView;
