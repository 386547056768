import D3Can from "@components/shared/D3Can";
import FixedColumn from "@components/shared/FixedColumn";
import { Icon } from "@iconify/react";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import D3Copy from "@utils/D3Copy";
import D3DateHourSorter from "@utils/D3DateHourSorter";
import { Fixed, FixedType } from "@utils/Fixed";
import { Button, Col, Flex, Pagination, Popover, Row, Space, Tooltip } from "antd";
import Table from "antd/es/table";
import { useContext, useEffect, useState } from "react";
import { ShowTag, StatusOrders } from "./utils";
import { useServiceOrdersStore } from "./store";
import { ItemListServiceOrderType } from "./types";
import { SearchServiceOrderForm } from "./components/SearchServiceOrderForm";
import { ViewServiceOrderForm } from "./components/ViewServiceOrderForm";
import PrintServiceOrderForm from "./components/PrintDeploymentOrderForm";
import { SendSignedServiceOrderForm } from "./components/SendSignedServiceOrderForm";
import { useNavigate } from "react-router-dom";
import ServeServiceOrderForm from "./components/ServeServiceOrderForm";
import ReadyServiceOrderForm from "./components/ReadyServiceOrderForm";
import D3PopConfirmation from "@components/shared/D3PopConfirmation";
import DocumentsServiceOrderForm from "./components/DocumentsServiceOrderForm";
import confirmationDialog from "@components/shared/confirmationDialog";

const ServiceOrdersView: React.FC = () => {
  const {
    apiResult,
    pagination,
    loading,
    setPagination,
    loadServiceOrders,
    resetFilter,
    resetState,
    cancelServiceOrder,
    readyServiceOrder,
  } = useServiceOrdersStore();
  const { openDrawer } = useContext(D3DrawerContext);
  const navigate = useNavigate();
  const [openSearch, setOpenSearch] = useState(false);
  const [fixed, setFixed] = useState<FixedType[]>([
    {
      dataIndex: "",
      direction: "left",
    },
    {
      dataIndex: "",
      direction: "right",
    },
  ]);

  useEffect(() => {
    (async () => {
      await loadServiceOrders();
    })();
    return () => resetState();
  }, [loadServiceOrders]);

  return (
    <div className="flex h-full mb-1 mr-1 overflow-auto bg-white rounded shadow-md">
      <Table
        size="small"
        rowKey="cuid"
        dataSource={apiResult.data}
        bordered
        className="responsive-table"
        pagination={false}
        loading={loading}
        title={() => {
          return (
            <>
              <Row className="w-full">
                <Col span={8} className="flex items-center justify-start">
                  <Popover
                    open={openSearch}
                    placement="rightTop"
                    title="Pesquisar Ordem de serviço"
                    content={
                      <SearchServiceOrderForm
                        onCallBackFilter={() => setOpenSearch(false)}
                        onCallBackResetFilter={() => {
                          setOpenSearch(false);
                          resetFilter();
                        }}
                      />
                    }
                    trigger="click"
                    onOpenChange={(open) => setOpenSearch(open)}
                  >
                    <Button
                      onClick={() => setOpenSearch(!openSearch)}
                      type="primary"
                      size="middle"
                      className="flex items-center"
                      icon={<Icon icon="fluent:search-12-filled" height={16} width={16} />}
                    >
                      Pesquisar
                    </Button>
                  </Popover>
                </Col>
                <Col span={8} className="text-center">
                  <span className="text-xl font-semibold text-gray-600">Ordens de Serviço</span>
                </Col>
                <Col span={8} className="flex items-center justify-end">
                  <D3Can action="create" resource="order_service">
                    <Button
                      type="primary"
                      size="middle"
                      className="flex items-center"
                      icon={<Icon icon="ic:round-plus" height={20} width={20} />}
                      onClick={() => navigate("/app/customer/spaces/work/special")}
                    >
                      Ordem de Serviço
                    </Button>
                  </D3Can>
                </Col>
              </Row>
            </>
          );
        }}
        footer={() => {
          return (
            <Flex className="w-full" align="center" justify="end">
              <Pagination
                showLessItems
                showTotal={(total, range) =>
                  `Exibindo de ${range[0]} a ${range[1]} do total ${total} ${total > 1 ? "registros" : "registro"}`
                }
                showSizeChanger={true}
                current={pagination.page}
                onChange={setPagination}
                pageSize={pagination.pageSize}
                total={apiResult.total}
              />
            </Flex>
          );
        }}
      >
        <Table.Column<ItemListServiceOrderType>
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          key="id"
          title={
            <FixedColumn
              active={fixed.some((item) => item.dataIndex === "id")}
              dataIndex="id"
              direction="left"
              title="Número"
              setFixed={setFixed}
            />
          }
          fixed={Fixed(fixed, "id")}
          dataIndex="id"
          render={(value) => {
            return (
              <div className="flex items-center justify-between gap-2">
                {value}
                <Tooltip trigger="hover" title="Copiar número da ordem">
                  <Button
                    className="transition-all duration-300 ease-in-out hover:bg-gray-200"
                    type="link"
                    shape="circle"
                    icon={<Icon className="text-blue-500" icon="fluent:copy-32-filled" />}
                    size="small"
                    onClick={() => D3Copy({ text: value, success: "Número copiado! " })}
                  />
                </Tooltip>
              </div>
            );
          }}
        />
        <Table.Column<ItemListServiceOrderType>
          title={
            <FixedColumn
              active={fixed.some((item) => item.dataIndex === "customer")}
              dataIndex="customer"
              direction="left"
              title="Cliente"
              setFixed={setFixed}
            />
          }
          fixed={Fixed(fixed, "customer")}
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          key="customer"
          dataIndex="customer"
        />
        <Table.Column<ItemListServiceOrderType>
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          key="department"
          title="Departamento"
          dataIndex="department"
        />

        <Table.Column<ItemListServiceOrderType>
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          key="requester_name"
          title="Requisitado por"
          dataIndex="requester_name"
        />
        <Table.Column<ItemListServiceOrderType>
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          key="created_by"
          title="Criado por"
          dataIndex="created_by"
        />
        <Table.Column<ItemListServiceOrderType>
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          key="created_at"
          title="Data criação"
          dataIndex="created_at"
          render={(value, serviceOrder) => D3DateHourSorter(serviceOrder.created_at)}
        />
        <Table.Column<ItemListServiceOrderType>
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          key="number_of_repositories"
          title="Qtd. Repositório"
          dataIndex="number_of_repositories"
        />
        <Table.Column<ItemListServiceOrderType>
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          key="number_of_documents"
          title="Qtd. Documento"
          dataIndex="number_of_documents"
        />
        <Table.Column<ItemListServiceOrderType>
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          key="emergency_transport"
          title="Emergencial ?"
          dataIndex="emergency_transport"
          render={(value, serviceOrder) => (value ? "Sim" : "Não")}
        />
        <Table.Column<ItemListServiceOrderType>
          showSorterTooltip={false}
          key="active"
          title="Status"
          dataIndex="active"
          render={(value, serviceOrder) => {
            return (
              <Space>
                {serviceOrder.printed && (
                  <Tooltip trigger="hover" title="Ordem impressa" destroyTooltipOnHide mouseLeaveDelay={0} zIndex={11}>
                    <Button
                      className="cursor-help"
                      type="link"
                      icon={
                        <Icon
                          height={20}
                          icon="lets-icons:print-duotone"
                          className="text-blue-400 transition-all duration-100 ease-in hover:scale-110 hover:text-blue-500"
                        />
                      }
                    />
                  </Tooltip>
                )}

                {serviceOrder.ready && (
                  <Tooltip
                    trigger="hover"
                    title="Ordem pronta para atendimento"
                    destroyTooltipOnHide
                    mouseLeaveDelay={0}
                    zIndex={11}
                  >
                    <Button
                      className="cursor-help"
                      type="link"
                      icon={
                        <Icon
                          height={20}
                          icon="entypo:check"
                          className="text-green-400 transition-all duration-100 ease-in hover:scale-110 hover:text-green-500"
                        />
                      }
                    />
                  </Tooltip>
                )}
                {serviceOrder.canceled && (
                  <Tooltip trigger="hover" title="Ordem cancelada" destroyTooltipOnHide mouseLeaveDelay={0} zIndex={11}>
                    <Button
                      className="cursor-help"
                      type="link"
                      icon={
                        <Icon
                          height={20}
                          icon="lets-icons:cancel"
                          className="text-red-400 transition-all duration-100 ease-in hover:scale-110 hover:text-red-500"
                        />
                      }
                    />
                  </Tooltip>
                )}

                {serviceOrder.completed && (
                  <Tooltip trigger="hover" title="Ordem atendida" destroyTooltipOnHide mouseLeaveDelay={0} zIndex={11}>
                    <Button
                      className="cursor-help"
                      type="link"
                      icon={
                        <Icon
                          height={20}
                          icon="fluent:document-checkmark-16-filled"
                          className="text-green-400 transition-all duration-100 ease-in hover:scale-110 hover:text-green-500"
                        />
                      }
                    />
                  </Tooltip>
                )}
              </Space>
            );
          }}
        />
        <Table.Column<ItemListServiceOrderType>
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          key="status"
          title="Status atual"
          dataIndex="status"
          render={(value, serviceOrder) => ShowTag(StatusOrders, serviceOrder.status)}
        />

        <Table.Column<ItemListServiceOrderType>
          key="actions"
          width={200}
          align="center"
          title={
            <FixedColumn
              active={fixed.some((item) => item.dataIndex === "actions")}
              dataIndex="actions"
              direction="right"
              title="Ações"
              setFixed={setFixed}
            />
          }
          fixed={Fixed(fixed, "actions")}
          render={(value, serviceOrder) => {
            return (
              <>
                <div className="flex items-center justify-center w-full gap-1 px-2">
                  <D3Can action="view" resource="order_service" type="hidden">
                    <Tooltip
                      trigger="hover"
                      title="Visualizar ordem"
                      destroyTooltipOnHide
                      mouseLeaveDelay={0}
                      zIndex={11}
                    >
                      <Button
                        className="flex items-center justify-center"
                        type="text"
                        shape="default"
                        icon={
                          <Icon
                            icon="carbon:document-view"
                            height={18}
                            width={18}
                            className="text-blue-400 transition-all duration-100 ease-in hover:text-blue-500"
                          />
                        }
                        size={"middle"}
                        onClick={() =>
                          openDrawer(
                            <ViewServiceOrderForm cuid={serviceOrder.cuid} />,
                            "right",
                            600,
                            "Visualizar Ordem de Serviço"
                          )
                        }
                      />
                    </Tooltip>
                  </D3Can>

                  <D3Can action="print" resource="order_service" type="hidden">
                    {serviceOrder.canceled ? null : (
                      <Tooltip
                        title={`${
                          serviceOrder.canceled
                            ? "Impossível realizar a impressão, ordem está cancelada."
                            : "Imprimir ordem"
                        }`}
                        destroyTooltipOnHide
                        trigger="hover"
                        mouseLeaveDelay={0}
                        zIndex={11}
                      >
                        <Button
                          disabled={serviceOrder.canceled}
                          className="flex items-center justify-center"
                          type="text"
                          shape="default"
                          icon={
                            <Icon
                              icon="lets-icons:print-duotone"
                              height={24}
                              width={24}
                              className="text-blue-400 transition-all duration-100 ease-in hover:text-blue-500"
                            />
                          }
                          size={"middle"}
                          onClick={() =>
                            openDrawer(
                              <PrintServiceOrderForm
                                cuid={serviceOrder.cuid}
                                fileName={`Ordem de service nº ${serviceOrder.id}`}
                                printed={serviceOrder.printed}
                              />,
                              "right",
                              1000,
                              "Imprimir Ordem de Serviço",
                              true
                            )
                          }
                        />
                      </Tooltip>
                    )}
                  </D3Can>

                  <D3Can action="view" resource="order_service" type="hidden">
                    {serviceOrder.canceled || serviceOrder.completed ? null : (
                      <Tooltip
                        title={`${
                          serviceOrder.canceled
                            ? "Ordem cancelada."
                            : serviceOrder.completed
                            ? "Ordem atendida."
                            : "Documentos da ordem"
                        }`}
                        trigger="hover"
                        destroyTooltipOnHide
                        mouseLeaveDelay={0}
                        zIndex={11}
                      >
                        <Button
                          disabled={serviceOrder.canceled || serviceOrder.completed}
                          className="flex items-center justify-center"
                          type="text"
                          shape="default"
                          onClick={() =>
                            openDrawer(
                              <DocumentsServiceOrderForm orderCuid={serviceOrder.cuid} />,
                              "right",
                              900,
                              "Documentos da Ordem"
                            )
                          }
                          icon={
                            <Icon
                              icon="ion:documents"
                              height={20}
                              width={20}
                              className="text-blue-400 transition-all duration-100 ease-in hover:text-blue-500"
                            />
                          }
                          size={"middle"}
                        />
                      </Tooltip>
                    )}
                  </D3Can>

                  <D3Can action="completed" resource="order_service" type="hidden">
                    {serviceOrder.completed || serviceOrder.canceled || serviceOrder.ready ? null : (
                      <Tooltip
                        title={`${
                          serviceOrder.canceled
                            ? "Ordem cancelada."
                            : serviceOrder.completed
                            ? "Ordem atendida."
                            : serviceOrder.ready
                            ? "Ordem pronta para atendimento."
                            : "Informar que a separação está concluída."
                        }`}
                        trigger="hover"
                        destroyTooltipOnHide
                        mouseLeaveDelay={0}
                        zIndex={11}
                      >
                        <Button
                          disabled={serviceOrder.canceled || serviceOrder.completed || serviceOrder.ready}
                          className="flex items-center justify-center"
                          type="text"
                          shape="default"
                          onClick={() => {
                            if (serviceOrder.has_documentation_center_address) {
                              confirmationDialog({
                                title: `Atenção !`,
                                icon: "question",
                                text: "Confirme se a ordem de serviço está pronta para atendimento.",
                                showConfirmButton: true,
                                confirmButtonText: `Confirmar`,
                                showCancelButton: true,
                                cancelButtonText: "Cancelar",
                                cancelFunction: () => {},
                                confirmFunction: async () => {
                                  await readyServiceOrder(serviceOrder.cuid, { documentation_center_address_cuid: "" });
                                },
                              });
                            } else {
                              openDrawer(
                                <ReadyServiceOrderForm orderCuid={serviceOrder.cuid} />,
                                "right",
                                600,
                                "Informar que a tapa de separação foi concluída"
                              );
                            }
                          }}
                          icon={
                            <Icon
                              icon="entypo:check"
                              height={20}
                              width={20}
                              className="text-green-400 transition-all duration-100 ease-in hover:text-green-500"
                            />
                          }
                          size={"middle"}
                        />
                      </Tooltip>
                    )}
                  </D3Can>

                  <D3Can action="serve" resource="order_service" type="hidden">
                    {serviceOrder.canceled || serviceOrder.completed || serviceOrder.ready === false ? null : (
                      <Tooltip
                        title={`${
                          serviceOrder.canceled
                            ? "Impossível realizar o atendimento, ordem está cancelada."
                            : serviceOrder.completed
                            ? "Impossível realizar o atendimento, ordem já atendida."
                            : "Atender a ordem"
                        }`}
                        trigger="hover"
                        destroyTooltipOnHide
                        mouseLeaveDelay={0}
                        zIndex={11}
                      >
                        <Button
                          disabled={serviceOrder.completed || serviceOrder.canceled}
                          className="flex items-center justify-center"
                          type="text"
                          shape="default"
                          onClick={() =>
                            openDrawer(
                              <ServeServiceOrderForm orderCuid={serviceOrder.cuid} />,
                              "right",
                              700,
                              "Atender ordem de Serviço"
                            )
                          }
                          icon={
                            <Icon
                              icon="fluent:document-checkmark-16-filled"
                              height={20}
                              width={20}
                              className="text-green-400 transition-all duration-100 ease-in hover:text-green-500"
                            />
                          }
                          size={"middle"}
                        />
                      </Tooltip>
                    )}
                  </D3Can>

                  <D3Can action="serve" resource="order_service" type="hidden">
                    {serviceOrder.canceled ? null : (
                      <Tooltip
                        trigger="hover"
                        title={`${
                          serviceOrder.canceled
                            ? "Impossível enviar ordem digitalizada, ordem está cancelada."
                            : "Envio/reenvio da ordem digitalizada"
                        }`}
                        destroyTooltipOnHide
                        mouseLeaveDelay={0}
                        zIndex={11}
                      >
                        <Button
                          disabled={serviceOrder.canceled}
                          className="flex items-center justify-center"
                          type="text"
                          shape="default"
                          icon={
                            <Icon
                              icon="fa6-solid:file-signature"
                              height={16}
                              className="text-blue-400 transition-all duration-100 ease-in hover:text-blue-500"
                            />
                          }
                          size={"middle"}
                          onClick={() =>
                            openDrawer(
                              <SendSignedServiceOrderForm
                                orderCuid={serviceOrder.cuid}
                                fileName={`Ordem de serviço nº ${serviceOrder.id}`}
                                isUploadOrder={serviceOrder.has_order_resent}
                              />,
                              "right",
                              600,
                              "Envio / reenvio da ordem digitalizada"
                            )
                          }
                        />
                      </Tooltip>
                    )}
                  </D3Can>

                  <D3Can action="cancel" resource="order_service" type="hidden">
                    {serviceOrder.completed || serviceOrder.canceled || serviceOrder.ready === false ? null : (
                      <Tooltip
                        title={`${
                          serviceOrder.completed
                            ? "Impossível realizar o cancelamento, ordem está atendida."
                            : serviceOrder.canceled
                            ? "Impossível realizar o cancelamento, ordem já cancelada."
                            : "Cancelar ordem"
                        }`}
                        trigger="hover"
                        destroyTooltipOnHide
                        mouseLeaveDelay={0}
                        zIndex={11}
                      >
                        <Button
                          disabled={serviceOrder.canceled || serviceOrder.completed}
                          className="flex items-center justify-center"
                          type="text"
                          shape="default"
                          onClick={() =>
                            D3PopConfirmation({
                              confirmText: "Confirmar Cancelamento",
                              input: "textarea",
                              text: "Informe o motivo do cancelamento.",
                              icon: "warning",
                              preConfirm: async (reason: string) => {
                                await cancelServiceOrder(serviceOrder.cuid, reason);
                              },
                            })
                          }
                          icon={
                            <Icon
                              icon="lets-icons:cancel"
                              height={20}
                              width={20}
                              className="text-red-400 transition-all duration-100 ease-in hover:text-red-500"
                            />
                          }
                          size={"middle"}
                        />
                      </Tooltip>
                    )}
                  </D3Can>
                </div>
              </>
            );
          }}
        />
      </Table>
    </div>
  );
};

export default ServiceOrdersView;
