import { create } from "zustand";
import { UpdatePasswordSystemUserType, UpdateSystemUserType, ViewSystemUserType } from "./types";
import { BadRequest, ClientHttp, StandardError, Success } from "@utils/clientHttp";

type CompanyStoreState = {
  activeTab: string;
  setActiveTab: (tab: string) => void;
  getActiveTab: () => string;
  getSystemUser: () => Promise<Success<ViewSystemUserType> | StandardError>;
  updateSystemUser: (payload: UpdateSystemUserType) => Promise<Success<void> | BadRequest | StandardError>;
  updatePasswordSystemUser: (
    payload: UpdatePasswordSystemUserType
  ) => Promise<Success<void> | BadRequest | StandardError>;
};

export const useCompaniesStore = create<CompanyStoreState>((set, get) => ({
  activeTab: "1",
  setActiveTab: (tab: string) => {
    set({ activeTab: tab });
  },
  getActiveTab: () => {
    return get().activeTab;
  },
  getSystemUser: async (): Promise<Success<ViewSystemUserType> | StandardError> => {
    return await new ClientHttp().get<Success<ViewSystemUserType>, StandardError>(`/api/v1/system/user`);
  },

  updateSystemUser: async (payload: UpdateSystemUserType): Promise<Success<void> | BadRequest | StandardError> => {
    return await new ClientHttp().put<UpdateSystemUserType, Success<void>, BadRequest | StandardError>(
      `/api/v1/system/user`,
      payload,
      (result: Success<void>) => {
        get().getSystemUser();
      }
    );
  },

  updatePasswordSystemUser: async (
    payload: UpdatePasswordSystemUserType
  ): Promise<Success<void> | BadRequest | StandardError> => {
    return await new ClientHttp().patch<UpdatePasswordSystemUserType, Success<void>, BadRequest | StandardError>(
      `/api/v1/system/user`,
      payload,
      (result: Success<void>) => {
        get().getSystemUser();
      }
    );
  },

  
}));
