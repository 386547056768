import { create } from "zustand";
import {
  NotificationsType,
  UpdateNotificationsType,
  ItemSimpleListUsersCustomerType,
  SimpleListCustomerUserType,
  UserCustomerNotificationType,
} from "./types";
import showMessage from "@utils/showMessage";
import { ClientHttp, BadRequest, StandardError, Success } from "@utils/clientHttp";

type NotificationStoreState = {
  loading: boolean;
  loadNotifications: () => Promise<void>;
  notifications: NotificationsType;
  oldNotifications: NotificationsType;
  handleChange: (key: string, value: any, index?: number) => void;
  updateNotifications: () => Promise<Success<void> | BadRequest | StandardError>;
  loadingUpdate: boolean;
  deleteUser: (userCuids: string | string[]) => void;
  simpleListUsersCustomer: () => Promise<void>;
  insertUsersNotification: (notificationsUsers: UserCustomerNotificationType[]) => void;
  usersCustomer: ItemSimpleListUsersCustomerType[];
  convertNotificationsToPayload: () => UpdateNotificationsType;
  selectedRows: string[];
  resetState: () => void;
};

const defaultState = {
  loading: false,
  notifications: {
    notify_requesting_user_on_order_creation: [],
    notify_requesting_user_on_order_status_change: [],
    user_company_notifications: [],
  },
  oldNotifications: {
    notify_requesting_user_on_order_creation: [],
    notify_requesting_user_on_order_status_change: [],
    user_company_notifications: [],
  },
  selectedRows: [],
  usersCustomer: [],
  loadingUpdate: false,
};

export const useNotificationStore = create<NotificationStoreState>((set, get) => ({
  ...defaultState,
  resetState: () => set(defaultState),
  loadNotifications: async (): Promise<void> => {
    set({ loading: true });
    await new ClientHttp().get<Success<NotificationsType>, BadRequest | StandardError>(
      "/api/v1/company/notifications",
      (result: Success<NotificationsType>) => {
        if (result.body) {
          set({
            notifications: result.body,
            oldNotifications: result.body,
          });
        }
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
      }
    );
    set({ loading: false });
  },
  handleChange: (key: string, value: any, index?: number) => {
    set((prev) => {
      let newNotifications = { ...prev.notifications };

      if (typeof index === "number") {
        newNotifications.user_company_notifications = newNotifications.user_company_notifications.map((item, i) =>
          i === index ? { ...item, [key]: value } : item
        );
      } else {
        newNotifications = {
          ...newNotifications,
          [key]: value,
        };
      }

      return { notifications: newNotifications };
    });
  },
  deleteUser: (userCuids: string | string[]) => {
    set((prev) => {
      const notifications = prev.notifications;
      const cuidsArray = Array.isArray(userCuids) ? userCuids : [userCuids];
      const updatedUserNotifications = notifications.user_company_notifications.filter(
        (item) => !cuidsArray.includes(item.user.cuid)
      );

      return {
        notifications: {
          ...notifications,
          user_company_notifications: updatedUserNotifications,
        },
      };
    });
  },
  convertNotificationsToPayload: () => {
    const { notifications } = get();
    const payload: UpdateNotificationsType = {
      notify_requesting_user_on_order_creation: notifications.notify_requesting_user_on_order_creation,
      notify_requesting_user_on_order_status_change: notifications.notify_requesting_user_on_order_status_change,
      user_company_notifications: notifications.user_company_notifications.map((notification) => ({
        notification_channel_types: notification.notification_channel_types,
        notify_on_expiring_documents: notification.notify_on_expiring_documents,
        notify_on_order_creation: notification.notify_on_order_creation,
        notify_on_order_status_change: notification.notify_on_order_status_change,
        user_cuid: notification.user.cuid,
      })),
    };
    return payload;
  },

  updateNotifications: async (): Promise<Success<void> | BadRequest | StandardError> => {
    set({ loadingUpdate: true });
    return await new ClientHttp().put<UpdateNotificationsType, Success<void>, BadRequest | StandardError>(
      "/api/v1/company/notifications",
      get().convertNotificationsToPayload(),
      (result: Success<void>) => {
        showMessage(result, "Notificações atualizada com sucesso.");
        set({
          oldNotifications: get().notifications,
          loadingUpdate: false,
        });
      },
      (error: BadRequest | StandardError): void => {
        showMessage(error);
        set({ loadingUpdate: false });
      }
    );
  },
  simpleListUsersCustomer: async (): Promise<void> => {
    await new ClientHttp().getItensForSelect<Success<SimpleListCustomerUserType>, StandardError, void>(
      "/api/v1/company/users/simple-list",
      (result: Success<SimpleListCustomerUserType>): void => {
        set({
          usersCustomer: result.body.data,
        });
      },
      (error: StandardError): void => {
        showMessage(error);
      }
    );
  },
  insertUsersNotification: (notificationsUsers: UserCustomerNotificationType[]) => {
    set((prev) => {
      const existingNotifications = prev.notifications.user_company_notifications;
      const newNotifications = notificationsUsers.filter(
        (newUser) => !existingNotifications.some((existingUser) => existingUser.user.cuid === newUser.user.cuid)
      );
      return {
        notifications: {
          ...prev.notifications,
          user_company_notifications: [...existingNotifications, ...newNotifications],
        },
      };
    });
  },
}));
