import { create } from "zustand";
import { ExtensionItemType, ExtensionListType } from "./types";
import { BadRequest, ClientHttp, StandardError, Success } from "@utils/clientHttp";
import showMessage from "@utils/showMessage";

type UseUploadManagedType = {
  simpleListExtensionsSystem: () => Promise<void>;
  extensions: ExtensionItemType[];
  loadingExtensions: boolean;
};

const defaultState = {
  extensions: [],
  loadingExtensions: false,
};

export const UseUploadManagedStore = create<UseUploadManagedType>((set, get) => ({
  ...defaultState,
  simpleListExtensionsSystem: async (): Promise<void> => {
    set({ loadingExtensions: true });
    await new ClientHttp().get<Success<ExtensionListType>, BadRequest | StandardError>(
      "/api/v1/system/extensions/simple-list",
      (result: Success<ExtensionListType>): void => {
        set({
          extensions: result.body.data,
          loadingExtensions: false,
        });
      },
      (error: BadRequest | StandardError): void => {
        showMessage(error);
        set({ loadingExtensions: false });
      }
    );
  },
}));
