import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import SessionTimer from "@components/shared/SessionTimer";
import { Icon } from "@iconify/react";
import useAuthStore from "@provider/AuthProvider";
import type { MenuProps } from "antd";
import { Button, Divider, Dropdown, Layout, Radio, Space, theme } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useD3LayoutStore } from "../Store/useD3LayoutStore";
import D3SelectEntity from "./components/D3SelectEntity";
import UploadManager from "@components/shared/UploadManager";
import PreviewProfilePicture from "@components/shared/PreviewProfilePicture";

const { useToken } = theme;
const { Header } = Layout;

function D3HeaderBar() {
  const { logout, getUserInfo } = useAuthStore();
  const { collapsed, setIsOpen } = useD3LayoutStore();

  const userInfo = getUserInfo();
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const items: MenuProps["items"] = [
    {
      label: "Perfil",
      key: "1",
      icon: <Icon icon="streamline:interface-user-profile-focus-close-geometric-human-person-profile-focus-user" />,
      onClick: () => navigate("system/user"),
    },
    {
      label: "Sair",
      key: "2",
      icon: <Icon icon="el:off" />,
      danger: true,
      onClick: () => logout(),
    },
  ];

  const menuProps = {
    items,
  };

  const { token } = useToken();

  const contentStyle: React.CSSProperties = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };

  const menuStyle: React.CSSProperties = {
    boxShadow: "none",
  };

  return (
    <Header className="flex items-center justify-between p-2 my-1 mr-1 bg-white rounded shadow-sm max-h-14 ">
      <Space direction="horizontal">
        <Button
          type="text"
          icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          onClick={() => setIsOpen(!collapsed)}
          className="flex items-center justify-center text-gray-500 "
        />
        <div className="flex items-center justify-center">
          <Radio.Group
            size="small"
            value={i18n.language}
            onChange={(e) => i18n.changeLanguage(e.target.value)}
            options={[
              { label: <Icon height={22} icon="twemoji:flag-brazil" />, value: "pt" },
              { label: <Icon height={22} icon="twemoji:flag-united-states" />, value: "en" },
            ]}
            optionType="button"
          />
        </div>
        <D3SelectEntity />
      </Space>
      <div className="flex items-center gap-3">
        <SessionTimer />
        <UploadManager />
        <Dropdown
          placement="bottomRight"
          menu={menuProps}
          dropdownRender={(menu) => (
            <div style={contentStyle}>
              <div className="flex flex-col items-center justify-center px-5 py-2 select-none">
                <PreviewProfilePicture size={60} preview />
                <span>Olá, {userInfo?.Name.split(" ")[0]}!</span>
                <span className="text-xs">{userInfo.Email}</span>
              </div>
              <Divider style={{ margin: 0 }} />
              {React.cloneElement(menu as React.ReactElement, {
                style: menuStyle,
              })}
            </div>
          )}
        >
          <Button type="link" className="p-0 m-0">
            <PreviewProfilePicture />
          </Button>
        </Dropdown>
      </div>
    </Header>
  );
}

export default D3HeaderBar;
