import { Form } from "antd";
import { NamePath } from "antd/es/form/interface";
import { FormInstance } from "antd/lib";

import { ItemBOO, ItemCAC, ItemDAT, ItemDTI, ItemEMA, ItemIFT, ItemINT, ItemNMI, ItemSEL, ItemTXT } from ".";
import { DocumentTypeIndexType } from "../../../types";

type ItemsProps = {
  name: number;
  item: DocumentTypeIndexType;
};

const Items = ({
  name,
  item: {
    list_of_key_and_values,
    system_index_type: { key },
  },
  ...props
}: ItemsProps) => {
  switch (key) {
    case "EMA":
      return <ItemEMA {...props} name={name} />;
    case "CAC":
      return <ItemCAC {...props} name={name} />;
    case "DAT":
      return <ItemDAT {...props} name={name} />;
    case "DTI":
      return <ItemDTI {...props} name={name} />;
    case "BOO":
      return <ItemBOO {...props} name={name} />;
    case "NMI":
      return <ItemNMI {...props} name={name} />;
    case "TXT":
      return <ItemTXT {...props} name={name} />;
    case "INT":
      return <ItemINT {...props} name={name} />;
    case "IFT":
      return <ItemIFT {...props} name={name} />;
    case "SEL":
      return <ItemSEL {...props} name={name} values={list_of_key_and_values} />;
    default:
      return null;
  }
};

type RenderIndexersProps = {
  indexes: DocumentTypeIndexType[];
  form: FormInstance<any>;
  attribute: NamePath | NamePath[];
};

function RenderIndexers({ indexes, form, attribute }: RenderIndexersProps) {
  return (
    <Form.List name={attribute}>
      {(fields) => {
        return (
          <>
            {indexes.length > 0 &&
              fields.length === 0 &&
              form.setFieldsValue({
                indexes_values: indexes.map((item: DocumentTypeIndexType) => {
                  return {
                    id: item.id,
                    key: item.system_index_type.key,
                    start_value: "",
                    value: "",
                    end_value: "",
                  };
                }),
              })}
            {fields.map(({ key, name, ...restField }) => {
              const { document_type_index_id } = form.getFieldValue(["indexes_values"])[key];
              const index = indexes.filter((item) => item.id === document_type_index_id)[0];
              return (
                <Form.Item key={key} label={index ? index.label : ""} className="w-full px-2">
                  <Items {...restField} name={name} item={index} />
                </Form.Item>
              );
            })}
          </>
        );
      }}
    </Form.List>
  );
}

export default RenderIndexers;
