import { Icon } from "@iconify/react";
import { Tabs } from "antd";
import TabAddress from "./components/tabs/TabAddress";
import TabAdmins from "./components/tabs/TabAdmins";
import TabData from "./components/tabs/TabData";
import TabPrices from "./components/tabs/TabPrices";
import { useCompanyStore } from "./store";
import TabNotifications from "./components/tabs/TabNotifications";

const CompanyView: React.FC = () => {
  const { getActiveTab, setActiveTab } = useCompanyStore();
  return (
    <div className="h-full p-4 mb-1 mr-1 bg-white rounded shadow-md">
      <Tabs
        tabPosition="left"
        defaultActiveKey="1"
        activeKey={getActiveTab()}
        onTabClick={(tab) => {
          setActiveTab(tab);
        }}
        className="h-full select-none"
        items={[
          {
            key: "1",
            label: (
              <span className="flex items-center justify-center gap-2">
                <Icon icon="ri:profile-line" />
                Dados
              </span>
            ),
            children: <TabData />,
          },
          {
            key: "2",
            label: (
              <span className="flex items-center justify-center gap-2">
                <Icon icon="mdi:address-marker" />
                Endereços
              </span>
            ),
            children: <TabAddress />,
          },
          {
            key: "3",
            label: (
              <span className="flex items-center justify-center gap-2">
                <Icon icon="material-symbols:price-change" />
                Preço
              </span>
            ),
            children: <TabPrices />,
          },

          {
            key: "4",
            label: (
              <span className="flex items-center justify-center gap-2">
                <Icon icon="clarity:group-solid" />
                Administradores
              </span>
            ),
            children: <TabAdmins />,
          },
          {
            key: "5",
            label: (
              <span className="flex items-center justify-center gap-2">
                <Icon icon="mingcute:notification-fill" />
                Notificações
              </span>
            ),
            children: <TabNotifications />,
          },
        ]}
      />
    </div>
  );
};

export default CompanyView;
