import { IndexesValueType } from "../../types";
import { DatePicker, Form, Input, InputNumber, Radio, Select } from "antd";
import dayjs from "dayjs";
const renderFormItem = ({
  end_value,
  start_value,
  value,
  document_type_index: {
    system_index_type: { key },
    list_of_key_and_values,
  },
}: IndexesValueType) => {
  const keyType = key;
  switch (keyType) {
    case "TXT":
      return <Input defaultValue={value} type="text" className={`w-full`} />;
    case "INT":
    case "IFT":
      return <InputNumber defaultValue={value} />;
    case "BOO":
      return (
        <Radio.Group
          value={value === "true"}
          optionType="button"
          buttonStyle="solid"
          options={[
            { label: "Sim", value: true },
            { label: "Não", value: false },
          ]}
        />
      );
    case "CAC":
      return <Input defaultValue={value} />;
    case "SEL":
      return (
        <Select
          defaultValue={value}
          options={list_of_key_and_values.map((option) => {
            return {
              value: option.key,
              label: option.value,
            };
          })}
          value={value}
          className="w-40"
        ></Select>
      );
    case "DAT":
      const date = value ? dayjs(value) : "";
      return <DatePicker defaultValue={date} format="DD/MM/YYYY" className="w-32" />;
    case "DTI":
      const sdate = start_value ? dayjs(start_value) : "";
      const edate = end_value ? dayjs(end_value) : "";
      return (
        <div className="flex gap-5">
          <div className="flex items-center gap-5">
            Início <DatePicker defaultValue={sdate} format="DD/MM/YYYY" className="w-32" />
            Fim <DatePicker defaultValue={edate} format="DD/MM/YYYY" className="w-32" />
          </div>
        </div>
      );
    case "NMI":
      return (
        <div className="flex items-center gap-5">
          Início <Input className="w-32" defaultValue={start_value} />
          Fim <Input className="w-32" defaultValue={end_value} />
        </div>
      );
    case "EMA":
      return <Input defaultValue={value} />;
    default:
      return null;
  }
};
export const viewIndexer = (item: IndexesValueType) => (
  <Form.Item label={item.document_type_index.label}>{renderFormItem(item)}</Form.Item>
);
