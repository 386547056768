import { useContext, useEffect } from "react";
import { useSpecialsStore } from "../store";
import { Button, Checkbox, Form, Input, InputNumber, Segmented, Select, Space, Table, Tag, Tooltip } from "antd";
import Selector from "@components/shared/Selector";
import { Icon } from "@iconify/react";
import StatusSelect from "@components/shared/StatusSelect";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import { ItemDocumentType, PayloadType, ResponseRequestServiceOrderType } from "../types";
import confirmationDialog from "@components/shared/confirmationDialog";
import { useNavigate } from "react-router-dom";
import useAuthStore from "@provider/AuthProvider";

const { TextArea, Search } = Input;

function GenerateWorkOrder() {
  const {
    listServicesAvailableForCustomer,
    listServicesAvailable,
    loadingListServices,
    simpleListUsersCustomer,
    users,
    loadingUsers,
    simpleListDepartmentCustomer,
    loadingDepartments,
    departments,
    simpleListAddressesCustomer,
    addresses,
    loadingAddresses,
    payload,
    responsibleInList,
    serviceSelected,
    requestNewServiceOrder,
    allDescription,
    loadingDescription,
    deleteDocument,
  } = useSpecialsStore();
  const { onClose } = useContext(D3DrawerContext);
  const [form] = Form.useForm<PayloadType>();
  const { getUserInfo } = useAuthStore();
  const navigate = useNavigate();

  //const requiredEmail = payload.department_cuid === getUserInfo()

  const columns = [
    {
      title: "Documento",
      dataIndex: "fs_document_physical_tag_id",
      key: "fs_document_physical_tag_id",
    },
    {
      title: "Descrição para Localização",
      dataIndex: "description",
      hidden: !serviceSelected.requires_document_description_for_location,
      align: "center" as const,
      key: "description",
      render: (value: any, record: any) => (
        <Form.Item
          rules={[
            {
              required: record.required_description,
              message: "",
            },
          ]}
          noStyle
          name={[record.fs_document_physical_tag_id, "description"]}
        >
          <Input />
        </Form.Item>
      ),
    },
    {
      title: "Quantidade",
      dataIndex: "quantity",
      align: "center" as const,
      hidden: !serviceSelected.is_quantity_variable,
      key: "quantity",
      render: (value: any, record: any) => (
        <Form.Item
          noStyle
          name={[record.fs_document_physical_tag_id, "quantity"]}
          rules={[
            {
              required: record.required_description,
              message: "",
            },
          ]}
        >
          <InputNumber min={0} />
        </Form.Item>
      ),
    },
    {
      title: "Ação",
      dataIndex: "action",
      key: "action",
      align: "center" as const,
      render: (value: any, record: any) => (
        <div className="flex items-center justify-center">
          <Tooltip title="Excluir" destroyTooltipOnHide mouseLeaveDelay={0} zIndex={11}>
            <Button
              type="text"
              onClick={() => deleteDocument(record.fs_document_physical_tag_id)}
              shape="default"
              icon={
                <Icon
                  icon="material-symbols:delete"
                  height={18}
                  width={18}
                  className="text-red-400 transition-all duration-100 ease-in hover:text-red-500"
                />
              }
              size={"middle"}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  useEffect(() => {
    (async () => await listServicesAvailableForCustomer())();
  }, []);

  useEffect(() => {
    form.setFieldsValue(payload);
  }, [payload]);

  const descriptionInput = Form.useWatch("all_description", form);

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        onFinish={async (values: PayloadType) => {
            const result = await requestNewServiceOrder();
          if (result.status === "success") {
            const { os_id } = result.body as ResponseRequestServiceOrderType;
            confirmationDialog({
              title: `Ordem requisitada com sucesso. Nº ${os_id}`,
              showConfirmButton: true,
              confirmButtonText: `Ir para Ordens de Serviço`,
              showCancelButton: true,
              cancelButtonText: "Requisitar nova ordem",
              cancelFunction: () => onClose(),
              confirmFunction: () => {
                onClose();
                navigate("orders/service");
              },
            });
          } 
        }}
        onValuesChange={async (changedValues: any, values: any) => {
          const key = Object.keys(changedValues)[0];
          const value = changedValues[key];

          useSpecialsStore.setState((state) => ({
            payload: {
              ...state.payload,
              [key]: value,
            },
          }));

          /*  if (isNaN(Number(key))) {
            
          } else {
            useSpecialsStore.setState((state) => {
              const newObj: ItemDocumentType[] = state.payload.fs_documents.map((doc) => {
                if (doc.fs_document_physical_tag_id === key) {
                  const objKey = Object.keys(value)[0];
                  const objValue = Object.values(value)[0];
                  return {
                    ...doc,
                    [objKey]: objValue,
                  };
                }
                return doc;
              });
              return {
                payload: {
                  ...state.payload,
                  fs_documents: newObj,
                },
              };
            });
          } */
        }}
      >
        <Form.Item<PayloadType>
          name="service_cuid"
          label="Serviço"
          rules={[{ required: true, message: "Por favor, selecione um serviço" }]}
        >
          <Selector
            fieldMap={{
              value: "cuid",
              label: "description",
              dataLabel: ["description"],
              dataFilter: ["description"],
            }}
            onChange={async (e) => {
              if (e) {
                await simpleListUsersCustomer();
                await simpleListDepartmentCustomer();
                await simpleListAddressesCustomer();
                useSpecialsStore.setState({
                  serviceSelected: listServicesAvailable.find((srv) => srv.cuid === e),
                });
              }
            }}
            loading={loadingListServices}
            options={listServicesAvailable}
          />
        </Form.Item>

        <div className="flex items-center justify-between gap-10">
          {responsibleInList ? (
            <Form.Item<PayloadType>
              className="w-full h-[70px]"
              hidden={!responsibleInList}
              label="Nome do Responsável"
              name="requester_person_name"
              rules={[{ required: responsibleInList, message: "Por favor, insira o responsável" }]}
            >
              <Input />
            </Form.Item>
          ) : (
            <Form.Item<PayloadType>
              className="w-full h-[70px]"
              hidden={!payload.service_cuid}
              label="Responsável"
              name="requester_user_cuid"
              rules={[{ required: !responsibleInList, message: "Por favor, selecione um responsável" }]}
            >
              <Selector
                fieldMap={{
                  status: "active",
                  value: "cuid",
                  label: "username",
                  description: "name",
                  dataLabel: ["username"],
                  dataFilter: ["username", "name"],
                  disabled: true,
                }}
                loading={loadingUsers}
                options={users}
              />
            </Form.Item>
          )}
          <Form.Item hidden={!payload.service_cuid}>
            <Checkbox
              value={responsibleInList}
              onChange={(e) => useSpecialsStore.setState({ responsibleInList: e.target.checked })}
              className="text-start"
            >
              Responsável não está na lista
            </Checkbox>
          </Form.Item>
        </div>

        <Form.Item<PayloadType>
          hidden={!responsibleInList}
          label="Email do Responsável"
          name="requester_person_email"
          rules={[{ required: responsibleInList, message: "Por favor, insira o email." }]}
        >
          <Input />
        </Form.Item>

        <Form.Item<PayloadType>
          hidden={!payload.service_cuid}
          label="Departamento Faturamento"
          name="billable_department_cuid"
        >
          <Selector
            fieldMap={{
              status: "active",
              value: "cuid",
              label: "description",
              description: "abbreviation",
              dataLabel: ["description", "abbreviation"],
              dataFilter: ["description", "abbreviation"],
              disabled: true,
            }}
            loading={loadingDepartments}
            options={departments.filter((dep) => dep.cuid !== payload.department_cuid)}
          />
        </Form.Item>

        <Form.Item<PayloadType> hidden={!payload.service_cuid}>
          <Table
            rowKey="fs_document_physical_tag_id"
            columns={columns}
            dataSource={payload.fs_documents}
            bordered
            size="small"
            pagination={false}
          />
        </Form.Item>

        <Form.Item
          name="all_description"
          //hidden={!serviceSelected.requires_document_description_for_location}
          label="Preencher todas descrições com o conteúdo"
        >
          <Space.Compact className="w-full">
            <Input />
            <Button loading={loadingDescription} type="primary" onClick={() => allDescription(descriptionInput)}>
              Preencher
            </Button>
          </Space.Compact>
        </Form.Item>

        <Form.Item<PayloadType>
          hidden={!serviceSelected.requires_address_information}
          label="Endereço"
          name="customer_address_cuid"
          rules={[
            { required: serviceSelected.requires_address_information, message: "Por favor, selecione um endereço." },
          ]}
        >
          <Select
            loading={loadingAddresses}
            listItemHeight={10}
            listHeight={250}
            allowClear
            removeIcon={
              <Icon
                icon="iconamoon:close-duotone"
                className="w-4 h-4 text-red-400 transition-all duration-300 ease-in-out hover:text-red-600"
              />
            }
            menuItemSelectedIcon={<Icon icon="eva:checkmark-outline" className="w-5 h-5 text-blue-500" />}
            optionLabelProp="dataLabel"
            showSearch
            maxTagCount="responsive"
            size="middle"
            className="w-full truncate select-none"
            optionFilterProp="children"
            filterOption={(input, option) => option?.dataFilter.toLowerCase().includes(input.toLowerCase())}
          >
            {addresses.map(
              ({
                city,
                complement,
                cuid,
                description,
                state,
                street_address,
                street_number,
                zip_code,
                active,
                is_default,
              }) => (
                <Select.Option
                  key={cuid}
                  value={cuid}
                  dataFilter={`${description} ${addresses}`}
                  dataLabel={`${description}`}
                >
                  <div className="flex items-center justify-between ">
                    <div className="flex flex-col truncate">
                      <span className="truncate">{description}</span>
                      <span className="text-[10px] truncate">{`${street_address}, nº ${street_number}${
                        complement ? ", " + complement : ""
                      }, ${city} - ${state}, Cep ${zip_code}`}</span>
                    </div>
                    <div className="flex items-center justify-between gap-2">
                      {is_default && (
                        <Tooltip title="Endereço Padrão" placement="right">
                          <Tag
                            className="flex items-center justify-between gap-1"
                            icon={<Icon icon="bi:geo-alt-fill" />}
                            color="success"
                          >
                            Padrão
                          </Tag>
                        </Tooltip>
                      )}
                      <StatusSelect status={active} />
                    </div>
                  </div>
                </Select.Option>
              )
            )}
          </Select>
        </Form.Item>

        <Form.Item<PayloadType>
          hidden={!serviceSelected.requires_address_information}
          name="emergency_transport"
          label="Transporte Emergencial?"
        >
          <Segmented
            value={payload.emergency_transport}
            options={[
              { label: "Sim", value: true },
              { label: "Não", value: false },
            ]}
          />
        </Form.Item>

        <Form.Item<PayloadType>
          hidden={!payload.service_cuid}
          label="Email"
          name="email"
          rules={[{ required: serviceSelected.requires_email_for_sending, message: "Por favor, insira o email." }]}
        >
          <Input />
        </Form.Item>

        <Form.Item<PayloadType> hidden={!payload.service_cuid} label="Observação" name="observation">
          <TextArea rows={4} />
        </Form.Item>

        <Form.Item hidden={!payload.service_cuid}>
          <div className="grid grid-cols-2 gap-4">
            <Button htmlType="submit" type="primary">
              Finalizar Ordem de Serviço
            </Button>

            <Button type="dashed" onClick={() => onClose()} danger>
              Fechar Tela e Continuar Adicionando Documento à Ordem
            </Button>
          </div>
        </Form.Item>
      </Form>
    </>
  );
}

export default GenerateWorkOrder;
