import FormItemConditionalDate from "@components/shared/FormItemConditionalDate";
import { getAllRepositoryStatusForSelect } from "@utils/RepositoryStatus";
import { Form, FormInstance, Input } from "antd";
import { useEffect, useState } from "react";
import { SearchType } from "./types";
import FormItemSwitcher from "./FormItemSwitcher";
import useSearchStore from "./store";
import Selector from "@components/shared/Selector";

type SearchDocumentFormProps = {
  form: FormInstance<SearchType>;
};

const options = [
  { label: "Igual a", value: "equal" },
  { label: "Maior que", value: "greater" },
  { label: "Menor que", value: "less" },
  { label: "No intervalo", value: "range" },
];

function SearchDocumentForm({ form }: SearchDocumentFormProps) {
  const { simpleListDepartmentCustomer, departments, simpleListExtensions, extensions } = useSearchStore();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      if (departments.length === 0) {
        await simpleListDepartmentCustomer();
      }
      if (extensions.length === 0) {
        simpleListExtensions();
      }
      setLoading(false);
    })();
  }, []);

  return (
    <div className="flex flex-col w-full h-full p-2 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100 !important">
      <Form.Item label="Departamentos" name={["filter_document", "departments_cuids"]} className="w-full p-0 my-2">
        <Selector
          fieldMap={{
            status: "active",
            value: "cuid",
            label: "abbreviation",
            description: "description",
            dataLabel: ["abbreviation", "description"],
            dataFilter: ["abbreviation", "description"],
          }}
          mode="multiple"
          options={departments}
          loading={loading}
          disabled={loading}
        />
      </Form.Item>

      <FormItemSwitcher
        attribute={["filter_document", "document_physical_tag_id"]}
        label="Etiquetas de documento"
        form={form}
      />

      <Form.Item label="Intervalo de documentos" className="p-0 my-2">
        <div className="flex items-center gap-3">
          <Form.Item
            className="w-full p-0 m-0"
            name={["filter_document", "document_range", "start_document_physical_tag_id"]}
          >
            <Input showCount maxLength={12} />
          </Form.Item>
          até
          <Form.Item
            className="w-full p-0 m-0"
            name={["filter_document", "document_range", "end_document_physical_tag_id"]}
          >
            <Input showCount maxLength={12} />
          </Form.Item>
        </div>
      </Form.Item>

      <Form.Item label="Descrição" className="p-0 my-2" name={["filter_document", "name"]}>
        <Input />
      </Form.Item>
      <Form.Item className="w-full p-0 my-2" label="Situação" name={["filter_document", "status"]}>
        <Selector
          fieldMap={{
            value: "value",
            label: "label",
            dataLabel: ["label"],
            dataFilter: ["label"],
          }}
          mode="multiple"
          options={getAllRepositoryStatusForSelect()}
        />
      </Form.Item>
      <Form.Item label="Extensões" name={["filter_document", "extensions_ids"]} className="w-full p-0 my-2">
        <Selector
          fieldMap={{
            value: "id",
            label: "description",
            description: "key",
            dataLabel: ["description", "key"],
            dataFilter: ["description", "key"],
          }}
          mode="multiple"
          options={extensions}
          loading={loading}
          disabled={loading}
        />
      </Form.Item>

      <FormItemConditionalDate<SearchType>
        attribute={["filter_document", "created_at"]}
        label="Data cadastramento"
        options={options}
        form={form}
      />

      <FormItemConditionalDate<SearchType>
        attribute={["filter_document", "expected_purge_date"]}
        label="Data previsão de expurgo"
        options={options}
        form={form}
      />

      <FormItemConditionalDate<SearchType>
        attribute={["filter_document", "purged_at"]}
        label="Data de expurgo"
        options={options}
        form={form}
      />
    </div>
  );
}

export default SearchDocumentForm;
