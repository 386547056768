import { useMemo, useState, useEffect, useRef } from "react";
import { Button, Tooltip, Input } from "antd";
import VirtualList from "rc-virtual-list";
import { UploadFile } from "antd/lib";
import { useUploadContext } from "@context/Upload/UploadContext";
import { UseUploadManagedStore } from "./store";
import { extension } from "@utils/mine";
import SpinLoading from "@components/shared/SpinLoading";
import { Icon } from "@iconify/react";
import formatBytes from "@utils/formatBytes";
import InputFiles from "./components/InputFiles";

interface CustomUploadFile extends UploadFile {
  duplicate?: boolean;
  progress?: number;
}

function NewUploadManager() {
  const { fileList, uploading, loadingFiles, handleUpload, deleteFile, renameFile, resetFileList } = useUploadContext();
  const { simpleListExtensionsSystem, loadingExtensions, extensions } = UseUploadManagedStore();
  const [edit, setEdit] = useState<string>("");
  const [fileName, setFileName] = useState<string>("");
  const divRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState<number>(0);

  useEffect(() => {
    (async () => {
      await simpleListExtensionsSystem();
    })();
  }, []);

  useEffect(() => {
    const divElement = divRef.current;
    if (!divElement) return;
    setHeight(divElement.offsetHeight);
    const handleResize = (entries: ResizeObserverEntry[]) => {
      for (let entry of entries) {
        if (entry.contentRect) {
          setHeight(entry.contentRect.height);
        }
      }
    };
    const resizeObserver = new ResizeObserver(handleResize);
    resizeObserver.observe(divElement);
    return () => {
      resizeObserver.unobserve(divElement);
    };
  }, [divRef, fileList]);

  const renderItem = useMemo(
    () => (file: CustomUploadFile) => {
      const ext = extension(file.type ?? "não reconhecido");
      return (
        <div className="grid grid-cols-12 border-b h-11">
          <div className="flex items-center justify-center col-span-1 ">
            <Icon
              className="drop-shadow-md"
              height={24}
              icon={extensions.find((ext) => ext.key === extension(file.type ?? ""))?.icon ?? ""}
            />
          </div>
          <div className="flex items-center justify-start col-span-5 px-2 ">
            {edit === file.uid ? (
              <Input
                className="w-full"
                onChange={(e) => setFileName(e.target.value)}
                value={edit === file.uid ? fileName : file.name.replace(String(ext), "")}
              />
            ) : (
              <span className="font-medium truncate max-w-[300px] ">{file.name.replace(String(ext), "")} </span>
            )}
          </div>
          <div className="flex items-center justify-center col-span-2">
            <span className="uppercase">{ext?.replace(".", "")}</span>
          </div>
          <div className="flex items-center justify-center col-span-2">
            <span className="uppercase"> {formatBytes(file.size ?? 0)}</span>
          </div>
          <div className="flex items-center justify-center col-span-2 gap-2">
            <Tooltip title={edit === file.uid ? "Salvar" : "Editar"}>
              <Button
                type="text"
                onClick={() => {
                  if (edit === file.uid) {
                    renameFile(file.uid, fileName);
                    setEdit("");
                    setFileName("");
                  } else {
                    setEdit(file.uid);
                    setFileName(file.name.replace(String(ext), ""));
                  }
                }}
                icon={
                  edit === file.uid ? (
                    <Icon
                      height={18}
                      className="text-green-400 transition-all duration-200 ease-in hover:text-green-500 hover:scale-105 "
                      icon="uil:save"
                    />
                  ) : (
                    <Icon
                      height={18}
                      className="text-blue-400 transition-all duration-200 ease-in hover:text-blue-500 hover:scale-105 "
                      icon="eva:edit-2-fill"
                    />
                  )
                }
              />
            </Tooltip>
            <Tooltip title="Excluir">
              <Button
                type="text"
                onClick={() => deleteFile(file.uid)}
                icon={
                  <Icon
                    height={18}
                    className="text-red-400 transition-all duration-200 ease-in hover:text-red-500 hover:scale-105 "
                    icon="material-symbols:delete"
                  />
                }
              />
            </Tooltip>
          </div>
        </div>
      );
    },
    [fileList, edit, fileName]
  );

  return (
    <div className="flex justify-between h-full mb-1 mr-1 overflow-hidden bg-white rounded shadow">
      {loadingExtensions ? (
        <SpinLoading />
      ) : (
        <div className="flex flex-col h-full overflow-hidden  gap-3 min-w-[800px] p-4">
          <InputFiles />

          <div className="flex flex-col h-full overflow-hidden border rounded shadow">
            <div className="grid items-center flex-none w-full grid-cols-3 px-2 border-b h-11">
              <div></div>
              <div className="flex items-center justify-center">
                <span className="text-xl font-semibold text-gray-600">Gerenciador de Uploads</span>
              </div>
              <div className="flex items-center justify-end"></div>
            </div>
            <div className="grid h-12 grid-cols-12 font-semibold text-gray-600 border-b divide-x shadow-b">
              <div className="flex items-center justify-center col-span-1 ">icon</div>
              <div className="flex items-center justify-start col-span-5 px-2 ">Nome</div>
              <div className="flex items-center justify-center col-span-2">
                <span>Tipo</span>
              </div>
              <div className="flex items-center justify-center col-span-2">
                <span> Tamanho</span>
              </div>
              <div className="flex items-center justify-center col-span-2 gap-2">Ações</div>
            </div>
            <div className="flex-col flex-grow h-full overflow-hidden">
              {loadingFiles ? (
                <SpinLoading />
              ) : (
                <div ref={divRef} className="flex-grow h-full overflow-auto">
                  <VirtualList
                    virtual
                    data={fileList}
                    height={height}
                    itemHeight={43}
                    itemKey="uid"
                    className="flex flex-col max-h-full overflow-hidden"
                  >
                    {(item: CustomUploadFile) => renderItem(item)}
                  </VirtualList>
                </div>
              )}
            </div>
            <div className="grid flex-none w-full h-10 grid-cols-2 px-2 border-t shadow-t max-h-10">
              <div className="flex items-center justify-start"></div>
              <div className="flex items-center justify-end ">
                <Button size="small" className="max-w-max" danger type="dashed" onClick={() => resetFileList()}>
                  Apagar todos ({fileList.length})
                </Button>
              </div>
            </div>
          </div>

          <Button
            size="large"
            type="primary"
            onClick={handleUpload}
            loading={uploading}
            disabled={!fileList.length || edit !== ""}
          >
            Enviar
          </Button>
        </div>
      )}
    </div>
  );
}

export default NewUploadManager;
