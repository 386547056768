import Swal, { SweetAlertIcon } from "sweetalert2";

type CTMessageProps = {
  title: string;
  text?: string;
  icon?: SweetAlertIcon;
};

function CTMessage({ title, text = undefined, icon = undefined }: CTMessageProps) {
  return Swal.fire({
    title: title,
    text: text,
    icon: icon,
    heightAuto: false,
  });
}

export default CTMessage;
