import Swal, { SweetAlertIcon, SweetAlertInput, SweetAlertOptions } from "sweetalert2";

type D3PopConfirmationProps = {
  title?: string;
  input: SweetAlertInput;
  confirmText: string;
  confirmButtonColor?: string;
  preConfirm: (inputValue: string) => Promise<any>;
  icon?: SweetAlertIcon;
  text: string;
  minLength?: number;
  maxLength?: number;
};

function D3PopConfirmation({
  title = "",
  input,
  confirmText,
  confirmButtonColor = "#22c55e",
  preConfirm,
  icon = "question",
  text,
  minLength,
  maxLength,
}: D3PopConfirmationProps) {
  const options: SweetAlertOptions = {
    title: title,
    text: text,
    icon: icon,
    input: input,
    inputAttributes: {
      autocapitalize: "off",
    },
    inputAutoTrim: true,
    heightAuto: false,
    showCancelButton: true,
    cancelButtonText: "Fechar",
    confirmButtonColor: confirmButtonColor,
    confirmButtonText: confirmText,
    showLoaderOnConfirm: true,
    preConfirm: async (inputValue) => {
      if (minLength && inputValue.length < minLength) {
        Swal.showValidationMessage(`O mínimo de caracteres é ${minLength}.`);
        return;
      }
      if (maxLength && inputValue.length > maxLength) {
        Swal.showValidationMessage(`O máximo de caracteres é ${maxLength}.`);
        return;
      }
      await preConfirm(inputValue as string);
    },
    allowOutsideClick: () => !Swal.isLoading(),
    didOpen: () => {
      const confirmButton = Swal.getConfirmButton();
      const inputField = Swal.getInput();
      if (confirmButton && inputField) {
        confirmButton.disabled = inputField.value.trim().length === 0;
        inputField.addEventListener("input", () => {
          confirmButton.disabled = inputField.value.trim().length === 0;
        });
      }
    },
  };

  return Swal.fire(options);
}

export default D3PopConfirmation;
