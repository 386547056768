import { D3DrawerContext } from "@provider/D3DrawerContext";
import { Button, DatePicker, Divider, Form, Input, Radio, Spin } from "antd";
import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import RenderIndexers from "./indexers/RenderIndexer";
import {
  UpdateIndexersType,
  ViewStructuredDocumentType,
  IndexItemType,
  IndexesValueType,
  DocumentTypeIndexType,
} from "../../types";
import useStructuredDocumentStore from "../../store";
import dayjs from "dayjs";

type EditIndexersFormProps = {
  documentCuid: string;
  setShow: Dispatch<SetStateAction<boolean>>;
  show: boolean;
};

function OldIndexersForm({ documentCuid, setShow, show }: EditIndexersFormProps) {
  const { viewStructuredDocument, view, loadingView, updateIndexers } = useStructuredDocumentStore();
  const { onClose } = useContext(D3DrawerContext);
  const [form] = Form.useForm<UpdateIndexersType>();
  const [loadingSave, setLoadingSave] = useState<boolean>(false);
  const [structuredDocumentType, setStructuredDocumentType] = useState<ViewStructuredDocumentType>();
  const [indexes, setIndexes] = useState<DocumentTypeIndexType[]>([]);

  useEffect(() => {
    (async () => {
      await viewStructuredDocument(documentCuid);
    })();
    return () => useStructuredDocumentStore.setState({ view: {} as ViewStructuredDocumentType });
  }, []);

  useEffect(() => {
    if (view.cuid) {
      (async () => {
        setStructuredDocumentType(view);
        setIndexes(view.indexes_values.map((index) => index.document_type_index));
        form.setFieldsValue({
          document_type_cuid: view.document_type.cuid,
          expected_purge_date: view.expected_purge_date,
          original_date: view.original_date,
          indexes_values: view.indexes_values.map((item) => {
            return {
              document_type_index_id: item.document_type_index.id,
              end_value: item.end_value,
              start_value: item.start_value,
              value: item.value,
            };
          }),
          is_permanent_storage: view.is_permanent_storage,
        });
      })();
    }
  }, [view]);

  return (
    <Form
      className="flex flex-col h-full overflow-hidden "
      form={form}
      layout="vertical"
      onFinish={async (payload: UpdateIndexersType) => {
        setLoadingSave(true);
        const result = await updateIndexers(documentCuid, payload);
        if (result) {
          onClose();
        }
        setLoadingSave(false);
      }}
    >
      {loadingView ? (
        <div className="flex items-center justify-center h-full">
          <Spin />
        </div>
      ) : (
        <div className="flex flex-col h-full px-2 overflow-hidden">
          <div className="h-full overflow-y-auto grow custom-scroll">
            <Form.Item<UpdateIndexersType> name="document_type_cuid" className="px-2" label="Tipo de documento">
              <div className="flex gap-3 ">
                <Input value={structuredDocumentType?.document_type.description} disabled />
                <Button danger onClick={() => setShow((prevState) => !prevState)} type="dashed">
                  {`${show ? "Fechar" : "Trocar"}`}
                </Button>
              </div>
              <div className="flex items-center justify-between mt-8">
                <Form.Item<UpdateIndexersType>
                  className="p-0 m-0 w-44"
                  name="is_permanent_storage"
                  label="Guarda permanente?"
                >
                  <Radio.Group
                    disabled
                    options={[
                      { value: true, label: "Sim" },
                      { value: false, label: "Não" },
                    ]}
                  />
                </Form.Item>

                <Form.Item<UpdateIndexersType>
                  getValueProps={(value) => ({ value: value ? dayjs(value) : dayjs() })}
                  normalize={(value) => (value ? dayjs(value).format("YYYY-MM-DD") : null)}
                  className="p-0 m-0 w-52"
                  label="Data original"
                  name="original_date"
                >
                  <DatePicker
                    disabled
                    format={{
                      format: "DD/MM/YYYY",
                      type: "mask",
                    }}
                  />
                </Form.Item>

                <Form.Item<UpdateIndexersType>
                  getValueProps={(value) => ({ value: value ? dayjs(value) : null })}
                  normalize={(value) => (value ? dayjs(value).format("YYYY-MM-DD") : null)}
                  className="p-0 m-0 w-52"
                  label="Previsão de expurgo"
                  name="expected_purge_date"
                >
                  <DatePicker
                    disabled
                    format={{
                      format: "DD/MM/YYYY",
                    }}
                  />
                </Form.Item>
              </div>
            </Form.Item>
            {indexes && (
              <>
                <Divider>
                  <span className="text-sm font-medium">Indexadores</span>
                </Divider>
                <RenderIndexers form={form} attribute={["indexes_values"]} indexes={indexes} />
              </>
            )}
          </div>
          <div className="flex-none w-full h-14">
            <Form.Item hidden={show} className="p-0 m-0">
              <div className="flex items-center justify-end gap-3 h-14">
                <Button type="default" danger onClick={() => onClose()}>
                  Cancelar
                </Button>
                <Button loading={loadingSave} type="primary" htmlType="submit">
                  Salvar
                </Button>
              </div>
            </Form.Item>
          </div>
        </div>
      )}
    </Form>
  );
}

export default OldIndexersForm;
