import { InfoCircleOutlined } from "@ant-design/icons";
import D3Can from "@components/shared/D3Can";
import { Icon } from "@iconify/react";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import { Button, Form, Select, Space, Tooltip } from "antd";
import { useContext, useEffect, useState } from "react";
import { useDeploymentOrdersStore } from "../store";
import { ItemSimpleListCellsType, ItemSimpleListShelvesType, ItemSimpleListStorageType, ServedType } from "../types";

type ServeDeploymentOrderFormProps = {
  orderCuid: string;
};

function ServeDeploymentOrderForm({ orderCuid }: ServeDeploymentOrderFormProps) {
  const { simpleListStorage, simpleListShelves, simpleListCells, savedDeploymentOrder, loadingServe } =
    useDeploymentOrdersStore();
  const [form] = Form.useForm<ServedType>();
  const { onClose } = useContext(D3DrawerContext);

  const [storage, setStorage] = useState<ItemSimpleListStorageType[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      setStorage(await simpleListStorage());
    })();
    setLoading(false);
  }, [simpleListStorage]);

  const [shelves, setShelves] = useState<ItemSimpleListShelvesType[]>([]);
  const [loadingShelves, setLoadingShelves] = useState<boolean>(false);
  const handleShelves = async (storageCuid: string) => {
    setShelves(await simpleListShelves(storageCuid));
    setLoadingShelves(false);
  };

  const [cells, setCells] = useState<ItemSimpleListCellsType[]>([]);
  const [loadingCells, setLoadingCells] = useState<boolean>(false);
  const handleCells = async (storageCuid: string, shelfCuid: string) => {
    setCells(await simpleListCells(storageCuid, shelfCuid, orderCuid));
    setLoadingCells(false);
  };

  return (
    <Form
      className="w-full h-full select-none"
      form={form}
      layout="vertical"
      onFinish={async (payload: ServedType) => {
        const isOk = await savedDeploymentOrder(orderCuid, payload);
        if (isOk) {
          onClose();
        }
      }}
    >
      <Form.Item<ServedType>
        name="storage_cuid"
        label="Galpão"
        rules={[{ required: true, message: "Por favor, selecione o galpão." }]}
      >
        <Select
          loading={loading}
          disabled={storage.length === 0}
          listItemHeight={10}
          allowClear
          removeIcon={
            <Icon
              icon="iconamoon:close-duotone"
              className="w-4 h-4 text-red-400 transition-all duration-300 ease-in-out hover:text-red-600"
            />
          }
          menuItemSelectedIcon={<Icon icon="eva:checkmark-outline" className="w-5 h-5 text-blue-500" />}
          optionLabelProp="dataLabel"
          showSearch
          maxTagCount="responsive"
          size="middle"
          className="w-full truncate select-none"
          optionFilterProp="children"
          onClear={() => {
            form.setFieldValue("shelf_cuid", null);
            form.setFieldValue("cell_cuid", null);
            setShelves([]);
            setCells([]);
          }}
          filterOption={(input, option) => option?.dataFilter.toLowerCase().includes(input.toLowerCase())}
          onChange={async (value) => {
            if (value) {
              form.setFieldValue("shelf_cuid", null);
              form.setFieldValue("cell_cuid", null);
              setShelves([]);
              setCells([]);
              setLoadingShelves(true);
              await handleShelves(value);
            }
          }}
        >
          {storage.map(({ active, cuid, description, id, key_document_storage }) => (
            <Select.Option
              key={cuid}
              value={cuid}
              dataFilter={`${key_document_storage} - ${description}`}
              dataLabel={`${key_document_storage} - ${description}`}
            >
              <div className="flex items-center justify-between ">
                <div className="flex flex-col truncate">
                  <span className="truncate">{`${key_document_storage} - ${description}`}</span>
                </div>

                {active ? (
                  <Tooltip title="Galpão desbloqueado" placement="right">
                    <span>
                      <Icon icon="basil:unlock-solid" className="text-green-500" height={16} width={16} />
                    </span>
                  </Tooltip>
                ) : (
                  <Tooltip title="Galpão bloqueado" placement="right">
                    <span>
                      <Icon icon="basil:lock-solid" className="text-red-500" height={16} width={16} />
                    </span>
                  </Tooltip>
                )}
              </div>
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item<ServedType> name="shelf_cuid" label="Estante">
        <Select
          disabled={shelves.length === 0}
          loading={loadingShelves}
          listItemHeight={10}
          allowClear
          removeIcon={
            <Icon
              icon="iconamoon:close-duotone"
              className="w-4 h-4 text-red-400 transition-all duration-300 ease-in-out hover:text-red-600"
            />
          }
          menuItemSelectedIcon={<Icon icon="eva:checkmark-outline" className="w-5 h-5 text-blue-500" />}
          optionLabelProp="dataLabel"
          showSearch
          maxTagCount="responsive"
          size="middle"
          className="w-full truncate select-none"
          optionFilterProp="children"
          onClear={() => {
            form.setFieldValue("cell_cuid", null);
            setCells([]);
          }}
          filterOption={(input, option) => option?.dataFilter.toLowerCase().includes(input.toLowerCase())}
          onChange={async (value) => {
            const storageCuid = form.getFieldValue("storage_cuid");
            if (value) {
              form.setFieldValue("cell_cuid", null);
              setCells([]);
              setLoadingCells(true);
              await handleCells(storageCuid, value);
            }
          }}
        >
          {shelves.map(({ abbreviation, active, cuid, storage_id }) => (
            <Select.Option
              key={cuid}
              value={cuid}
              dataFilter={`${abbreviation} (${storage_id})`}
              dataLabel={`${abbreviation} (${storage_id})`}
            >
              <div className="flex items-center justify-between ">
                <div className="flex flex-col truncate">
                  <span className="truncate">{`${abbreviation} (${storage_id})`}</span>
                </div>
                {active ? (
                  <Tooltip title="Estante desbloqueada" placement="right">
                    <span>
                      <Icon icon="basil:unlock-solid" className="text-green-500" height={16} width={16} />
                    </span>
                  </Tooltip>
                ) : (
                  <Tooltip title="Estante bloqueada" placement="right">
                    <span>
                      <Icon icon="basil:lock-solid" className="text-red-500" height={16} width={16} />
                    </span>
                  </Tooltip>
                )}
              </div>
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item<ServedType> name="cell_cuid" label="Vão/Prateleira">
        <Select
          disabled={cells.length === 0}
          loading={loadingCells}
          listItemHeight={10}
          allowClear
          removeIcon={
            <Icon
              icon="iconamoon:close-duotone"
              className="w-4 h-4 text-red-400 transition-all duration-300 ease-in-out hover:text-red-600"
            />
          }
          menuItemSelectedIcon={<Icon icon="eva:checkmark-outline" className="w-5 h-5 text-blue-500" />}
          optionLabelProp="dataLabel"
          showSearch
          maxTagCount="responsive"
          size="middle"
          className="w-full truncate select-none"
          optionFilterProp="children"
          onClear={() => {
            form.setFieldValue("cell_cuid", null);
          }}
          filterOption={(input, option) => option?.dataFilter.toLowerCase().includes(input.toLowerCase())}
        >
          {cells.map(({ active, column, cuid, row, id, number_of_empty_positions, repository_type_description }) => (
            <Select.Option
              key={cuid}
              value={cuid}
              dataFilter={`${id} - ${repository_type_description} - Vão: ${column} - Prat: ${row} - Vagas: ${number_of_empty_positions}`}
              dataLabel={`${id} - ${repository_type_description} - Vão: ${column} - Prat: ${row} - Vagas: ${number_of_empty_positions}`}
            >
              <div className="flex items-center justify-between ">
                <div className="flex flex-col truncate">
                  <span className="truncate">{`${id} - ${repository_type_description} - Vão: ${column} - Prat: ${row} - Vagas: ${number_of_empty_positions}`}</span>
                </div>
                {active ? (
                  <Tooltip title="Vão/Prateleira desbloqueada" placement="right">
                    <span>
                      <Icon icon="basil:unlock-solid" className="text-green-500" height={16} width={16} />
                    </span>
                  </Tooltip>
                ) : (
                  <Tooltip title="Vão/Prateleira bloqueada" placement="right">
                    <span>
                      <Icon icon="basil:lock-solid" className="text-red-500" height={16} width={16} />
                    </span>
                  </Tooltip>
                )}
              </div>
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item<ServedType>
        name="increase_option"
        label="Incrementar"
        tooltip={{
          title:
            "Se não houver espaço o suficiente no vão/prateleira selecionado serão utilizados os vãos/prateleiras seguintes incrementando",
          icon: <InfoCircleOutlined />,
        }}
      >
        <Select
          disabled={storage.length === 0}
          listItemHeight={10}
          allowClear
          removeIcon={
            <Icon
              icon="iconamoon:close-duotone"
              className="w-4 h-4 text-red-400 transition-all duration-300 ease-in-out hover:text-red-600"
            />
          }
          menuItemSelectedIcon={<Icon icon="eva:checkmark-outline" className="w-5 h-5 text-blue-500" />}
          optionLabelProp="dataLabel"
          showSearch
          maxTagCount="responsive"
          size="middle"
          className="w-full truncate select-none"
          optionFilterProp="children"
          onClear={() => {
            form.setFieldValue("increase_option", null);
          }}
        >
          {[
            { label: "Coluna (Vão)", value: "C" },
            { label: "Linha (Prateleira)", value: "R" },
          ].map(({ label, value }) => (
            <Select.Option key={value} value={value} dataFilter={label} dataLabel={label}>
              <div className="flex items-center justify-start ">
                <div className="flex flex-col truncate">
                  <span className="truncate">{label}</span>
                </div>
              </div>
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item>
        <div className="text-right">
          <Space size="small">
            <Button type="default" danger onClick={() => onClose()}>
              Cancelar
            </Button>
            <D3Can action="create" resource="order_deployment">
              <Button loading={loadingServe} type="primary" htmlType="submit">
                Baixar
              </Button>
            </D3Can>
          </Space>
        </div>
      </Form.Item>
    </Form>
  );
}

export default ServeDeploymentOrderForm;
