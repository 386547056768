import { Button, Form, Segmented, Space, Spin } from "antd";
import { useContext, useEffect } from "react";
import D3Can from "@components/shared/D3Can";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import Selector from "@components/shared/Selector";
import { useReportsStore } from "../store";
import { RunReportType } from "../types";
import Swal from "sweetalert2";
import InputGenerator from "./InputGenerator";

type UpdateRepositoryTypeProps = {
  reportCuid: string;
};

export const GenerateReportForm = ({ reportCuid }: UpdateRepositoryTypeProps) => {
  const { runReport, listParamsReport, listParams, loadingListParams } = useReportsStore();
  const { onClose } = useContext(D3DrawerContext);
  const [form] = Form.useForm<RunReportType>();

  useEffect(() => {
    (async () => {
      await listParamsReport(reportCuid);
    })();
    return () => useReportsStore.setState({ listParams: [] });
  }, [reportCuid]);

  const type = Form.useWatch("report_type", form);

  return (
    <>
      <Spin spinning={loadingListParams}>
        <Form
          className="select-none"
          layout="vertical"
          form={form}
          onFinish={async (values: RunReportType) => {
            Swal.fire({
              title: "Gerando relatório...",
              text: "Por favor, aguarde enquanto geramos o relatório.",
              allowOutsideClick: false,
              heightAuto: false,
              didOpen: () => {
                Swal.showLoading();
              },
            });
            const isGenerate = await runReport(reportCuid, values);
            if (isGenerate) {
              onClose();
            }
          }}
          autoComplete="off"
        >
          <Form.Item<RunReportType>
            name="report_type"
            label="Tipo do relatório"
            initialValue="xlsx"
            rules={[{ required: true, message: "Por favor, selecione um tipo." }]}
          >
            <Selector
              fieldMap={{
                value: "value",
                label: "label",
                dataLabel: ["label"],
                dataFilter: ["label"],
              }}
              options={[
                { value: "xlsx", label: "Excel" },
                { value: "pdf", label: "PDF" },
              ]}
              onChange={(e) => {
                if (e === "xlsx") {
                  form.setFieldValue("page_orientation", "");
                }
              }}
            />
          </Form.Item>
          <Form.Item<RunReportType>
            name="page_orientation"
            initialValue="P"
            label="Layout"
            hidden={type === "xlsx"}
            rules={[{ required: type === "pdf", message: "Por favor, selecione a orientação." }]}
          >
            <Selector
              fieldMap={{
                value: "value",
                label: "label",
                dataLabel: ["label"],
                dataFilter: ["label"],
              }}
              options={[
                { value: "P", label: "Retrato" },
                { value: "L", label: "Paisagem" },
              ]}
          
            />
          </Form.Item>

          <Form.Item<RunReportType> name="show_header" label="Mostrar cabeçalho  do relatório?" initialValue={true}>
            <Segmented
              options={[
                { label: "Sim", value: true },
                { label: "Não", value: false },
              ]}
            />
          </Form.Item>

          {listParams.length > 0 ? listParams.map((param) => <InputGenerator input={param} />) : null}

          <Form.Item>
            <div className="text-right">
              <Space size="small">
                <Button type="default" danger onClick={() => onClose()}>
                  Cancelar
                </Button>
                <D3Can action="view" resource="report">
                  <Button type="primary" htmlType="submit">
                    Gerar relatório
                  </Button>
                </D3Can>
              </Space>
            </div>
          </Form.Item>
        </Form>
      </Spin>
    </>
  );
};
