import { Icon } from "@iconify/react";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import { Avatar, Button, Dropdown, Tooltip } from "antd";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ItemsType } from "./ItemsType";

import { typeFile } from "../types";
import ViewFile from "../../file/view-file/ViewFile";
import useNavigateStore from "../store";

interface RenderGridProps {
  cuid: string;
  name: string;
  type: typeFile;
  isPhysical: boolean;
  fileUploadCompleted: boolean;
  icon: string;
  createdUserName: string;
}

function ItemGrid({ cuid, name, type, isPhysical, fileUploadCompleted, icon, createdUserName }: RenderGridProps) {
  const { openDrawer } = useContext(D3DrawerContext);
  const navigate = useNavigate();
  function handleItemClick(cuid: string, name: string, type: typeFile) {
    navigate(`/app/customer/spaces/work/${cuid}`);
  }

  return (
    <div
      className="flex flex-col border-[1px] border-gray-100 m-2  text-gray-500 transition-all duration-300 ease-in-out rounded select-none min-w-60 max-w-60 max-h-48 min-h-48 hover:drop-shadow-lg bg-gray-50 hover:bg-gray-100 cursor-pointer"
      onContextMenu={(event: React.MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
      }}
      onDoubleClick={() => {
        if (type === "FLE") {
          openDrawer(<ViewFile fileCuid={cuid} />, "right", 1000, "Visualizar arquivo");
        } else {
          useNavigateStore.setState({ isFilter: false });
          handleItemClick(cuid, name, type);
        }
      }}
    >
      <div className="flex items-center justify-between max-h-8 pl-2 text-gray-600 border-b-[1px] border-gray-200">
        <div className="flex w-full gap-1">
          <div className="flex items-center justify-center w-5">
            <Icon height={20} icon={icon} />
          </div>
          <div className="flex w-[186px] flex-grow truncate justify-start items-center whitespace-nowrap">
            <Tooltip title={name}>
              <span className="truncate"> {name}</span>
            </Tooltip>
          </div>
        </div>
        <div className="flex w-5">
          <Dropdown
            menu={{
              items: ItemsType({
                cuid: cuid,
                name: name,
                type: type,
                menu: "FLS",
              }) as any[],
            }}
            placement="bottomLeft"
          >
            <Button className="flex items-center justify-center" type="link" icon={<Icon icon="charm:menu-kebab" />} />
          </Dropdown>
        </div>
      </div>

      <div className="flex items-center justify-center grow ">
        <Icon className="drop-shadow-md" icon={icon} height={70} />
      </div>

      <div className="flex items-center justify-between min-h-8 px-2  text-gray-600 border-t-[1px] border-gray-200">
        <div className="flex items-center justify-start gap-1 max-w-32">
          <div>
            <Avatar alt={createdUserName} size="small">
              {createdUserName[0]}
            </Avatar>
          </div>
          <Tooltip trigger="hover" title={createdUserName}>
            <span className="w-32 text-xs truncate text-start">{createdUserName.split(" ")[0]}</span>
          </Tooltip>
        </div>
        {type === "FLE" && (
          <Tooltip trigger="hover" title={`${fileUploadCompleted ? "Upload concluído" : "Upload em processo"}`}>
            <Icon
              className={`drop-shadow-md ${fileUploadCompleted ? "text-blue-400" : "text-red-400"}`}
              icon={`${fileUploadCompleted ? "mdi:cloud-check" : "mdi:cloud-refresh"}`}
              height={18}
            />
          </Tooltip>
        )}
        {type === "SDC" && (
          <Tooltip trigger="hover" title={`${isPhysical ? "Documento físico" : "Documento digital"}`}>
            <Icon
              className={`drop-shadow-md ${isPhysical ? "text-red-400" : "text-blue-400"}`}
              icon={`${isPhysical ? "bxs:box" : "streamline:web-solid"}`}
              height={18}
            />
          </Tooltip>
        )}
      </div>
    </div>
  );
}

export default ItemGrid;
