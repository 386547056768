import D3QSGenerator, { FilterDefinition } from "@utils/D3QSGenerator";
import { BadRequest, ClientHttp, StandardError, Success } from "@utils/clientHttp";
import showMessage from "@utils/showMessage";
import { create } from "zustand";
import {
  CancelRelocation,
  CreateRelocationType,
  ItemSimpleListCellsType,
  ItemSimpleListCustomerType,
  ItemSimpleListDepartmentType,
  ItemSimpleListeRepositoriesType,
  ItemSimpleListRepositoryType,
  ItemSimpleListShelvesType,
  ItemSimpleListStorageType,
  ResponseCreateRelocationType,
  SearchRelocationType,
  SearchRepositoriesType,
  ServeRelocationType,
  SimpleListCellsType,
  SimpleListCustomerType,
  SimpleListDepartmentType,
  SimpleListeRepositoriesType,
  SimpleListRelocationsType,
  SimpleListRepositoryType,
  SimpleListShelvesType,
  SimpleListStorageType,
  UrlPrintRelocationType,
  UrlPrintResendRelocationType,
  UrlSendRelocationType,
  ViewRelocationType,
} from "./types";

type RelocationStoreState = {
  resetState: () => void;

  resetFilter: () => void;

  loadRelocations: () => Promise<void>;
  loading: boolean;
  apiResult: SimpleListRelocationsType;

  setFilter: (filter: SearchRelocationType) => void;
  filter: SearchRelocationType;

  setPagination: (page: number, pageSize: number) => void;
  pagination: { page: number; pageSize: number };

  createRelocation: (payload: CreateRelocationType) => Promise<boolean>;

  listRepositoriesSuitable: (repositoryTypeCuid: string) => Promise<void>;
  repositoryTypeCuid: string;
  totalRepositoriesSuitable: number;
  loadingRepositoriesSuitable: boolean;
  repositoriesSuitable: ItemSimpleListeRepositoriesType[];
  setPaginationRepositories: (page: number, pageSize: number) => void;
  paginationRepositories: { page: number; pageSize: number };
  filterRepositories: SearchRepositoriesType;
  setFilterRepositories: (filterRepositories: SearchRepositoriesType) => void;
  resetFilterRepositories: () => void;

  ViewRelocation: (relocationCuid: string) => Promise<void>;
  viewRelocation: ViewRelocationType;
  loadingView: boolean;

  cancelRelocation: (relocationCuid: string, cancelReason: string) => Promise<boolean>;

  getUrlPrint: (relocationCuid: string) => Promise<void>;
  loadingUrlPrint: boolean;
  urlPrint: string;

  getUrlPrintResend: (relocationCuid: string) => Promise<void>;
  urlPrintResend: string;

  getUrlResend: (relocationCuid: string) => Promise<void>;
  urlResend: string;

  serveRelocation: (relocationCuid: string, payload: ServeRelocationType) => Promise<boolean>;
  loadingServe: boolean;

  simpleListRepositoryType: () => Promise<void>;
  repositoryTypes: ItemSimpleListRepositoryType[];

  selectedRows: string[];

  simpleListStorage: () => Promise<void>;
  loadingStorages: boolean;
  listStorages: ItemSimpleListStorageType[];

  simpleListShelves: (storageCuid: string) => Promise<void>;
  loadingShelves: boolean;
  listShelves: ItemSimpleListShelvesType[];

  simpleListCells: (storageCuid: string, shelfCuid: string, orderCuid: string) => Promise<void>;
  loadingCells: boolean;
  listCells: ItemSimpleListCellsType[];

  simpleListCustomer: () => Promise<void>;
  loadingCustomer: boolean;
  listCustomer: ItemSimpleListCustomerType[];

  simpleListDepartmentByCustomerCuid: (customerCuid: string) => Promise<void>;
  loadingDepartments: boolean;
  listDepartments: ItemSimpleListDepartmentType[];
};

const relocationOrdersFilter: FilterDefinition = {
  ro_id: { op: "eq", type: "string" },
  uc_name: { op: "eq", type: "string" },
  ro_status: { op: "eq", type: "string" },
  r_repository_physical_tag_id: { op: "eq", type: "string" },
  ro_action_type: { op: "lk", type: "string" },
  ro_has_order_resent: { op: "eq", type: "active" },
};

const repositoriesOrdersFilter: FilterDefinition = {
  rep_repository_physical_tag_id: { op: "eq", type: "string" },
  customer_cuid: { op: "eq", type: "string" },
  department_cuid: { op: "eq", type: "string" },
  position_id: { op: "lk", type: "string" },
};

const defaultState = {
  apiResult: {
    total: 0,
    data: [],
  },
  loading: false,
  filter: {
    ro_id: "",
    uc_name: "",
    ro_status: "WTG",
    ro_has_order_resent: "all",
    r_repository_physical_tag_id: "",
    ro_action_type: "",
  } as SearchRelocationType,
  pagination: {
    page: 1,
    pageSize: 10,
  },
  repositoriesSuitable: [],
  loadingRepositoriesSuitable: false,
  totalRepositoriesSuitable: 0,

  urlPrint: "",
  loadingUrlPrint: false,

  urlPrintResend: "",
  urlResend: "",
  repositoryTypes: [],
  selectedRows: [],

  loadingStorages: false,
  listStorages: [],

  loadingShelves: false,
  listShelves: [],

  loadingCells: false,
  listCells: [],

  loadingServe: false,

  viewRelocation: {} as ViewRelocationType,
  loadingView: false,

  paginationRepositories: {
    page: 1,
    pageSize: 10,
  },
  repositoryTypeCuid: "",
  filterRepositories: {
    rep_repository_physical_tag_id: "",
    customer_cuid: "",
    department_cuid: "",
    position_id: "",
  } as SearchRepositoriesType,

  loadingCustomer: false,
  listCustomer: [],

  loadingDepartments: false,
  listDepartments: [],
};

export const useRelocationStore = create<RelocationStoreState>((set, get) => ({
  ...defaultState,
  resetState: () => set(defaultState),
  setFilter: (filter: SearchRelocationType) => {
    set({
      filter,
      pagination: { ...get().pagination, page: 1 },
    });
    get().loadRelocations();
  },
  resetFilter: () => {
    set({ filter: { ...defaultState.filter, ro_status: "" } });
    get().loadRelocations();
  },
  setPagination: (page: number, pageSize: number) => {
    if (pageSize !== get().pagination.pageSize) {
      page = 1;
    }
    set({ pagination: { page, pageSize } });
    get().loadRelocations();
  },
  loadRelocations: async (): Promise<void> => {
    set({ loading: true, viewRelocation: {} as ViewRelocationType });
    let qs = D3QSGenerator(get().filter, relocationOrdersFilter, get().pagination);
    await new ClientHttp().get<Success<SimpleListRelocationsType>, BadRequest | StandardError>(
      `/api/v1/orders/relocations?${qs}`,
      (result: Success<SimpleListRelocationsType>) => {
        set({ apiResult: result.body, loading: false });
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
        set({ loading: false });
      }
    );
  },

  createRelocation: async (payload: CreateRelocationType): Promise<boolean> => {
    const result = await new ClientHttp().post<
      CreateRelocationType,
      Success<ResponseCreateRelocationType>,
      BadRequest | StandardError
    >(
      "/api/v1/orders/relocations",
      payload,
      (result: Success<ResponseCreateRelocationType>) => {
        get().loadRelocations();
      },
      (error: BadRequest | StandardError): void => {
        showMessage(error);
      }
    );
    return result.status === "success";
  },

  listRepositoriesSuitable: async (repositoryTypeCuid: string): Promise<void> => {
    set({ loadingRepositoriesSuitable: true, repositoryTypeCuid: repositoryTypeCuid });
    let qs = D3QSGenerator(get().filterRepositories, repositoriesOrdersFilter, get().paginationRepositories);
    await new ClientHttp().post<
      { repository_type_cuid: string },
      Success<SimpleListeRepositoriesType>,
      BadRequest | StandardError
    >(
      `/api/v1/orders/relocations/repositories?${qs}`,
      { repository_type_cuid: repositoryTypeCuid },
      (result: Success<SimpleListeRepositoriesType>) => {
        set({ repositoriesSuitable: result.body.data, totalRepositoriesSuitable: result.body.total });
      },
      (error: BadRequest | StandardError): void => {
        showMessage(error);
      }
    );
    set({ loadingRepositoriesSuitable: false });
  },

  ViewRelocation: async (relocationCuid: string): Promise<void> => {
    set({ loadingView: true });
    await new ClientHttp().get<Success<ViewRelocationType>, BadRequest | StandardError>(
      `/api/v1/orders/relocations/${relocationCuid}`,
      (result: Success<ViewRelocationType>): void => {
        set({ viewRelocation: result.body });
      },
      (error: BadRequest | StandardError): void => {
        showMessage(error);
      }
    );
    set({ loadingView: false });
  },

  cancelRelocation: async (relocationCuid: string, cancelReason: string): Promise<boolean> => {
    const result = await new ClientHttp().patch<CancelRelocation, Success<void>, BadRequest | StandardError>(
      `/api/v1/orders/relocations/${relocationCuid}/cancel`,
      { cancel_reason: cancelReason },
      (result: Success<void>) => {
        showMessage(result, "Ordem cancelada com sucesso.");
        get().loadRelocations();
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
      }
    );
    return result.status === "success";
  },

  getUrlPrint: async (relocationCuid: string): Promise<void> => {
    set({ loadingUrlPrint: true });
    await new ClientHttp().get<Success<UrlPrintRelocationType>, StandardError>(
      `/api/v1/orders/relocations/${relocationCuid}/print`,
      (result: Success<UrlPrintRelocationType>): void => {
        set({ urlPrint: result.body.url });
      },
      (error: StandardError): void => {
        showMessage(error);
      }
    );
    set({ loadingUrlPrint: false });
  },

  getUrlPrintResend: async (relocationCuid: string): Promise<void> => {
    await new ClientHttp().get<Success<UrlPrintResendRelocationType>, StandardError>(
      `/api/v1/orders/relocations/${relocationCuid}/print-resend`,
      (result: Success<UrlPrintResendRelocationType>): void => {
        set({ urlPrintResend: result.body.url });
      },
      (error: StandardError): void => {
        showMessage(error);
      }
    );
  },

  getUrlResend: async (relocationCuid: string): Promise<void> => {
    await new ClientHttp().post<null, Success<UrlSendRelocationType>, BadRequest | StandardError>(
      `/api/v1/orders/relocations/${relocationCuid}/resend`,
      null,
      (result: Success<UrlSendRelocationType>): void => {
        set({ urlResend: result.body.url });
      },
      (error: BadRequest | StandardError): void => {
        showMessage(error);
      }
    );
  },
  serveRelocation: async (relocationCuid: string, payload: ServeRelocationType): Promise<boolean> => {
    set({ loadingServe: true });
    const result = await new ClientHttp().patch<ServeRelocationType, Success<void>, BadRequest | StandardError>(
      `/api/v1/orders/relocations/${relocationCuid}/serve`,
      payload,
      (result: Success<void>): void => {
        showMessage(result, "Ordem baixada com sucesso.");
        get().loadRelocations();
      },
      (error: BadRequest | StandardError): void => {
        showMessage(error);
      }
    );
    set({ loadingServe: false });
    return result.status === "success";
  },
  simpleListRepositoryType: async (): Promise<void> => {
    await new ClientHttp().getItensForSelect<Success<SimpleListRepositoryType>, StandardError, void>(
      `/api/v1/system/repository-types/simple-list`,
      (result: Success<SimpleListRepositoryType>): void => {
        set({ repositoryTypes: result.body.data });
      },
      (error: StandardError): void => {
        showMessage(error);
      }
    );
  },

  simpleListStorage: async (): Promise<void> => {
    set({ loadingStorages: true });
    await new ClientHttp().getItensForSelect<Success<SimpleListStorageType>, StandardError, void>(
      "/api/v1/system/storages/simple-list",
      (result: Success<SimpleListStorageType>): void => {
        set({ listStorages: result.body.data });
      },
      (error: StandardError): void => {
        showMessage(error);
      }
    );
    set({ loadingStorages: false });
  },

  simpleListShelves: async (storageCuid: string): Promise<void> => {
    set({ loadingShelves: true });
    await new ClientHttp().getItensForSelect<Success<SimpleListShelvesType>, StandardError, void>(
      `/api/v1/system/storages/${storageCuid}/shelves/simple-list`,
      (result: Success<SimpleListShelvesType>): void => {
        set({ listShelves: result.body.data });
      },
      (error: StandardError): void => {
        showMessage(error);
      }
    );
    set({ loadingShelves: false });
  },

  simpleListCells: async (storageCuid: string, shelfCuid: string, orderCuid: string): Promise<void> => {
    set({ loadingCells: true });
    await new ClientHttp().getItensForSelect<Success<SimpleListCellsType>, StandardError, void>(
      `/api/v1/system/storages/${storageCuid}/shelves/${shelfCuid}/cells/simple-list?relocationOrderCuid=${orderCuid}`,
      (result: Success<SimpleListCellsType>): void => {
        set({ listCells: result.body.data });
      },
      (error: StandardError): void => {
        showMessage(error);
      }
    );
    set({ loadingCells: false });
  },
  setPaginationRepositories: (page: number, pageSize: number) => {
    const currentPagination = get().paginationRepositories;
    const pageSizeChanged = pageSize !== currentPagination.pageSize;
    if (pageSizeChanged) {
      page = 1;
    }
    if (page !== currentPagination.page || pageSizeChanged) {
      set({ paginationRepositories: { page, pageSize } });
      get().listRepositoriesSuitable(get().repositoryTypeCuid);
    }
  },
  setFilterRepositories: (filterRepositories: SearchRepositoriesType) => {
    set({
      filterRepositories,
      paginationRepositories: { ...get().paginationRepositories, page: 1 },
      selectedRows: [],
    });
    get().listRepositoriesSuitable(get().repositoryTypeCuid);
  },
  resetFilterRepositories: () => {
    set({ filterRepositories: { ...defaultState.filterRepositories } });
    get().listRepositoriesSuitable(get().repositoryTypeCuid);
  },

  simpleListCustomer: async (): Promise<void> => {
    set({ loadingCustomer: true });
    await new ClientHttp().getItensForSelect<Success<SimpleListCustomerType>, StandardError, void>(
      "/api/v1/system/customers/simple-list",
      (result: Success<SimpleListCustomerType>): void => {
        set({ listCustomer: result.body.data });
      },
      (error: StandardError): void => {
        showMessage(error);
      }
    );
    set({ loadingCustomer: false });
  },
  simpleListDepartmentByCustomerCuid: async (customerCuid: string): Promise<void> => {
    set({ loadingDepartments: true });
    await new ClientHttp().getItensForSelect<Success<SimpleListDepartmentType>, StandardError, void>(
      `/api/v1/system/customers/${customerCuid}/departments/simple-list`,
      (result: Success<SimpleListDepartmentType>): void => {
        set({ listDepartments: result.body.data });
      },
      (error: StandardError): void => {
        showMessage(error);
      }
    );
    set({ loadingDepartments: false });
  },
}));
