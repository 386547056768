import useAuthStore from "@provider/AuthProvider";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import { Drawer } from "antd";
import { useContext, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useD3SelectEntityStore } from "../Layout/D3HeaderBar/components/store";

const D3Drawer = () => {
  const { isOpen, content, onClose, placement, size, title, withOutBorder } = useContext(D3DrawerContext);
  const { pathname } = useLocation();
  const customerName = useD3SelectEntityStore.getState().getCustomerName();
  const showSelectedCustomer = useMemo(() => {
    const userInfo = useAuthStore.getState().getUserInfo();
    const isPathValid = ["/workspace/", "/customer/"].some((path) => pathname.includes(path));
    return isPathValid && userInfo?.Type !== "CTM" && customerName !== undefined && customerName.length > 0;
  }, [pathname, customerName]);

  const titleRender: JSX.Element = (
    <div className="flex items-center justify-between select-none">
      <div className="flex flex-col items-start justify-center">
        <span>{title}</span>
        {showSelectedCustomer && <span className="text-xs opacity-65">Cliente: {customerName}</span>}
      </div>
    </div>
  );

  return (
    <Drawer
      className={`${withOutBorder ? "d3-pdf-view" : ""} `}
      title={titleRender}
      maskClosable={false}
      placement={placement}
      width={size}
      onClose={onClose}
      open={isOpen}
    >
      {content}
    </Drawer>
  );
};

export default D3Drawer;
