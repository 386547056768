import D3Can from "@components/shared/D3Can";
import { Icon } from "@iconify/react";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import D3Copy from "@utils/D3Copy";
import D3DateHourSorter from "@utils/D3DateHourSorter";
import D3RepositorySituation from "@utils/D3RepositorySituation";
import { Button, Pagination, Popover, Space, Tooltip } from "antd";
import Table from "antd/es/table";
import { useContext, useEffect, useState } from "react";
import { CreateRepositoryForm } from "./components/CreateRepositoryForm";
import { SearchRepositoryForm } from "./components/SearchRepositoryForm";
import { UpdateRepositoryForm } from "./components/UpdateRepositoryForm";
import { ViewRepositoryForm } from "./components/ViewRepositoryForm";
import { useRepositoriesStore } from "./store";
import { RowRepositoryType } from "./types";
import D3PopConfirmation from "@components/shared/D3PopConfirmation";

const RepositoriesView: React.FC = () => {
  const { openDrawer } = useContext(D3DrawerContext);
  const {
    apiResult,
    pagination,
    loading,
    setPagination,
    setSort,
    loadRepositories,
    resetFilter,
    resetState,
    deletionRepository,
  } = useRepositoriesStore();

  const [openSearch, setOpenSearch] = useState(false);

  useEffect(() => {
    (async () => {
      await loadRepositories();
    })();
    return () => {
      resetState();
    };
  }, [loadRepositories]);

  return (
    <div className="flex flex-col h-full mb-1 mr-1 overflow-auto bg-white rounded shadow-md">
      <Table<RowRepositoryType>
        size="small"
        rowKey="cuid"
        dataSource={apiResult.data as RowRepositoryType[]}
        bordered
        className="responsive-table"
        pagination={false}
        loading={loading}
        title={() => {
          return (
            <>
              <div className="flex items-center justify-between w-full">
                <div className="flex items-center justify-start">
                  <Popover
                    open={openSearch}
                    placement="rightTop"
                    title="Pesquisar Repositórios"
                    content={
                      <SearchRepositoryForm
                        onCallBackFilter={() => setOpenSearch(false)}
                        onCallBackResetFilter={() => {
                          setOpenSearch(false);
                          resetFilter();
                        }}
                      />
                    }
                    trigger="click"
                    onOpenChange={(open) => setOpenSearch(open)}
                  >
                    <Button
                      onClick={() => setOpenSearch(!openSearch)}
                      type="primary"
                      size="middle"
                      icon={<Icon icon="fluent:search-12-filled" height={16} width={16} />}
                    >
                      Pesquisar
                    </Button>
                  </Popover>
                </div>
                <div className="flex items-center justify-center">
                  <span className="text-xl font-semibold text-gray-600">Repositórios</span>
                </div>
                <div className="flex items-center justify-end">
                  <D3Can action="create" resource="customer_repository">
                    <Button
                      type="primary"
                      size="middle"
                      icon={<Icon icon="ic:round-plus" height={20} width={20} />}
                      onClick={() => openDrawer(<CreateRepositoryForm />, "right", 600, "Cadastrar Repositório")}
                    >
                      Novo Repositório
                    </Button>
                  </D3Can>
                </div>
              </div>
            </>
          );
        }}
        onChange={(a, b, sorter: any) => {
          if (sorter) {
            setSort({
              sort_by: sorter.field,
              sort_direction: sorter.order,
            });
          }
        }}
        footer={() => {
          return (
            <>
              <div></div>
              <Space>
                <Pagination
                  showLessItems
                  showTotal={(total, range) =>
                    `Exibindo de ${range[0]} a ${range[1]} do total ${total} ${total > 1 ? "registros" : "registro"}`
                  }
                  showSizeChanger={true}
                  current={pagination.page}
                  onChange={setPagination}
                  pageSize={pagination.pageSize}
                  total={apiResult.total}
                />
              </Space>
            </>
          );
        }}
      >
        <Table.Column<RowRepositoryType>
          showSorterTooltip={false}
          key="repository_physical_tag_id"
          title="Código"
          dataIndex="repository_physical_tag_id"
          render={(value) => {
            return (
              <div className="flex items-center gap-2">
                {value}
                <Tooltip trigger="hover" title="Copiar código do repositório.">
                  <Button
                    className="transition-all duration-300 ease-in-out hover:bg-gray-200"
                    type="link"
                    shape="default"
                    icon={<Icon className="text-blue-500" icon="fluent:copy-32-filled" />}
                    size="small"
                    onClick={() => D3Copy({ text: value, success: "Código copiado! " })}
                  />
                </Tooltip>
              </div>
            );
          }}
          sorter
        />

        <Table.Column<RowRepositoryType>
          showSorterTooltip={false}
          key="status"
          title="Situação"
          dataIndex="status"
          render={(value) => D3RepositorySituation(value)}
          sorter
        />

        <Table.Column<RowRepositoryType>
          showSorterTooltip={false}
          key="position_id"
          title="ID da posição"
          dataIndex="position_id"
          render={(value) => (value !== null ? value : "Não armazenada")}
          sorter
        />
        <Table.Column<RowRepositoryType>
          showSorterTooltip={false}
          key="billable_department"
          title="Departamento"
          dataIndex="billable_department"
        />
        <Table.Column<RowRepositoryType>
          showSorterTooltip={false}
          key="global_visibility"
          title="Visibilidade Global"
          dataIndex="global_visibility"
          render={(value) => (value ? "Sim" : "Não")}
        />

        <Table.Column<RowRepositoryType>
          showSorterTooltip={false}
          key="repository_type"
          title="Tipo de repositório"
          dataIndex="repository_type"
        />

        <Table.Column<RowRepositoryType>
          showSorterTooltip={false}
          key="number_of_documents"
          title="Qtd. de documentos"
          dataIndex="number_of_documents"
        />

        <Table.Column<RowRepositoryType>
          showSorterTooltip={false}
          key="created_by"
          title="Criado por"
          dataIndex="created_by"
        />
        <Table.Column<RowRepositoryType>
          showSorterTooltip={false}
          key="created_at"
          title="Criado em"
          dataIndex="created_at"
          render={(value, repository) => D3DateHourSorter(repository.created_at)}
          sorter
        />
        <Table.Column<RowRepositoryType>
          key="action"
          title="Ações"
          width={100}
          render={(value, repository, index) => {
            return (
              <>
                <Space>
                  <D3Can action="update" resource="customer_repository">
                    <Tooltip title="Editar" destroyTooltipOnHide mouseLeaveDelay={0} zIndex={11}>
                      <Button
                        type="text"
                        shape="default"
                        icon={
                          <Icon
                            icon="iconamoon:edit-fill"
                            height={18}
                            width={18}
                            className="text-blue-400 transition-all duration-100 ease-in hover:text-blue-500"
                          />
                        }
                        size={"middle"}
                        onClick={() =>
                          openDrawer(
                            <UpdateRepositoryForm cuid={repository.cuid} />,
                            "right",
                            600,
                            "Editar Repositório"
                          )
                        }
                      />
                    </Tooltip>
                  </D3Can>
                  <D3Can action="view" resource="customer_repository">
                    <Tooltip title="Visualizar" destroyTooltipOnHide mouseLeaveDelay={0} zIndex={11}>
                      <Button
                        className="peer"
                        type="text"
                        shape="default"
                        icon={
                          <Icon
                            icon="carbon:document-view"
                            height={18}
                            width={18}
                            className="text-blue-400 transition-all duration-100 ease-in hover:text-blue-500"
                          />
                        }
                        size={"middle"}
                        onClick={() =>
                          openDrawer(
                            <ViewRepositoryForm cuid={repository.cuid} />,
                            "right",
                            700,
                            "Visualizar Repositório"
                          )
                        }
                      />
                    </Tooltip>
                  </D3Can>

                  <D3Can action="cancel" resource="customer_repository" type="hidden">
                    <Tooltip
                      title={`${
                        repository.number_of_documents > 0
                          ? "Impossível excluir, repositório possui documentos"
                          : "Excluir repositório"
                      }`}
                      trigger="hover"
                      destroyTooltipOnHide
                      mouseLeaveDelay={0}
                      zIndex={11}
                    >
                      <Button
                        disabled={repository.number_of_documents > 0}
                        className="flex items-center justify-center"
                        type="text"
                        shape="default"
                        onClick={() =>
                          D3PopConfirmation({
                            confirmText: "Confirmar Cancelamento",
                            input: "textarea",
                            text: "Informe o motivo do cancelamento.",
                            icon: "warning",
                            minLength: 10,
                            preConfirm: async (reason: string) => {
                              await deletionRepository(repository.cuid, { deletion_reason: reason });
                            },
                          })
                        }
                        icon={
                          <Icon
                            icon="material-symbols:delete"
                            height={20}
                            width={20}
                            className="text-red-400 transition-all duration-100 ease-in hover:text-red-500"
                          />
                        }
                        size={"middle"}
                      />
                    </Tooltip>
                  </D3Can>
                </Space>
              </>
            );
          }}
        />
      </Table>
    </div>
  );
};

export default RepositoriesView;
