import React, { createContext, useContext, ReactNode } from "react";
import { UploadFile } from "antd/es/upload/interface";
import { UploadChangeParam } from "antd/lib/upload";
import { CustomUploadFile } from "./types";
import { useUploadContextStore } from "./store";

interface UploadContextProps {
  fileList: CustomUploadFile[];
  uploading: boolean;
  loadingFiles: boolean;
  handleChange: ((info: UploadChangeParam<UploadFile<any>>) => void) | undefined;
  addFiles: (files: CustomUploadFile[]) => void;
  deleteFile: (uid: string) => void;
  renameFile: (uid: string, fileName: string) => void;
  updateFileProgress: (uid: string, progress: number) => void;
  handleUpload: () => Promise<void>;
  resetFileList: () => void;
}

const UploadContext = createContext<UploadContextProps | undefined>(undefined);

export const UploadProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const {
    fileList,
    loadingFiles,
    uploading,
    addFiles,
    deleteFile,
    renameFile,
    updateFileProgress,
    handleChange,
    handleUpload,
    resetFileList,
  } = useUploadContextStore();

  return (
    <UploadContext.Provider
      value={{
        fileList,
        uploading,
        addFiles,
        updateFileProgress,
        handleUpload,
        deleteFile,
        renameFile,
        resetFileList,
        loadingFiles,
        handleChange,
      }}
    >
      {children}
    </UploadContext.Provider>
  );
};

export const useUploadContext = () => {
  const context = useContext(UploadContext);
  if (!context) {
    throw new Error("useUploadContext must be used within an UploadProvider");
  }
  return context;
};
