import { InfoCircleOutlined } from "@ant-design/icons";
import D3Can from "@components/shared/D3Can";
import { Icon } from "@iconify/react";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import { Button, Form, Select, Space, Spin, Tooltip } from "antd";
import { useContext, useEffect, useState } from "react";
import { useRelocationStore } from "../store";
import { ServeRelocationType } from "../types";

type ServeDeploymentOrderFormProps = {
  orderCuid: string;
};

function ServeDeploymentOrderForm({ orderCuid }: ServeDeploymentOrderFormProps) {
  const {
    serveRelocation,
    simpleListStorage,
    loadingStorages,
    listStorages,
    simpleListShelves,
    listShelves,
    loadingShelves,
    simpleListCells,
    listCells,
    loadingCells,
    loadingServe,
  } = useRelocationStore();
  const [form] = Form.useForm<ServeRelocationType>();
  const { onClose } = useContext(D3DrawerContext);
  const [isMigration, setIsMigration] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      await simpleListStorage();
    })();
  }, [simpleListStorage]);

  return (
    <Spin spinning={loadingStorages}>
      <Form
        className="w-full h-full select-none"
        form={form}
        layout="vertical"
        onFinish={async (payload: ServeRelocationType) => {
          const isOk = await serveRelocation(orderCuid, payload);
          if (isOk) {
            onClose();
          }
        }}
        onValuesChange={async (changedValues: any, values: ServeRelocationType) => {
          if (changedValues.action_type) {
            setIsMigration(changedValues.action_type === "D" ? false : true);
          }
          if (changedValues.migrate_parameters?.storage_cuid) {
            await simpleListShelves(changedValues.migrate_parameters.storage_cuid);
          }
          if (changedValues.migrate_parameters?.shelf_cuid) {
            await simpleListCells(
              values.migrate_parameters.storage_cuid,
              changedValues.migrate_parameters.shelf_cuid,
              orderCuid
            );
          }
        }}
      >
        <Form.Item<ServeRelocationType>
          name="action_type"
          label="Tipo de ação"
          rules={[{ required: true, message: "Por favor, selecione uma ação." }]}
        >
          <Select
            options={[
              { value: "M", label: "Migração" },
              { value: "D", label: "Desalocação" },
            ]}
          />
        </Form.Item>

        {isMigration && (
          <>
            <Form.Item<ServeRelocationType>
              name={["migrate_parameters", "storage_cuid"]}
              label="Galpão"
              rules={[{ required: true, message: "Por favor, selecione o galpão." }]}
            >
              <Select
                loading={loadingStorages}
                disabled={listStorages.length === 0 || loadingStorages}
                listItemHeight={10}
                allowClear
                removeIcon={
                  <Icon
                    icon="iconamoon:close-duotone"
                    className="w-4 h-4 text-red-400 transition-all duration-300 ease-in-out hover:text-red-600"
                  />
                }
                menuItemSelectedIcon={<Icon icon="eva:checkmark-outline" className="w-5 h-5 text-blue-500" />}
                optionLabelProp="dataLabel"
                showSearch
                maxTagCount="responsive"
                size="middle"
                className="w-full truncate select-none"
                optionFilterProp="children"
                onClear={() => {
                  form.setFieldValue(["migrate_parameters", "shelf_cuid"], null);
                  form.setFieldValue(["migrate_parameters", "cell_cuid"], null);
                }}
                filterOption={(input, option) => option?.dataFilter.toLowerCase().includes(input.toLowerCase())}
              >
                {listStorages.map(({ active, cuid, description, id, key_document_storage }) => (
                  <Select.Option
                    key={cuid}
                    value={cuid}
                    dataFilter={`${key_document_storage} - ${description}`}
                    dataLabel={`${key_document_storage} - ${description}`}
                  >
                    <div className="flex items-center justify-between ">
                      <div className="flex flex-col truncate">
                        <span className="truncate">{`${key_document_storage} - ${description}`}</span>
                      </div>
                      {active ? (
                        <Tooltip title="Galpão desbloqueado" placement="right">
                          <span>
                            <Icon icon="basil:unlock-solid" className="text-green-500" height={16} width={16} />
                          </span>
                        </Tooltip>
                      ) : (
                        <Tooltip title="Galpão bloqueado" placement="right">
                          <span>
                            <Icon icon="basil:lock-solid" className="text-red-500" height={16} width={16} />
                          </span>
                        </Tooltip>
                      )}
                    </div>
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item<ServeRelocationType> name={["migrate_parameters", "shelf_cuid"]} label="Estante">
              <Select
                disabled={listShelves.length === 0 || loadingShelves}
                loading={loadingShelves}
                listItemHeight={10}
                allowClear
                removeIcon={
                  <Icon
                    icon="iconamoon:close-duotone"
                    className="w-4 h-4 text-red-400 transition-all duration-300 ease-in-out hover:text-red-600"
                  />
                }
                menuItemSelectedIcon={<Icon icon="eva:checkmark-outline" className="w-5 h-5 text-blue-500" />}
                optionLabelProp="dataLabel"
                showSearch
                maxTagCount="responsive"
                size="middle"
                className="w-full truncate select-none"
                optionFilterProp="children"
                onClear={() => {
                  form.setFieldValue(["migrate_parameters", "cell_cuid"], null);
                }}
                filterOption={(input, option) => option?.dataFilter.toLowerCase().includes(input.toLowerCase())}
              >
                {listShelves.map(({ abbreviation, active, cuid, storage_id }) => (
                  <Select.Option
                    key={cuid}
                    value={cuid}
                    dataFilter={`${abbreviation} (${storage_id})`}
                    dataLabel={`${abbreviation} (${storage_id})`}
                  >
                    <div className="flex items-center justify-between ">
                      <div className="flex flex-col truncate">
                        <span className="truncate">{`${abbreviation} (${storage_id})`}</span>
                      </div>
                      {active ? (
                        <Tooltip title="Estante desbloqueada" placement="right">
                          <span>
                            <Icon icon="basil:unlock-solid" className="text-green-500" height={16} width={16} />
                          </span>
                        </Tooltip>
                      ) : (
                        <Tooltip title="Estante bloqueada" placement="right">
                          <span>
                            <Icon icon="basil:lock-solid" className="text-red-500" height={16} width={16} />
                          </span>
                        </Tooltip>
                      )}
                    </div>
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item<ServeRelocationType> name={["migrate_parameters", "cell_cuid"]} label="Vão/Prateleira">
              <Select
                disabled={listCells.length === 0 || loadingCells}
                loading={loadingCells}
                listItemHeight={10}
                allowClear
                removeIcon={
                  <Icon
                    icon="iconamoon:close-duotone"
                    className="w-4 h-4 text-red-400 transition-all duration-300 ease-in-out hover:text-red-600"
                  />
                }
                menuItemSelectedIcon={<Icon icon="eva:checkmark-outline" className="w-5 h-5 text-blue-500" />}
                optionLabelProp="dataLabel"
                showSearch
                maxTagCount="responsive"
                size="middle"
                className="w-full truncate select-none"
                optionFilterProp="children"
                /*   onClear={() => {
                  form.setFieldValue("cell_cuid", null);
                }} */
                filterOption={(input, option) => option?.dataFilter.toLowerCase().includes(input.toLowerCase())}
              >
                {listCells.map(
                  ({ active, column, cuid, row, id, number_of_empty_positions, repository_type_description }) => (
                    <Select.Option
                      key={cuid}
                      value={cuid}
                      dataFilter={`${id} - ${repository_type_description} - Vão: ${column} - Prat: ${row} - Vagas: ${number_of_empty_positions}`}
                      dataLabel={`${id} - ${repository_type_description} - Vão: ${column} - Prat: ${row} - Vagas: ${number_of_empty_positions}`}
                    >
                      <div className="flex items-center justify-between ">
                        <div className="flex flex-col truncate">
                          <span className="truncate">{`${id} - ${repository_type_description} - Vão: ${column} - Prat: ${row} - Vagas: ${number_of_empty_positions}`}</span>
                        </div>

                        {active ? (
                          <Tooltip title="Vão/Prateleira desbloqueada" placement="right">
                            <span>
                              <Icon icon="basil:unlock-solid" className="text-green-500" height={16} width={16} />
                            </span>
                          </Tooltip>
                        ) : (
                          <Tooltip title="Vão/Prateleira bloqueada" placement="right">
                            <span>
                              <Icon icon="basil:lock-solid" className="text-red-500" height={16} width={16} />
                            </span>
                          </Tooltip>
                        )}
                      </div>
                    </Select.Option>
                  )
                )}
              </Select>
            </Form.Item>
            <Form.Item<ServeRelocationType>
              name={["migrate_parameters", "increase_option"]}
              label="Incrementar"
              tooltip={{
                title:
                  "Se não houver espaço o suficiente no vão/prateleira selecionado serão utilizados os vãos/prateleiras seguintes incrementando",
                icon: <InfoCircleOutlined />,
              }}
            >
              <Select
                disabled={listStorages.length === 0 || loadingStorages}
                listItemHeight={10}
                allowClear
                removeIcon={
                  <Icon
                    icon="iconamoon:close-duotone"
                    className="w-4 h-4 text-red-400 transition-all duration-300 ease-in-out hover:text-red-600"
                  />
                }
                menuItemSelectedIcon={<Icon icon="eva:checkmark-outline" className="w-5 h-5 text-blue-500" />}
                optionLabelProp="dataLabel"
                showSearch
                maxTagCount="responsive"
                size="middle"
                className="w-full truncate select-none"
                optionFilterProp="children"
                /*   onClear={() => {
                  form.setFieldValue("increase_option", null);
                }} */
              >
                {[
                  { label: "Coluna (Vão)", value: "C" },
                  { label: "Linha (Prateleira)", value: "R" },
                ].map(({ label, value }) => (
                  <Select.Option key={value} value={value} dataFilter={label} dataLabel={label}>
                    <div className="flex items-center justify-start ">
                      <div className="flex flex-col truncate">
                        <span className="truncate">{label}</span>
                      </div>
                    </div>
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </>
        )}

        <Form.Item>
          <div className="text-right">
            <Space size="small">
              <Button type="default" danger onClick={() => onClose()}>
                Cancelar
              </Button>
              <D3Can action="create" resource="order_relocation">
                <Button loading={loadingServe} type="primary" htmlType="submit">
                  Baixar
                </Button>
              </D3Can>
            </Space>
          </div>
        </Form.Item>
      </Form>
    </Spin>
  );
}

export default ServeDeploymentOrderForm;
