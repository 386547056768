import { Form, Button, Spin, Input, Radio } from "antd";
import { useCompaniesStore } from "../store";
import { useEffect, useState } from "react";
import { UpdateSystemUserType, ViewSystemUserType } from "../types";
import showMessage from "@utils/showMessage";
import { D3Date } from "@utils/date";
import ProfilePicture from "@components/shared/ProfilePicture/ProfilePicture";

function TabPersonalInformation() {
  const [form] = Form.useForm<ViewSystemUserType>();

  const [isEditing, setIsEditing] = useState(false);

  const { updateSystemUser } = useCompaniesStore();

  const { getSystemUser } = useCompaniesStore();

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      const result = await getSystemUser();
      if (result.status === "success") {
        const viewUser = result.body as ViewSystemUserType;
        form.setFieldsValue(
          Object.assign(viewUser, {
            created_at: D3Date.FormatValueApiToPtBR(viewUser.created_at),
            updated_at: D3Date.FormatValueApiToPtBR(viewUser.updated_at),
          })
        );
      } else {
        showMessage(result);
      }

      setLoading(false);
    })();
  }, [form, getSystemUser]);

  return (
    <>
      <Spin spinning={loading}>
        <div className="flex items-center py-2 ml-12 w-[600px]">
          <ProfilePicture />
        </div>
        <Form
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 14 }}
          className="select-none w-[600px]"
          form={form}
          layout="horizontal"
          onFinish={async (payload: UpdateSystemUserType) => {
            setLoading(true);
            const result = await updateSystemUser(payload);
            if (result.status === "success") {
              showMessage(result, "Usuário atualizado com sucesso.");
              setIsEditing(false);
            } else {
              showMessage(result);
            }
            setLoading(false);
          }}
          autoComplete="off"
        >
          <Form.Item<ViewSystemUserType>
            label="Usuário"
            name="username"
            rules={[
              {
                required: true,
                message: "Por favor, insira o usuário.",
              },
              {
                max: 30,
                message: "O usuário deve ter menos de 30 caracteres.",
              },
              {
                validator: (_, value) =>
                  /\s/.test(value)
                    ? Promise.reject(new Error("O usuário não pode conter espaços."))
                    : Promise.resolve(),
              },
            ]}
          >
            <Input className="w-64" disabled={!isEditing} variant={isEditing ? "outlined" : "borderless"} />
          </Form.Item>
          <Form.Item<ViewSystemUserType>
            label="Nome"
            name="name"
            rules={[
              {
                required: true,
                message: "Por favor, insira o nome.",
              },
            ]}
          >
            <Input className="w-64" disabled={!isEditing} variant={isEditing ? "outlined" : "borderless"} />
          </Form.Item>
          <Form.Item<ViewSystemUserType>
            label="E-mail"
            name="email"
            rules={[
              {
                required: true,
                message: "Por favor, insira o e-mail.",
              },
              {
                type: "email",
                message: "Por favor, insira um e-mail válido.",
              },
            ]}
          >
            <Input className="w-64" disabled={!isEditing} variant={isEditing ? "outlined" : "borderless"} />
          </Form.Item>
          <Form.Item<ViewSystemUserType> label="Tipo" name="type">
            <Input className="w-64" disabled={true} variant="borderless" />
          </Form.Item>
          <Form.Item<ViewSystemUserType> label="Admin" name="admin">
            <Radio.Group
              disabled
              options={[
                { label: "Sim", value: true },
                { label: "Não", value: false },
              ]}
            />
          </Form.Item>

          <Form.Item<ViewSystemUserType> name="created_at" label="Criado em">
            <Input disabled={true} variant="borderless" />
          </Form.Item>
          <Form.Item<ViewSystemUserType> name="updated_at" label="Atualizado em">
            <Input disabled={true} variant="borderless" />
          </Form.Item>

          <div className="flex items-center justify-end w-[400px]">
            <Form.Item className={isEditing ? `` : `hidden`}>
              <Button type="primary" htmlType="submit">
                atualizar
              </Button>
            </Form.Item>
            <Form.Item className={isEditing ? `hidden` : ``}>
              <Button type="primary" onClick={() => setIsEditing(true)}>
                Editar
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Spin>
    </>
  );
}

export default TabPersonalInformation;
