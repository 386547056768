import { create } from "zustand";
import { CustomUploadFile, ResponseGetUrls } from "./types";
import { extension, mineType } from "@utils/mine";
import axios from "axios";
import { RcFile, UploadFile } from "antd/es/upload";
import { UploadChangeParam } from "antd/lib/upload";
import { BadRequest, ClientHttp, StandardError, Success } from "@utils/clientHttp";
import SweetAlert from "@components/shared/SweetAlert";

type UseUploadContextType = {
  fileList: CustomUploadFile[];
  addFiles: (files: CustomUploadFile[]) => void;
  deleteFile: (uid: string) => void;
  handleUpload: () => Promise<void>;
  renameFile: (uid: string, fileName: string) => void;
  updateFileProgress: (uid: string, progress: number) => void;
  uploadFileWithProgress: (url: string, file: CustomUploadFile) => Promise<void>;
  handleChange: ((info: UploadChangeParam<UploadFile<any>>) => void) | undefined;
  uploading: boolean;
  loadingFiles: boolean;
  resetFileList: () => void;
};

const defaultState = {
  fileList: [],

  uploading: false,

  loadingFiles: false,
};

export const useUploadContextStore = create<UseUploadContextType>((set, get) => ({
  ...defaultState,
  resetFileList: () => {
    set({
      fileList: [],
    });
  },
  addFiles: (files: CustomUploadFile[]) => {
    set({
      fileList: files,
    });
  },
  deleteFile: (uid: string) => {
    set((state) => ({
      fileList: state.fileList.filter((file) => file.uid !== uid),
    }));
  },
  renameFile: (uid: string, fileName: string) => {
    set((state) => ({
      fileList: state.fileList.map((file) => {
        if (file.uid === uid) {
          return {
            ...file,
            fileName: fileName + extension(file.type ?? ""),
            name: fileName + extension(file.type ?? ""),
          };
        } else {
          return file;
        }
      }),
    }));
  },

  updateFileProgress: async (uid: string, progress: number) => {
    set((state) => ({
      fileList: state.fileList.map((file) => (file.uid === uid ? { ...file, progress } : file)),
    }));
  },
  uploadFileWithProgress: async (url: string, file: CustomUploadFile) => {
    try {
      await axios.put(url, file.originFileObj as RcFile, {
        onUploadProgress: (progressEvent) => {
          if (progressEvent.total) {
            const percentComplete = Math.round((progressEvent.loaded / progressEvent.total) * 100);
            get().updateFileProgress(file.uid, percentComplete); // Atualiza o progresso de cada arquivo
          }
        },
      });
    } catch (error) {
      throw error;
    }
  },
  handleChange: (info: UploadChangeParam<UploadFile<any>>) => {
    set({ loadingFiles: true });
    let updatedFileList: CustomUploadFile[] = [...info.fileList];
    updatedFileList = updatedFileList.map((file) => {
      if (updatedFileList.filter((f) => f.name === file.name).length > 1) {
        file.duplicate = true;
      } else {
        file.duplicate = false;
      }
      return file as CustomUploadFile;
    });
    setTimeout(() => {
      set({
        fileList: updatedFileList,
        loadingFiles: false,
      });
    }, 1000);
  },
  handleUpload: async () => {
    set({ uploading: true });
    const payload = {
      billable_department_cuid: "cm16qt3yl00ambyw5pbovjp5c",
      files: get().fileList.map((file) => {
        return {
          extension: mineType(file.type!), // Supondo que mineType está importado corretamente
          name: file.name,
          file: {
            uid: file.uid,
          },
          size: file.size,
          uid: file.uid,
        };
      }),
      global_visibility: false,
      parent_cuid: "cm16pe0290003eakzjoa5o83l",
    };
    try {
      await new ClientHttp().post<any, Success<ResponseGetUrls>, BadRequest | StandardError>(
        "http://localhost:8080/api/v1/customer/fs/file",
        JSON.stringify(payload),
        async (result: Success<ResponseGetUrls>) => {
          const uploadPromises = get().fileList.map((file) => {
            const url = result.body.data.find((resp) => resp.uid === file.uid)?.url;
            if (url) {
              return get().uploadFileWithProgress(url, file);
            }
            return Promise.reject(`URL not found for file with UID: ${file.uid}`);
          });

          await Promise.all(uploadPromises);
          SweetAlert({ title: "Upload finalizado com sucesso!", type: "success" });
        }
      );
    } catch (error) {
      SweetAlert({ title: "Erro no upload.", type: "error" });
    } finally {
      set({ uploading: false });
    }
  },
}));
