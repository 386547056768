import SweetAlert from "@components/shared/SweetAlert";
import { BadRequest, StandardError, Success } from "./clientHttp";

type PayloadType = BadRequest | StandardError | Success<any>;

const showMessage = (payload: PayloadType, standardMessage?: string, duration: number = 3) => {
  duration = duration * 1000;
  if ((payload.code === 200 || payload.code === 201) && payload.status === "success") {
    SweetAlert({ title: standardMessage?? "Operação realizada com sucesso" });
  } else if (payload.status === "warning") {
    SweetAlert({ title: standardMessage ?? "Operação realizada com sucesso", type: "warning" });
  } else if (payload.status === "error") {
    let description = "";
    if (
      payload.code === 400 ||
      payload.code === 401 ||
      payload.code === 403 ||
      payload.code === 404 ||
      payload.code === 409 ||
      payload.code === 410 ||
      payload.code === 422 ||
      payload.code === 423 ||
      payload.code === 500
    ) {
      let resultError = payload as StandardError;
      description =
        standardMessage ?? resultError?.body?.message ?? "Ocorreu um erro desconhecido ao realizar a operação";
    } else if (payload.code === 400) {
      let resultError: BadRequest = payload as BadRequest;
      description = resultError?.body?.data
        ? JSON.stringify(resultError.body.data)
        : "Ocorreu um erro desconhecido ao realizar a operação";
    }
    if (description !== "") {
      SweetAlert({ title: description, type: "error" });
    }
  }
};

export default showMessage;
