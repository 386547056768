import Selector from "@components/shared/Selector";
import { Icon } from "@iconify/react";
import { useReportsStore } from "@views/reports/store";
import { RegisterReportType, UpdateReportParamType } from "@views/reports/types";
import { Button, Checkbox, Form, Input, List, Segmented, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";

export enum ReportParamType {
  C = "Carácter",
  I = "Inteiro",
  F = "Decimal",
  D = "Data",
  B = "Condicional",
}

const listParams = Object.entries(ReportParamType).map(([value, label]) => ({
  value,
  label,
}));

function TabParams() {
  const { payloadUpdate } = useReportsStore();
  const [form] = Form.useForm<UpdateReportParamType>();
  const [edit, setEdit] = useState<UpdateReportParamType>({} as UpdateReportParamType);

  useEffect(() => {
    if (edit.name) {
      form.setFieldsValue(edit);
    }
    return () => form.resetFields();
  }, [edit]);

  const type = Form.useWatch("type", form);

  const onDragEnd = (result: DropResult, payloadUpdate: RegisterReportType, setState: any) => {
    const { destination, source } = result;
    if (!destination || destination.index === source.index) {
      return;
    }

    const reorderedItems = Array.from(payloadUpdate.report_params);
    const [movedItem] = reorderedItems.splice(source.index, 1);
    reorderedItems.splice(destination.index, 0, movedItem);

    const updatedItems = reorderedItems.map((item, index) => ({
      ...item,
      order: index + 1,
    }));

    setState((state: any) => ({
      payloadUpdate: {
        ...state.payloadUpdate,
        report_params: updatedItems,
      },
    }));
  };

  return (
    <div className="flex flex-col h-full overflow-hidden ">
      <Form
        key="params"
        form={form}
        className="w-full "
        layout="vertical"
        onFinish={(values: UpdateReportParamType) => {
          if (edit.name) {
            useReportsStore.setState((state) => ({
              payloadUpdate: {
                ...state.payloadUpdate,
                report_params: state.payloadUpdate.report_params.map((item) =>
                  item.name === edit.name ? { ...values } : item
                ),
              },
            }));
            form.resetFields();
            setEdit({} as UpdateReportParamType);
          } else {
            const newParam = {
              ...values,
              order: payloadUpdate.report_params.length + 1,
            };
            useReportsStore.setState((state) => ({
              payloadUpdate: {
                ...state.payloadUpdate,
                report_params: [...state.payloadUpdate.report_params, newParam],
              },
            }));
            form.resetFields();
          }
        }}
      >
        <div className="grid grid-cols-2 gap-5">
          <Form.Item hidden name="id">
            <Input />
          </Form.Item>
          <Form.Item hidden name="order">
            <Input />
          </Form.Item>
          <Form.Item
            name="label"
            label="Descrição"
            rules={[
              {
                required: true,
                message: "Por favor, preencha a descrição.",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="name"
            label="Nome"
            rules={[
              {
                required: true,
                message: "Por favor, preencha o nome.",
              },
              {
                pattern: /^@[A-Z_]+@$/,
                message: "O nome deve conter apenas letras e underline.",
              },
              {
                validator: (_, value) => {
                  if (value && value.includes("ENV_")) {
                    const cleanValue = value.replace(/^@|@$/g, "");
                    if (cleanValue.startsWith("ENV_")) {
                      return Promise.reject(new Error("Nome não pode iniciar com ENV_."));
                    }
                  }
                  return Promise.resolve();
                },
              },
              {
                validator: (_, value) => {
                  if (Array.isArray(payloadUpdate.report_params)) {
                    const existingName = payloadUpdate.report_params.find(
                      (item) => item.name.toLowerCase() === value.toLowerCase()
                    );
                    if (existingName && existingName.name !== edit.name) {
                      return Promise.reject(new Error(`O nome '${value.replace(/^@|@$/g, "")}' já está em uso.`));
                    } else {
                      return Promise.resolve();
                    }
                  } else {
                    return Promise.resolve();
                  }
                },
              },
            ]}
            getValueProps={(value) => {
              let valueStr = "";
              if (value) {
                valueStr = typeof value === "string" ? value : value.value || "";
                valueStr = valueStr.replace(/^@|@$/g, "");
              }
              return { value: valueStr };
            }}
            normalize={(value) => {
              if (!value) return "";
              const valueStr = typeof value === "string" ? value : value.value || "";
              const sanitizedValue = valueStr.replace(/[^a-zA-Z_]/g, "").toUpperCase();
              return `@${sanitizedValue}@`;
            }}
          >
            <Input addonBefore="@" addonAfter="@" />
          </Form.Item>
        </div>
        <div className="grid grid-cols-2">
          <Form.Item
            name="type"
            label="Tipo"
            className="w-[416px]"
            rules={[
              {
                required: true,
                message: "Por favor, selecione o tipo.",
              },
            ]}
          >
            <Selector
              fieldMap={{
                value: "value",
                label: "label",
                dataLabel: ["label"],
                dataFilter: ["label"],
              }}
              options={listParams}
            />
          </Form.Item>
          <div className="flex gap-5 pl-3">
            <Form.Item hidden={type === "B"} name="multiple" label="Múltiplos valores?" initialValue={false}>
              <Segmented
                options={[
                  { label: "Sim", value: true },
                  { label: "Não", value: false },
                ]}
              />
            </Form.Item>
            <Form.Item name="required" label="Obrigatório?" initialValue={false}>
              <Segmented
                options={[
                  { label: "Sim", value: true },
                  { label: "Não", value: false },
                ]}
              />
            </Form.Item>
          </div>
        </div>
        <Form.Item className="w-full">
          <Button size="large" block type="primary" htmlType="button" onClick={() => form.submit()}>
            {edit.name ? "Editar Parâmetro" : "Adicionar Parâmetro"}
          </Button>
        </Form.Item>
      </Form>

      <div className="flex flex-col h-full px-3 overflow-hidden ">
        <DragDropContext onDragEnd={(result) => onDragEnd(result, payloadUpdate, useReportsStore.setState)}>
          <Droppable droppableId="reportParamsList">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <List
                  bordered
                  itemLayout="vertical"
                  size="small"
                  className="flex flex-col h-full overflow-y-auto custom-scroll"
                  header={
                    <div className="grid grid-cols-12 font-semibold divide-x">
                      <div className="flex justify-start col-span-2">Descrição</div>
                      <div className="flex justify-start col-span-2 pl-2">Nome</div>
                      <div className="flex justify-start col-span-2 pl-2">Tipo</div>
                      <div className="flex justify-center col-span-2">Múltiplo</div>
                      <div className="flex justify-center col-span-2">Obrigatório</div>
                      <div className="flex justify-center col-span-2">Ações</div>
                    </div>
                  }
                  dataSource={payloadUpdate.report_params}
                  renderItem={(item, index) => (
                    <Draggable key={item.name} draggableId={item.name} index={index}>
                      {(provided) => (
                        <List.Item
                          className="bg-white"
                          key={item.order}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                        >
                          <div className="grid items-center grid-cols-12 divide-x min-h-8">
                            <div className="flex items-center justify-start w-full col-span-2 ">
                              <Tooltip title={item.label}>
                                <span className="truncate">{item.label}</span>
                              </Tooltip>
                            </div>
                            <div className="flex items-center justify-start col-span-2 pl-2 min-h-8">
                              <Tooltip title={item.name}>
                                <span className="truncate">{item.name}</span>
                              </Tooltip>
                            </div>
                            <div className="flex items-center justify-start col-span-2 pl-2 min-h-8">
                              {ReportParamType[item.type as keyof typeof ReportParamType]}
                            </div>
                            <div className="flex items-center justify-center col-span-2 min-h-8">
                              {item.type !== "B" ? <Checkbox checked={item.multiple} /> : <></>}
                            </div>
                            <div className="flex items-center justify-center col-span-2 min-h-8">
                              <Checkbox checked={item.required} />
                            </div>
                            <div className="z-50 flex items-center justify-center col-span-2 gap-2 px-1 min-h-8">
                              <Button
                                type="text"
                                shape="default"
                                icon={
                                  <Icon
                                    icon="iconamoon:edit-fill"
                                    height={18}
                                    width={18}
                                    className="text-blue-400 transition-all duration-100 ease-in hover:text-blue-500"
                                  />
                                }
                                onClick={() => setEdit(item)}
                                size={"middle"}
                              />
                              <Button
                                type="text"
                                shape="default"
                                icon={
                                  <Icon
                                    icon="material-symbols:delete"
                                    height={18}
                                    width={18}
                                    className="text-red-400 transition-all duration-100 ease-in hover:text-red-500"
                                  />
                                }
                                onClick={() => {
                                  if (item.name === edit.name) {
                                    setEdit({} as UpdateReportParamType);
                                  }
                                  useReportsStore.setState((state) => {
                                    const updatedParams = state.payloadUpdate.report_params.filter(
                                      (param) => param.name !== item.name
                                    );
                                    const reorderedParams = updatedParams.map((param, index) => ({
                                      ...param,
                                      order: index + 1,
                                    }));
                                    return {
                                      payloadUpdate: {
                                        ...state.payloadUpdate,
                                        report_params: reorderedParams,
                                      },
                                    };
                                  });
                                }}
                                size={"middle"}
                              />
                            </div>
                          </div>
                        </List.Item>
                      )}
                    </Draggable>
                  )}
                />
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
}

export default TabParams;
