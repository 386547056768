export const enumSituation: Map<string, string> = new Map<string, string>([
	["STO", "Armazenado"],
	["REG", "Cadastrado"],
	["MIG", "Em migração"],
	["REQ", "Solicitado"],
	["PUR", "Expurgado"]
]);

function getRepositoryStatusByKey(key: string): string | undefined {
	return enumSituation.get(key);
}

function getAllRepositoryStatusForSelect(): Array<{ label: string; value: string }> {
	const options: Array<{ label: string; value: string }> = [];
	enumSituation.forEach((label, value) => {
		options.push({ label, value });
	});
	return options;
}

export { getAllRepositoryStatusForSelect, getRepositoryStatusByKey };
