import { Icon } from "@iconify/react";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import { DownloadUrl } from "@utils/DownloadUrl";
import RegisterDigitalDocument from "@views/customer/document-registration/digital";
import RegisterPhysicalDocument from "@views/customer/document-registration/physical";
import { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DropdownItemType, ItemsTypeType } from "../types";
import RenameFolderForm from "../../folder/RenameFolderForm";
import MoveToTrashForm from "../../trash/MoveToTrashForm";
import useNavigateStore from "../store";
import { DownloadFile } from "../types";
import EditFile from "../../file/edit-file/EditFile";
import ViewFile from "../../file/view-file/ViewFile";
import DetailsFile from "../../file/details-file/DetailsFile";
import { ViewStructuredDocument } from "../../structured-document/view/ViewStructuredDocument";
import EditStructuredDocumentForm from "../../structured-document/edit/EditStructuredDocumenForm";
import EditIndexersForm from "../../structured-document/edit-indexers";
import useFolderStore from "../../folder/store";
import FormUploadDrawer from "@views/customer/spaces/components/form-upload-work/FormUploadDrawer";
import SendEmail from "../../file/send-email/SendEmail";

export const ItemsType = (item: DropdownItemType) => {
  const {
    downloadFile,
    content: { can_create_folder_here, can_upload_files_here, children },
  } = useNavigateStore();
  const { openDrawer } = useContext(D3DrawerContext);
  const { cuid } = useParams<{ cuid: string }>();
  const navigate = useNavigate();

  function handleItemClick(cuid: string) {
    navigate(`/app/customer/spaces/work/${cuid}`);
  }

  const items: ItemsTypeType[] = [
    {
      type: ["FLD", "SDC", "DTF"],
      menu: "FLS",
      key: "2",
      label: "Abrir pasta",
      icon: <Icon className="text-gray-500" height={16} icon="bxs:door-open" />,
      onClick: () => handleItemClick(item.cuid),
    },
    {
      type: ["FLD"],
      menu: "FLS",
      key: "4",
      label: "Renomear pasta",
      icon: <Icon className="text-gray-500" height={16} icon="mdi:folder-edit" />,
      onClick: () =>
        openDrawer(<RenameFolderForm cuid={item.cuid} oldName={item.name} />, "right", 600, "Renomear pasta"),
    },
    {
      type: ["FLE"],
      menu: "FLS",
      key: "5",
      label: "Editar arquivo",
      icon: <Icon className="text-gray-500" height={16} icon="iconamoon:edit-fill" />,
      onClick: () => openDrawer(<EditFile fileCuid={item.cuid} />, "right", 600, "Editar arquivo"),
    },
    {
      type: ["FLE"],
      menu: "FLS",
      key: "6",
      label: "Visualizar arquivo",
      icon: <Icon className="text-gray-500" height={16} icon="bxs:door-open" />,
      onClick: () => openDrawer(<ViewFile fileCuid={item.cuid} />, "right", 1000, "Visualizar arquivo"),
    },
    {
      type: ["FLE"],
      menu: "FLS",
      key: "7",
      label: "Detalhes do arquivo",
      icon: <Icon className="text-gray-500" height={16} icon="bxs:door-open" />,
      onClick: () => openDrawer(<DetailsFile fileCuid={item.cuid} />, "right", 600, "Detalhes do arquivo"),
    },
    {
      type: ["FLE"],
      menu: "FLS",
      key: "8",
      label: "Download arquivo",
      icon: <Icon className="text-gray-500" height={18} icon="material-symbols:download" />,
      onClick: async () => {
        const { body } = await downloadFile(item.cuid);
        const { url } = body as DownloadFile;
        DownloadUrl(url);
      },
    },
    {
      type: ["SDC"],
      menu: "FLS",
      key: "9",
      label: "Visualizar documento estruturado",
      icon: <Icon className="text-gray-500" height={16} icon="bxs:door-open" />,
      onClick: () =>
        openDrawer(
          <ViewStructuredDocument fsDocumentCuid={item.cuid} />,
          "right",
          800,
          "Visualizar documento estruturado"
        ),
    },
    {
      type: ["SDC"],
      menu: "FLS",
      key: "10",
      label: "Editar documento estruturado",
      icon: <Icon className="text-gray-500" height={22} icon="ic:twotone-edit-note" />,
      onClick: () =>
        openDrawer(
          <EditStructuredDocumentForm documentCuid={item.cuid} />,
          "right",
          600,
          "Editar documento estruturado"
        ),
    },
    {
      type: ["SDC"],
      menu: "FLS",
      key: "11",
      label: "Editar indexadores",
      icon: <Icon className="text-gray-500" height={16} icon="oui:index-edit" />,
      onClick: () => openDrawer(<EditIndexersForm documentCuid={item.cuid} />, "right", 600, "Editar indexadores"),
    },
    {
      type: ["DTF"],
      menu: "HDR",
      key: "12",
      label: "Cadastrar documento físico",
      icon: <Icon className="text-gray-500" height={16} icon="bxs:box" />,
      onClick: () =>
        openDrawer(
          <RegisterPhysicalDocument dtfCuid={item.cuid} isDrawer />,
          "right",
          1000,
          "Cadastro documento físico"
        ),
    },
    {
      type: ["DTF"],
      menu: "HDR",
      key: "13",
      label: "Cadastrar documento digital",
      icon: <Icon className="text-gray-500" height={16} icon="streamline:web-solid" />,
      onClick: () =>
        openDrawer(
          <RegisterDigitalDocument dtfCuid={item.cuid} isDrawer />,
          "right",
          1000,
          "Cadastro documento digital"
        ),
    },
  ];

  if (can_create_folder_here) {
    items.push({
      type: ["FLE", "FLD", "SDC", "DTF"],
      menu: "HDR",
      key: "1",
      label: "Nova pasta",
      icon: <Icon className="text-gray-500" height={16} icon="mingcute:new-folder-fill" />,
      onClick: () => {
        useFolderStore.setState({ openModal: true });
      },
    });
  }

  if (children.find((child) => child.cuid === item.cuid)?.file_upload_completed) {
    items.push({
      type: ["FLE"],
      menu: "FLS",
      key: "9",
      label: "Enviar por email",
      icon: <Icon className="text-gray-500" height={18} icon="mdi:email-send-outline" />,
      onClick: () => openDrawer(<SendEmail fileCuid={item.cuid} />, "right", 600, "Enviar por Email"),
    });
  }

  if (can_upload_files_here) {
    items.push({
      type: ["FLE", "FLD", "SDC", "DTF"],
      menu: "HDR",
      key: "3",
      label: "Upload de arquivos",
      icon: <Icon className="text-gray-500" height={16} icon="ic:round-upload-file" />,
      onClick: () => openDrawer(<FormUploadDrawer cuid={cuid as string} />, "right", 800, "Upload de arquivos"),
    });
  }

  if (!children.find((child) => child.cuid === item.cuid)?.is_physical) {
    items.push({
      type: ["FLE", "FLD", "SDC"],
      menu: "FLS",
      key: "14",
      label: "Mover para lixeira",
      icon: <Icon className="text-gray-500" height={16} icon="ic:baseline-auto-delete" />,
      onClick: () => openDrawer(<MoveToTrashForm fsCuid={item.cuid} />, "right", 600, "Mover para lixeira"),
    });
  }

  return items.filter((i) => i.type.includes(item.type) && i.menu === item.menu);
};
