import { Icon } from "@iconify/react";
import { Tooltip } from "antd";
import { tv } from "tailwind-variants";

export type OptionsType = {
  status: string;
  label: string;
  color: string;
  icon: string;
};

export const StatusOrders: OptionsType[] = [
  {
    status: "WTG",
    label: "Aguardando atendimento",
    color: "processing",
    icon: "mingcute:service-fill",
  },
  {
    status: "RDY",
    label: "Etapa de Separação Concluída",
    color: "success",
    icon: "fa6-solid:forward-step",
  },
  {
    status: "CLD",
    label: "Cancelada",
    color: "error",
    icon: "eva:close-fill",
  },
  {
    status: "CPD",
    label: "Atendida",
    color: "success",
    icon: "entypo:check",
  },
];

export const TypeOfRequest: OptionsType[] = [
  {
    status: "WOT",
    label: "Sem transporte",
    color: "error",
    icon: "mdi:truck-remove",
  },
  {
    status: "WTR",
    label: "Com transporte",
    color: "processing",
    icon: "mdi:truck-check",
  },
  {
    status: "WTE",
    label: "Com transporte emergencial",
    color: "warning",
    icon: "mdi:truck-fast",
  },
];

const showTag = tv({
  base: "flex select-none items-center justify-start gap-1 max-w-max  rounded p-1 text-xs border",
  variants: {
    color: {
      error: "bg-red-50 border-red-300 text-red-500",
      success: `bg-green-50  border-green-300 text-green-500`,
      warning: "bg-yellow-50 border-yellow-300 text-orange-400",
      info: "bg-blue-50 border-blue-300 text-blue-500",
      processing: "bg-blue-50 border-blue-300 text-blue-500",
    },
  },
  defaultVariants: {
    color: "processing",
  },
});

export function ShowTag(data: OptionsType[], value: string) {
  const statusObj = data.find((item) => item.status === value);
  if (!statusObj) return null;
  return (
    <Tooltip title={statusObj.label} trigger="hover">
      <div className={showTag({ color: statusObj.color as "error" | "success" | "warning" | "processing" })}>
        <div className={`text-${statusObj.color}-700`}>
          <Icon className="min-h-4 min-w-4" height={16} icon={statusObj.icon} />
        </div>
        <div className="px-1 truncate">{statusObj.label}</div>
      </div>
    </Tooltip>
  );
}
