import Dragger from "antd/es/upload/Dragger";
import { UseUploadManagedStore } from "../store";
import { UploadFile } from "antd/lib";
import { useUploadContext } from "@context/Upload/UploadContext";
import { InboxOutlined } from "@ant-design/icons";
import { mineType } from "@utils/mine";

function InputFiles() {
  const { extensions } = UseUploadManagedStore();
  const { fileList, handleChange } = useUploadContext();
  return (
    <Dragger
      className="rounded shadow h-44 bg-blue-50"
      accept={`${extensions.filter((extension) => extension.key.includes(".")).map((ext) => `${mineType(ext.key)}`)}`}
      showUploadList={false}
      multiple
      // maxCount={100}
      beforeUpload={() => false}
      onChange={handleChange}
      fileList={fileList as UploadFile[]}
    >
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="text-base font-medium">Clique ou arraste arquivos para essa área para fazer o upload</p>
      <p className="text-sm ">{`${extensions
        .filter((extension) => extension.key.includes("."))
        .map((extension) => ` ${extension.key.replace(".", "")}`)}`}</p>
    </Dragger>
  );
}

export default InputFiles;
