export type D3Active = "on" | "off" | "all";

type D3Type = "number" | "string" | "active" | "date" | "boolean" | "array";

type ItemDefinition = {
  op: string;
  type: D3Type | D3Active;
};

export type FilterDefinition = {
  [key: string]: ItemDefinition;
};

export default function D3QSGenerator<T extends Record<string, any>>(
  form: T,
  definition: FilterDefinition,
  pagination?: { page: number; pageSize: number },
  sort?: { sort_by: string; sort_direction: string }
): string {
  let qs: string[] = [];
  const qsFilter = Object.entries(form)
    .filter(([key, inputValue]) => {
      const inputDefinition = definition[key];
      return (
        (inputValue !== undefined && inputValue !== "" && inputDefinition.type === "string") ||
        (inputDefinition.type === "active" && inputValue.toString() !== "all") ||
        (inputValue !== undefined && inputValue.length !== 0 && inputDefinition.type === "array")
      );
    })
    .map(([key, inputValue]) => {
      const inputDefinition = definition[key];
      if (inputDefinition?.type === "string" || inputDefinition?.type === "number") {
        return `${key}:${inputDefinition.op}=${inputValue}`;
      } else if (inputDefinition?.type === "array") {
        return `${key}:${inputDefinition.op}=${inputValue.join("|")}`;
      } else if (inputDefinition?.type === "active") {
        return `${key}:${inputDefinition.op}=${inputValue === "on" ? true : false}`;
      } else if (inputDefinition?.type === "boolean") {
        return `${key}:${inputDefinition.op}=${inputValue === "on" ? true : false}`;
      } else {
        throw new Error("O tipo informado ainda não possui tratamento.");
      }
    })
    .join(",");

  if (sort) {
    qs.push(`sort=${sort.sort_by}:${sort.sort_direction}`);
  }

  if (pagination) {
    qs.push(`page=${pagination.page}&per_page=${pagination.pageSize}`);
  }

  if (qsFilter.length) {
    qs.push(`q=${qsFilter}`);
  }
  return qs.join("&");
}
