import { Icon } from "@iconify/react";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import { Button, Divider, Popconfirm, Space, Spin, Table, Tag, Tooltip } from "antd";
import { useContext, useEffect, useState } from "react";
import { useCompanyStore } from "../../store";
import { AddressTypeListItemType, RowAddressType } from "../../types";
import { CreateAddressForm } from "../address/CreateAddressForm";
import { UpdateAddressForm } from "../address/UpdateAddressForm";
import { ViewAddressForm } from "../address/ViewAddressForm";

function TabAddress() {
	const { openDrawer } = useContext(D3DrawerContext);
	const { loadAddresses, apiResultAddress, deleteAddress } = useCompanyStore();
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		(async () => {
			await loadAddresses();
			setLoading(false);
		})();
	}, [loadAddresses]);

	return (
		<div className="p-2">
			<Spin spinning={false}>
				<div className="flex items-center justify-between">
					<h2 className="ml-1 text-lg font-semibold text-gray-600">Endereços da empresa</h2>

					<Button
						className="flex items-center justify-start"
						type="primary"
						size="middle"
						icon={<Icon icon="ic:round-plus" height={20} width={20} />}
						onClick={() => openDrawer(<CreateAddressForm />, "right", 600, "Cadastrar Endereço")}
					>
						Novo Endereço
					</Button>
				</div>
				<Divider className="mt-1 mb-6 " />
				<Table<RowAddressType>
					size="small"
					rowKey="cuid"
					dataSource={apiResultAddress.data as RowAddressType[]}
					bordered
					pagination={false}
					loading={loading}
				>
					<Table.Column<RowAddressType>
						showSorterTooltip={false}
						key="description"
						defaultSortOrder="ascend"
						title="Descrição"
						dataIndex="description"
					/>
					<Table.Column<RowAddressType>
						width={80}
						showSorterTooltip={false}
						key="is_default"
						title="Padrão"
						dataIndex="is_default"
						render={(value, address, index) => (address.is_default ? <Tag color="success">Sim</Tag> : <Tag color="error">Não</Tag>)}
					/>
					<Table.Column<RowAddressType>
						width={150}
						showSorterTooltip={false}
						key="is_storage_address"
						title="Endereço de Galpão"
						dataIndex="is_storage_address"
						render={(value, address, index) => (address.is_storage_address ? <Tag color="success">Sim</Tag> : <Tag color="error">Não</Tag>)}
					/>
					<Table.Column<RowAddressType>
						width={350}
						showSorterTooltip={false}
						key="street_address"
						className="min-w-max"
						title="Logradouro"
						dataIndex="street_address"
						render={(value, address, index) => {
							return `${address.street_address}, nº ${address.street_number} ${address.complement === "" ? `` : `, ${address.complement}`}`;
						}}
					/>
					<Table.Column<RowAddressType> showSorterTooltip={false} key="neighborhood" title="Bairro" dataIndex="neighborhood" />
					<Table.Column<RowAddressType> showSorterTooltip={false} key="city" title="Cidade" dataIndex="city" />
					<Table.Column<RowAddressType> width={110} showSorterTooltip={false} key="state" title="Estado" dataIndex="state" />
					<Table.Column<RowAddressType> width={110} showSorterTooltip={false} key="zip_code" title="CEP" dataIndex="zip_code" />
					<Table.Column<AddressTypeListItemType>
						key="action"
						title="Ações"
						width={130}
						render={(value, address, index) => {
							return (
								<>
									<Space>
										<Tooltip title="Editar" destroyTooltipOnHide mouseLeaveDelay={0} zIndex={11}>
											<Button
												type="text"
												shape="circle"
												icon={
													<Icon
														icon="iconamoon:edit-fill"
														height={18}
														width={18}
														className="text-blue-400 transition-all duration-100 ease-in hover:text-blue-500"
													/>
												}
												size={"middle"}
												onClick={() => openDrawer(<UpdateAddressForm cuid={address.cuid} />, "right", 600, "Editar Endereço")}
											/>
										</Tooltip>

										<Tooltip title="Visualizar" destroyTooltipOnHide mouseLeaveDelay={0} zIndex={11}>
											<Button
												className="peer"
												type="text"
												shape="circle"
												icon={
													<Icon
														icon="carbon:document-view"
														height={18}
														width={18}
														className="text-blue-400 transition-all duration-100 ease-in hover:text-blue-500"
													/>
												}
												size={"middle"}
												onClick={() => openDrawer(<ViewAddressForm cuid={address.cuid} />, "right", 600, "Visualizar Endereço")}
											/>
										</Tooltip>
										<Popconfirm
											title="Atenção!"
											description="Confirmar exclusão do endereço?"
											onConfirm={() => deleteAddress(address.cuid)}
											okText="Sim"
											cancelText="Não"
										>
											<Tooltip
												title={
													address.is_default
														? "Endereço padrão não pode ser excluído"
														: address.is_storage_address
														? "Endereço de galpão não pode ser excluído"
														: "Excluir"
												}
												destroyTooltipOnHide
												mouseLeaveDelay={0}
												zIndex={11}
											>
												<Button
													disabled={address.is_default || address.is_storage_address}
													className="peer"
													type="text"
													shape="circle"
													icon={
														<Icon
															icon="material-symbols:delete"
															height={18}
															width={18}
															className="text-red-400 transition-all duration-100 ease-in hover:text-red-500"
														/>
													}
													size={"middle"}
												/>
											</Tooltip>
										</Popconfirm>
									</Space>
								</>
							);
						}}
					/>
				</Table>
			</Spin>
		</div>
	);
}

export default TabAddress;
