import D3Can from "@components/shared/D3Can";
import { Icon } from "@iconify/react";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import { Button, Form, Select, Space, Tag, Tooltip } from "antd";
import { useContext, useEffect } from "react";
import { useServiceOrdersStore } from "../store";
import { ReadyServiceOrderType } from "../types";
import StatusSelect from "@components/shared/StatusSelect";

type ServeServiceOrderFormProps = {
  orderCuid: string;
};

function ReadyServiceOrderForm({ orderCuid }: ServeServiceOrderFormProps) {
  const { simpleListAddress, addresses, loadingAddress, readyServiceOrder, loadingReady } = useServiceOrdersStore();
  const [form] = Form.useForm<ReadyServiceOrderType>();
  const { onClose } = useContext(D3DrawerContext);

  useEffect(() => {
    (async () => {
      await simpleListAddress();
    })();
    return () =>
      useServiceOrdersStore.setState({
        addresses: [],
      });
  }, [orderCuid]);

  return (
    <Form
      className="w-full h-full select-none"
      form={form}
      layout="vertical"
      onFinish={async (payload: ReadyServiceOrderType) => {
        const isReady = await readyServiceOrder(orderCuid, payload);
        if (isReady) {
          onClose();
        }
      }}
    >
      <Form.Item<ReadyServiceOrderType>
        name="documentation_center_address_cuid"
        label="Galpão"
        rules={[
          {
            required: true,
            message: "Por favor, selecione um galpão.",
          },
        ]}
      >
        <Select
          loading={loadingAddress}
          disabled={addresses.length === 0}
          listItemHeight={10}
          allowClear
          removeIcon={
            <Icon
              icon="iconamoon:close-duotone"
              className="w-4 h-4 text-red-400 transition-all duration-300 ease-in-out hover:text-red-600"
            />
          }
          menuItemSelectedIcon={<Icon icon="eva:checkmark-outline" className="w-5 h-5 text-blue-500" />}
          optionLabelProp="dataLabel"
          showSearch
          maxTagCount="responsive"
          size="middle"
          className="w-full truncate select-none"
          optionFilterProp="children"
          filterOption={(input, option) => option?.dataFilter.toLowerCase().includes(input.toLowerCase())}
        >
          {addresses.map(({ value, active, address, label, is_default }) => (
            <Select.Option key={value} value={value} dataFilter={`${label} ${address}`} dataLabel={`${label}`}>
              <div className="flex items-center justify-between ">
                <div className="flex flex-col truncate">
                  <span className="truncate">{label}</span>
                  <span className="text-[10px] truncate">{address}</span>
                </div>
                <div className="flex items-center justify-between gap-2">
                  {is_default && (
                    <Tooltip title="Endereço Padrão" placement="right">
                      <Tag
                        className="flex items-center justify-between gap-1"
                        icon={<Icon icon="bi:geo-alt-fill" />}
                        color="success"
                      >
                        Padrão
                      </Tag>
                    </Tooltip>
                  )}

                  <StatusSelect status={active} />
                </div>
              </div>
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item>
        <div className="text-right">
          <Space size="small">
            <Button disabled={loadingReady} type="default" danger onClick={() => onClose()}>
              Cancelar
            </Button>
            <D3Can action="completed" resource="order_service">
              <Button loading={loadingReady} type="primary" htmlType="submit">
                Ordem pronta para atendimento
              </Button>
            </D3Can>
          </Space>
        </div>
      </Form.Item>
    </Form>
  );
}

export default ReadyServiceOrderForm;
