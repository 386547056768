import { Icon } from "@iconify/react";
import useAuthStore from "@provider/AuthProvider";
import { Select } from "antd";
import { useEffect } from "react";
import { useD3SelectEntityStore } from "./store";
import StatusSelect from "@components/shared/StatusSelect";

function D3SelectEntity() {
  const { getUserInfo } = useAuthStore();
  const {
    loading,
    isOpen,
    simpleListEntity,
    onChangeToken,
    loadSimpleListEntity,
    onResetToken,
    getSelectedTypeAndEntityCuid,
  } = useD3SelectEntityStore();

  useEffect(() => {
    (async () => {
      await loadSimpleListEntity();
    })();
  }, [loadSimpleListEntity]);

  const { Type: userType } = getUserInfo();

  return (
    <Select
      listItemHeight={0}
      listHeight={260}
      allowClear
      open={isOpen ? true : undefined}
      optionLabelProp="data-selected"
      disabled={loading || userType === "CTM" || simpleListEntity.length === 0}
      value={
        loading
          ? "loading"
          : simpleListEntity.find(
              (item) =>
                item.type_and_entity_cuid.split(":")[1] === getSelectedTypeAndEntityCuid() ||
                item.type_and_entity_cuid === getSelectedTypeAndEntityCuid()
            )?.type_and_entity_cuid
      }
      showSearch
      size="middle"
      className="flex items-center  justify-center truncate select-none w-[500px] peer"
      placeholder="Selecione o Cliente"
      optionFilterProp="children"
      loading={loading}
      onChange={async (value: string) => {
        if (value === undefined || value === "") {
          await onResetToken();
        } else {
          await onChangeToken(value);
        }
      }}
      filterOption={(input, option) => {
        return option?.props["data-label"].toLowerCase().includes(input.toLowerCase());
      }}
    >
      {loading ? (
        <Select.Option value="loading" data-selected="Carregando...">
          Carregando...
        </Select.Option>
      ) : (
        simpleListEntity.map(({ active, cuid, fantasy_name, group_member, id, name, type_and_entity_cuid }) => (
          <Select.Option
            key={type_and_entity_cuid}
            value={type_and_entity_cuid}
            data-label={`${fantasy_name} ${name}  ${id}`}
            data-selected={`${fantasy_name}`}
            className="flex w-full"
          >
            <div className="flex items-center justify-between w-full ">
              <div className="flex items-center gap-2 truncate">
                {group_member ? (
                  <Icon icon="fa6-solid:users" height={20} width={20} />
                ) : (
                  <Icon icon="fa6-solid:user" height={12} width={12} />
                )}
                <div className="flex flex-col pr-1 truncate">
                  <span className="w-full truncate">{fantasy_name}</span>
                  <span className="text-[10px] truncate">{name}</span>
                </div>
              </div>
              <StatusSelect status={active} />
            </div>
          </Select.Option>
        ))
      )}
    </Select>
  );
}

export default D3SelectEntity;
