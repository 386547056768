import Swal, { SweetAlertIcon } from "sweetalert2";

type ConfirmationDialogProps = {
  title: string;
  text?: string;
  icon?: SweetAlertIcon;
  showConfirmButton?: boolean;
  confirmButtonText?: string;

  showDenyButton?: boolean;
  denyButtonText?: string;

  showCancelButton?: boolean;
  cancelButtonText?: string;

  showSuccess?: boolean;
  showDenied?: boolean;

  success?: {
    title: string;
    icon: "success" | "error" | "warning" | "info" | "question";
  };
  denied?: {
    title: string;
    icon: "success" | "error" | "warning" | "info" | "question";
  };
  cancel?: {
    title: string;
    icon: "success" | "error" | "warning" | "info" | "question";
  };

  cancelFunction: () => void;
  confirmFunction: () => void;
};

function confirmationDialog({
  title,
  text = undefined,
  icon = undefined,

  showConfirmButton,
  confirmButtonText,

  showDenyButton,
  denyButtonText,

  showCancelButton,
  cancelButtonText,

  success,
  denied,
  cancel,
  cancelFunction,
  confirmFunction,
}: ConfirmationDialogProps) {
  return Swal.fire({
    title: title,
    text: text,
    icon: icon,

    showConfirmButton: showConfirmButton,
    confirmButtonText: confirmButtonText,

    showDenyButton: showDenyButton,
    denyButtonText: denyButtonText,

    showCancelButton: showCancelButton,
    cancelButtonText: cancelButtonText,

    heightAuto: false,
    confirmButtonColor: "#4ade80",

    showLoaderOnConfirm: true,
    
  }).then((result) => {
    if (result.isConfirmed) {
      if (success) Swal.fire(success.title, "", success.icon);
      confirmFunction();
    } else if (result.isDenied) {
      if (denied) Swal.fire(denied.title, "", denied.icon);
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      if (cancel) Swal.fire(cancel.title, "", cancel.icon);
      cancelFunction();
    }
  });
}

export default confirmationDialog;
