import { BadRequest, ClientHttp, StandardError, Success } from "@utils/clientHttp";
import showMessage from "@utils/showMessage";
import { create } from "zustand";
import dayjs from "dayjs";
import {
  ChildrenType,
  IndexItemType,
  IndexesValue,
  ItemDocumentTypeType,
  ListIndexesType,
  PayloadDocumentType,
  ResponseType,
  SimpleListDocumentTypeType,
  ValidateRepository,
} from "./types";
import { mergeObjects } from "@utils/mergeObjects";

type DocumentStoreState = {
  validateRepository: () => Promise<boolean>;
  payload: PayloadDocumentType;
  simpleListDocumentTypeCustomer: () => Promise<void>;
  documentTypes: ItemDocumentTypeType[];
  listIndexesDocumentType: (documentTypeCuid: string) => Promise<void>;
  indexes: IndexItemType[];
  registrationDocument: () => Promise<Success<ChildrenType> | BadRequest | StandardError>;
  insertPayload: (values: Partial<PayloadDocumentType>) => void;
  resetState: () => void;
};

const defaultState = {
  payload: {
    can_insert_in_stored_repository: false,
    description: "",
    document_physical_tag_id: "",
    document_type_cuid: "",
    expected_purge_date: "",
    indexes_values: [] as IndexesValue[],
    is_permanent_storage: false,
    original_date: dayjs().format("YYYY-MM-DD"),
    physical_or_digital: "P",
    repository_physical_tag_id: "",
  } as PayloadDocumentType,
  documentTypes: [],
  indexes: [],
};

export const useDocumentStore = create<DocumentStoreState>((set, get) => ({
  ...defaultState,
  resetState: () => set(defaultState),
  insertPayload: (values: Partial<PayloadDocumentType>) => {
    const currentPayload = get().payload;
    const newPayload = mergeObjects<PayloadDocumentType, Partial<PayloadDocumentType>>(currentPayload, values);
    set({ payload: { ...newPayload } });
  },
  simpleListDocumentTypeCustomer: async (): Promise<void> => {
    await new ClientHttp().getItensForSelect<Success<SimpleListDocumentTypeType>, StandardError, void>(
      "/api/v1/customer/document-types/simple-list",
      (result: Success<SimpleListDocumentTypeType>): void => {
        set({
          documentTypes: result.body.data as ItemDocumentTypeType[],
        });
      },
      (error: StandardError): void => {
        showMessage(error);
        set({
          documentTypes: [],
        });
      }
    );
  },

  listIndexesDocumentType: async (documentTypeCuid: string): Promise<void> => {
    await new ClientHttp().getItensForSelect<Success<ListIndexesType>, StandardError, void>(
      `/api/v1/customer/document-types/${documentTypeCuid}/document-type-indexes`,
      (result: Success<ListIndexesType>): void => {
        set({
          indexes: result.body.data as IndexItemType[],
        });
      },
      (error: StandardError): void => {
        showMessage(error);
        set({
          indexes: [],
        });
      }
    );
  },

  validateRepository: async (): Promise<boolean> => {
    const fullPayload = get().payload;
    const payload = {
      can_insert_in_stored_repository: fullPayload.can_insert_in_stored_repository,
      repository_physical_tag_id: fullPayload.repository_physical_tag_id,
    };
    const result = await new ClientHttp().post<ValidateRepository, Success<ResponseType>, BadRequest | StandardError>(
      "/api/v1/customer/repositories/documents/validate-insert",
      payload,
      undefined,
      (error: BadRequest | StandardError): void => {
        showMessage(error);
      }
    );
    return result.status === "success";
  },
  registrationDocument: async (): Promise<Success<ChildrenType> | BadRequest | StandardError> => {
    return await new ClientHttp().post<PayloadDocumentType, Success<ChildrenType>, BadRequest | StandardError>(
      "/api/v1/customer/fs/document",
      get().payload as PayloadDocumentType,
      (result: Success<ChildrenType>): ChildrenType => {
        showMessage(result, "Documento cadastrado com sucesso.");
        return result.body;
      },
      (error: BadRequest | StandardError): void => {
        showMessage(error);
      }
    );
  },
}));
