import FormItemConditionalDate from "@components/shared/FormItemConditionalDate";
import { Form, FormInstance, Input, Select } from "antd";

import { useEffect, useState } from "react";
import { useSpecialsStore } from "../store";
import { SearchPhysicalDocumentType } from "../types";
import { Icon } from "@iconify/react";
import FormItemSwitcher from "./FormItemSwitcher";
import StatusSelect from "@components/shared/StatusSelect";
import Selector from "@components/shared/Selector";

type SearchRepositoryFormFormProps = {
  form: FormInstance<SearchPhysicalDocumentType>;
};

const options = [
  { label: "Igual a", value: "equal" },
  { label: "Maior que", value: "greater" },
  { label: "Menor que", value: "less" },
  { label: "No intervalo", value: "range" },
];

function SearchRepositoryForm({ form }: SearchRepositoryFormFormProps) {
  const { filter, simpleListDepartmentCustomer, departments, documentsType } = useSpecialsStore();
  const [documentsCuid, setDocumentsCuid] = useState<string[]>(filter.filter_document.document_physical_tag_id);

  useEffect(() => {
    (async () => {
      await simpleListDepartmentCustomer();
      form.setFieldsValue({
        filter_repository: filter.filter_repository,
      });
    })();
  }, [simpleListDepartmentCustomer, form]);

  return (
    <div className="flex flex-col w-full h-full p-2 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100 !important">
      <Form.Item label="Departamentos" name={["filter_repository", "departments_cuids"]} className="w-full p-0 my-2">
        <Selector
          fieldMap={{
            status: "active",
            value: "cuid",
            label: "abbreviation",
            description: "abbreviation",
            dataLabel: ["abbreviation", "description"],
            dataFilter: ["abbreviation", "description"],
          }}
          mode="multiple"
          options={departments}
        />
      </Form.Item>

      <FormItemSwitcher
        attribute={["filter_repository", "repository_physical_tag_id"]}
        label="Etiquetas de repositório"
        form={form}
      />
      
      <Form.Item label="Intervalo de repositórios" className="p-0 my-2">
        <div className="flex items-center gap-3">
          <Form.Item
            className="w-full p-0 m-0"
            name={["filter_repository", "repository_range", "start_repository_physical_tag_id"]}
          >
            <Input />
          </Form.Item>
          até
          <Form.Item
            className="w-full p-0 m-0"
            name={["filter_repository", "repository_range", "end_repository_physical_tag_id"]}
          >
            <Input />
          </Form.Item>
        </div>
      </Form.Item>

      <Form.Item
        name={["filter_repository", "repository_types_cuids"]}
        label="Tipo de Documento"
        className="w-full p-0 my-2"
      >
        <Select
          disabled={documentsType.length === 0}
          listItemHeight={10}
          allowClear
          removeIcon={
            <Icon
              icon="iconamoon:close-duotone"
              className="w-4 h-4 text-red-400 transition-all duration-300 ease-in-out hover:text-red-600"
            />
          }
          menuItemSelectedIcon={<Icon icon="eva:checkmark-outline" className="w-5 h-5 text-blue-500" />}
          optionLabelProp="dataLabel"
          showSearch
          maxTagCount="responsive"
          mode="multiple"
          size="middle"
          className="w-full truncate select-none"
          optionFilterProp="children"
          filterOption={(input, option) => option?.dataFilter.toLowerCase().includes(input.toLowerCase())}
          onClear={() => useSpecialsStore.setState({ listIndexers: [] })}
        >
          {documentsType.map(({ active, cuid, description, temporality_permanent_guard, temporality_year }) => (
            <Select.Option
              disabled={!active}
              key={cuid}
              value={cuid}
              dataFilter={`${description}`}
              dataLabel={`${description} - ${
                temporality_permanent_guard === true
                  ? "Tempo indeterminado"
                  : `${temporality_year} ${temporality_year > 1 ? "Anos" : "Ano"}`
              }`}
            >
              <div className="flex items-center justify-between ">
                <div className="flex flex-col truncate">
                  <span className="truncate">{`${description} - ${
                    temporality_permanent_guard === true
                      ? "Tempo indeterminado"
                      : `${temporality_year} ${temporality_year > 1 ? "Anos" : "Ano"}`
                  }`}</span>
                </div>
                <StatusSelect status={active} />
              </div>
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <FormItemConditionalDate<SearchPhysicalDocumentType>
        attribute={["filter_repository", "created_at"]}
        label="Data cadastramento"
        options={options}
        form={form}
      />

      <Form.Item label="Posição" className="p-0 my-2" name={["filter_repository", "position"]}>
        <Input />
      </Form.Item>

      <Form.Item label="Origem" className="p-0 my-2" name={["filter_repository", "repository_types_cuids"]}>
        <Input />
      </Form.Item>
    </div>
  );
}

export default SearchRepositoryForm;
