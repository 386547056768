import Swal, { SweetAlertPosition } from "sweetalert2";

type SweetAlertProps = {
  position?: SweetAlertPosition;
  timer?: number;
  type?: "error" | "success" | "warning" | "info" | "question";
  title: string | HTMLElement;
};

const SweetAlert = ({ position = "center", timer = 2, title, type = "success" }: SweetAlertProps) => {
  timer = timer * 1000;
  return Swal.fire({
    position: position,
    icon: type,
    title: title,
    showConfirmButton: false,
    timer: timer,
    width: 300,
    heightAuto: false,
    customClass: {
      title: "text-gray-500 text-base",
    },
    timerProgressBar: true,
  });
};

export default SweetAlert;
