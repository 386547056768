import { Icon } from "@iconify/react";
import { Button, Form, Input, Radio, Spin, Steps, Tooltip } from "antd";
import { useContext, useEffect, useRef, useState } from "react";
import { DynamicFormElement } from "../indexers/DynamicFormElement";
import { useDocumentStore } from "./store";
import { ChildrenType, IndexesValue, ItemDocumentTypeType, PayloadDocumentType, ValidateRepository } from "./types";
import dayjs from "dayjs";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import DocumentLifeCycleManager from "@components/shared/DocumentLifeCycleManager";
import useNavigateStore from "@views/customer/spaces/work/components/navigate/store";
import SweetAlert from "@components/shared/SweetAlert";

type RegisterPhysicalDocumentProps = {
  isDrawer?: boolean;
  dtfCuid?: string;
};

function RegisterPhysicalDocument({ isDrawer = false, dtfCuid }: RegisterPhysicalDocumentProps) {
  const {
    validateRepository,
    simpleListDocumentTypeCustomer,
    listIndexesDocumentType,
    registrationDocument,
    payload,
    documentTypes,
    indexes,
    insertPayload,
    resetState,
  } = useDocumentStore();
  const {
    content: { cuid },
  } = useNavigateStore();
  const [formValidation] = Form.useForm<ValidateRepository>();
  const [formClassification] = Form.useForm<Partial<PayloadDocumentType>>();
  const [formFill] = Form.useForm<Partial<PayloadDocumentType>>();
  const divRef = useRef<HTMLDivElement>(null);
  const { onClose } = useContext(D3DrawerContext);

  const [loading, setLoading] = useState<boolean>(false);
  const [current, setCurrent] = useState(0);
  const [isScroll, setIsScroll] = useState<boolean>(false);
  const [documentTypeSelected, setDocumentTypeSelected] = useState<ItemDocumentTypeType>();

  useEffect(() => {
    (async () => {
      if (current === 0) {
        formValidation.setFieldsValue({
          can_insert_in_stored_repository: payload.can_insert_in_stored_repository
            ? payload.can_insert_in_stored_repository
            : false,
          repository_physical_tag_id: payload.repository_physical_tag_id ? payload.repository_physical_tag_id : "",
        });
      } else if (current === 1) {
        setLoading(true);
        await simpleListDocumentTypeCustomer();
        setLoading(false);
      } else if (current === 2) {
        setLoading(true);
        await listIndexesDocumentType(payload.document_type_cuid);
        setLoading(false);
      }
    })();
  }, [current, cuid]);

  useEffect(() => {
    if (documentTypes.length > 0 && dtfCuid) {
      const documentType = documentTypes.find((item) => item.cuid === dtfCuid);
      setDocumentTypeSelected(documentType);
      if (documentType?.active) {
        const datePurge =
          documentType.temporality_year > 0
            ? dayjs(payload.original_date).add(documentType.temporality_year, "year")
            : "";
        payload.expected_purge_date = datePurge;
      } else {
        SweetAlert({
          title: "Não é possível realizar o upload neste tipo de documento, pois ele está inativado.",
          type: "info",
        });
        onClose();
      }
      payload.document_type_cuid = dtfCuid;
      formClassification.setFieldsValue(payload);
    } else {
      formClassification.setFieldsValue(payload);
    }
  }, [documentTypes, dtfCuid]);

  useEffect(() => {
    const div = divRef.current;
    const checkScroll = () => {
      if (div) {
        setIsScroll(div.scrollHeight > div.clientHeight);
      }
    };

    const resizeObserver = new ResizeObserver(checkScroll);
    if (div) {
      resizeObserver.observe(div);
    }
    checkScroll();
    return () => {
      if (div) {
        resizeObserver.unobserve(div);
      }
    };
  }, [indexes]);

  const scrollToBottom = () => {
    if (divRef.current) {
      divRef.current.scrollTop = divRef.current.scrollHeight;
    }
  };

  const steps = [
    {
      title: `Validação`,
      content: (
        <div ref={divRef} className="relative flex justify-center w-full h-full p-3 overflow-y-auto custom-scroll">
          {loading ? (
            <div className="flex items-center justify-center h-full">
              <Spin />
            </div>
          ) : (
            <Form
              form={formValidation}
              onFinish={async (values: ValidateRepository) => {
                setLoading(true);
                const validate = await validateRepository();
                if (validate) {
                  insertPayload(values);
                  setCurrent(current + 1);
                } else {
                  setLoading(false);
                }
              }}
              onValuesChange={(changedValues: any, values: ValidateRepository) => {
                insertPayload(values);
              }}
              className="h-full w-[420px] px-4"
              layout="vertical"
            >
              <Form.Item<ValidateRepository>
                initialValue={false}
                name="can_insert_in_stored_repository"
                label="Inserção em repositório armazenado?"
              >
                <Radio.Group
                  options={[
                    { label: "Sim", value: true },
                    { label: "Não", value: false },
                  ]}
                  optionType="button"
                  buttonStyle="solid"
                  size="small"
                  className="select-none"
                />
              </Form.Item>
              <Form.Item<ValidateRepository>
                name="repository_physical_tag_id"
                label="Etiqueta do repositório"
                rules={[
                  {
                    required: true,
                    message: "Por favor, insira o código do repositório.",
                  },
                  {
                    min: 12,
                    max: 12,
                    message: "O código do repositório deve incluir uma sequência de 12 dígitos numéricos.",
                  },
                  {
                    validator: (_, value) => {
                      if (value && value !== "") {
                        return /^\d+$/.test(value)
                          ? Promise.resolve()
                          : Promise.reject(
                              new Error("O código do repositório deve ser composta exclusivamente por números.")
                            );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input minLength={12} maxLength={12} showCount />
              </Form.Item>
            </Form>
          )}
        </div>
      ),
    },
    {
      title: "Classificação",
      content: (
        <div ref={divRef} className="relative flex justify-center w-full h-full p-3 overflow-y-auto custom-scroll">
          {loading ? (
            <div className="flex items-center justify-center h-full">
              <Spin />
            </div>
          ) : (
            <Form
              form={formClassification}
              onFinish={(values: Partial<PayloadDocumentType>) => {
                values.expected_purge_date = values.expected_purge_date
                  ? dayjs(values.expected_purge_date).format("YYYY-MM-DD")
                  : "";
                values.is_permanent_storage = values.is_permanent_storage ? values.is_permanent_storage : false;
                insertPayload(values);
                setCurrent(current + 1);
              }}
              onValuesChange={(changedValues, allValues) => {
                const key = Object.keys(changedValues)[0];
                const value = changedValues[key];
                useDocumentStore.setState((state) => ({
                  payload: {
                    ...state.payload,
                    [key]: value,
                  },
                }));
                if (changedValues.document_type_cuid) {
                  const documentType = documentTypes.find((item) => item.cuid === changedValues.document_type_cuid);
                  setDocumentTypeSelected(documentType);
                  if (documentType) {
                    const purgeDate =
                      documentType.temporality_year > 0
                        ? dayjs(payload.original_date).add(documentType.temporality_year, "year")
                        : "";
                    formClassification.setFieldValue("expected_purge_date", purgeDate);
                    formClassification.setFieldValue("is_permanent_storage", documentType.temporality_permanent_guard);
                  }
                }
                if (changedValues.original_date || changedValues.is_permanent_storage === false) {
                  if (documentTypeSelected) {
                    const purgeDate =
                      documentTypeSelected.temporality_year > 0
                        ? dayjs(changedValues.original_date).add(documentTypeSelected.temporality_year, "year")
                        : "";
                    formClassification.setFieldValue("expected_purge_date", purgeDate);
                  }
                }
                if (changedValues.original_date === "" || changedValues.is_permanent_storage) {
                  formClassification.setFieldValue("expected_purge_date", "");
                }
              }}
              className="h-full w-[420px] px-4"
              layout="vertical"
            >
              <Form.Item<Partial<PayloadDocumentType>>
                name="document_physical_tag_id"
                label="Etiqueta do documento"
                rules={[
                  {
                    required: true,
                    message: "Por favor, insira o código de etiqueta.",
                  },
                  {
                    min: 12,
                    max: 12,
                    message: "O código de etiqueta deve incluir uma sequência de 12 dígitos numéricos.",
                  },
                  {
                    validator: (_, value) => {
                      if (value && value !== "") {
                        return /^\d+$/.test(value)
                          ? Promise.resolve()
                          : Promise.reject(
                              new Error("O código de etiqueta deve ser composta exclusivamente por números.")
                            );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input minLength={12} maxLength={12} showCount />
              </Form.Item>

              <Form.Item<Partial<PayloadDocumentType>> name="description" label="Descrição">
                <Input />
              </Form.Item>

              <DocumentLifeCycleManager
                disabled={dtfCuid ? true : false}
                documentTypeSelected={documentTypeSelected ? documentTypeSelected : ({} as ItemDocumentTypeType)}
                documentTypes={documentTypes}
                loading={loading}
                payload={payload}
              />
            </Form>
          )}
        </div>
      ),
    },
    {
      title: "Preenchimento",
      content: (
        <div ref={divRef} className="relative flex justify-center w-full h-full p-3 overflow-y-auto custom-scroll">
          {loading ? (
            <div className="flex items-center justify-center h-full">
              <Spin />
            </div>
          ) : (
            <Form
              form={formFill}
              onFinish={async (values: Partial<PayloadDocumentType>) => {
                setLoading(true);
                const indexes_values = Object.entries(values).map(([key, val]) => {
                  const [type] = Object.entries(val);
                  const { end_value, start_value, value } = type[1] as IndexesValue;
                  return {
                    end_value: end_value ? end_value.toString() : "",
                    document_type_index_id: parseInt(key),
                    start_value: start_value ? start_value.toString() : "",
                    value: value ? value.toString() : "",
                  };
                }) as IndexesValue[];
                insertPayload({ indexes_values });
                const result = await registrationDocument();
                if (result.status === "success") {
                  if (dtfCuid) {
                    useNavigateStore.getState().addChildren(result.body as ChildrenType);
                  }
                  resetState();
                  formClassification.resetFields();
                  formFill.resetFields();
                  formValidation.resetFields();
                  setLoading(false);
                  setCurrent(0);
                  isDrawer && onClose();
                } else {
                  setLoading(false);
                }
              }}
              className={`h-full w-[420px] px-4 ${isScroll ? "mb-40" : ""}`}
              layout="vertical"
            >
              {DynamicFormElement(indexes)}
            </Form>
          )}
        </div>
      ),
    },
  ];

  const items = steps.map((item) => ({ key: item.title, title: item.title }));

  return (
    <div
      className={`${
        isDrawer ? " border rounded" : "mb-1 mr-1 rounded shadow-md"
      } flex flex-col h-full overflow-hidden bg-white `}
    >
      <div className="flex items-center justify-center h-12 border-b border-gray-200 min-h-12 ">
        <div className="flex items-center justify-center">
          <span className="text-xl font-semibold text-gray-600">Cadastramento de Documento Físico</span>
        </div>
      </div>

      <div className="flex items-start justify-center w-full h-full overflow-hidden grow">
        <div className="flex flex-col w-[900px] h-full overflow-hidden py-2">
          <div className="flex items-center px-2 min-h-9 h-9">
            <Steps current={current} items={items} />
          </div>
          <div className="relative flex h-full p-2 overflow-hidden grow">
            {isScroll && (
              <div className="absolute z-50 flex items-center justify-center top-1/2 right-8">
                <Tooltip title="Click ou role para ver mais indexadores">
                  <Button
                    type="link"
                    danger
                    onClick={scrollToBottom}
                    icon={<Icon height={18} icon="line-md:arrow-down" className="text-red-600 h-11 animate-bounce" />}
                  />
                </Tooltip>
              </div>
            )}
            {steps[current].content}
          </div>
          <div className="flex items-center justify-between px-2 h-11 min-h-11">
            {current > 0 && (
              <Button disabled={loading} onClick={() => setCurrent(current - 1)}>
                Anterior
              </Button>
            )}
            <div className="flex">
              {current === 0 ? (
                <Button loading={loading} type="primary" onClick={() => formValidation.submit()}>
                  Próximo
                </Button>
              ) : current === 1 ? (
                <Button loading={loading} type="primary" onClick={() => formClassification.submit()}>
                  Próximo
                </Button>
              ) : (
                <Button loading={loading} type="primary" onClick={() => formFill.submit()}>
                  Finalizar
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RegisterPhysicalDocument;
